import React from 'react';
import { IonIcon } from '@ionic/react';
import { volumeHighOutline } from 'ionicons/icons';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import './DoDontModule.css';

interface DraggableItemProps {
    text: string;
    audio_url?: string;
    playAudio: (url: string) => void;
}

const DraggableItem: React.FC<DraggableItemProps> = ({ text, audio_url, playAudio }) => {
    const { attributes, listeners, isDragging, setNodeRef, transform } = useDraggable({
        id: text,
        data: {
            text,
            audio_url
        }
    });

    const style = {
        transform: CSS.Translate.toString(transform),
    };

    return (
        <div ref={setNodeRef}>
            <div
                className={`draggable-item ${isDragging ? 'dragging' : ''}`}
                style={style}
                {...attributes}
                {...listeners}
                onClick={() => console.log('CLIIICK')}
            >
                {audio_url ? (
                    <IonIcon
                        icon={volumeHighOutline}
                    />
                ) : (
                    text
                )}
            </div>
        </div>
    );
};

export default DraggableItem;