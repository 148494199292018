// import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
// import React, { useState } from "react";

// const VoiceAndSpeech: React.FC = () => {
//   const [voice, setVoice] = useState<string>("");

//   return (
//     <IonItem>
//       <IonLabel>Choose Voice Tone</IonLabel>
//       <IonSelect value={voice} placeholder="Select One" onIonChange={e => setVoice(e.detail.value)}>
//         <IonSelectOption value="friendly">Friendly</IonSelectOption>
//         <IonSelectOption value="serious">Serious</IonSelectOption>
//         <IonSelectOption value="energetic">Energetic</IonSelectOption>
//       </IonSelect>
//     </IonItem>
//   );
// };

// export default VoiceAndSpeech;

// import React, { useState } from 'react';
// import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonLabel, IonList, IonRadioGroup, IonRadio, IonButton } from '@ionic/react';

// interface VoiceAndSpeechProps {
//     gender: string;
//   }


// // const voiceOptions = [
// //   { label: 'Friendly', value: 'friendly', sample: '/path-to-friendly-voice.mp3' },
// //   { label: 'Serious', value: 'serious', sample: '/path-to-serious-voice.mp3' },
// //   { label: 'Energetic', value: 'energetic', sample: '/path-to-energetic-voice.mp3' }
// // ];

// const VoiceAndSpeech: React.FC<VoiceAndSpeechProps> = ({ gender }) => {
//   const [selectedVoice, setSelectedVoice] = useState('');

//   type GenderOption = 'male' | 'female' | 'nonBinary';

//   const voiceOptions: Record<GenderOption, string[]> = {
//     male: ['Deep Male Voice', 'Bright Male Voice'],
//     female: ['Soft Female Voice', 'Energetic Female Voice'],
//     nonBinary: ['Neutral Tone Voice', 'Soft Tone Voice'],
//   };

//   const playSample = (sample: string) => {
//     const audio = new Audio(sample);
//     audio.play();
//   };

//   const voicesToShow = gender ? voiceOptions[gender as GenderOption] : [];

//   return (
//     <IonCard>
//       <IonCardHeader>
//         <IonCardTitle>Voice Personality</IonCardTitle>
//       </IonCardHeader>
//       <IonCardContent>
//         <IonRadioGroup value={selectedVoice} onIonChange={e => setSelectedVoice(e.detail.value)}>
//           <IonList>
//             {voicesToShow.map((voice, index) => (
//               <IonItem key={index}>
//                 <IonLabel>{voice}</IonLabel>
//                 <IonRadio slot="start" value={voice} />
//                 {/* Update to trigger the playSample function with the right sample */}
//                 <IonButton fill="clear" size="small" onClick={() => playSample(voice)}>Play Sample</IonButton>
//               </IonItem>
//             ))}
//           </IonList>
//         </IonRadioGroup>
//       </IonCardContent>
//     </IonCard>
//   );
// };

// export default VoiceAndSpeech;


import React, { useState,useEffect } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonRadioGroup, IonList, IonItem, IonLabel, IonRadio, IonButton } from '@ionic/react';

interface VoiceOption {
  label: string;
  id: string; // Assuming each voice has a unique identifier
  // ... other properties
}

interface VoiceAndSpeechProps {
  gender: string;
  initialVoiceId: string;
  setVoiceId: React.Dispatch<React.SetStateAction<string>>;
}

const VoiceAndSpeech: React.FC<VoiceAndSpeechProps> = ({ gender,initialVoiceId, setVoiceId }) => {
  const [selectedVoice, setSelectedVoice] = useState('');

  useEffect(() => {
    if (initialVoiceId) {
      setSelectedVoice(initialVoiceId);
      setVoiceId(initialVoiceId);  // Update the parent component's state if necessary
    }
  }, [initialVoiceId, setVoiceId]);

  // Mock data for voice options based on gender
  const voiceOptions: Record<string, VoiceOption[]> = {
    male: [
      { label: 'Deep Male Voice', id: 'deep_male' },
      // ... other male voice options
    ],
    female: [
      { label: 'Soft Female Voice', id: 'soft_female' },
      // ... other female voice options
    ],
    // ... other gender options
  };

  // Update the selected voice and lift the state up
  const handleVoiceChange = (voiceId: string) => {
    console.log('Received new props in VoiceAndSpeech:', voiceId);
    setSelectedVoice(voiceId);
    setVoiceId(voiceId); // Update parent component's state
  };

  
  // Get the correct voice options based on the selected gender
  const voicesToShow = gender ? voiceOptions[gender] : [];

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Voice Personality</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonRadioGroup value={selectedVoice} onIonChange={e => handleVoiceChange(e.detail.value)}>
          <IonList>
            {voicesToShow.map((voice) => (
              <IonItem key={voice.id}>
                <IonLabel>{voice.label}</IonLabel>
                <IonRadio slot="start" value={voice.id} />
              </IonItem>
            ))}
          </IonList>
        </IonRadioGroup>
      </IonCardContent>
    </IonCard>
  );
};

export default VoiceAndSpeech;
