import React from 'react';
import { useDroppable } from '@dnd-kit/core';

import './DoDontModule.css';


interface DropColumnProps {
    name: string;
    children: React.ReactNode;
}

// const DropColumn: React.FC<DropColumnProps> = ({ name, acceptItem, children }) => {
//     const [{ isOver, canDrop }, drop] = useDrop({
//         accept: 'ITEM',
//         drop: (item: { text: string }) => {
//             acceptItem(item.text);
//             return { name }; 
//         },
//         collect: (monitor) => ({
//             isOver: monitor.isOver(),
//             canDrop: monitor.canDrop(),
//         }),
//     });

//     const isActive = isOver && canDrop;
//     const backgroundColor = isActive ? 'lightgreen' : isOver ? 'lightcoral' : '';

//     const columnClassName = name.toLowerCase().includes("don't") ? 'dont-column' : 'do-column'; // Explicit class names

//     return (
//         <div ref={drop} className={`drop-column ${columnClassName} ${isActive ? 'over' : ''}`} style={{ backgroundColor }}>
//             <h3>{name}</h3>
//             {children}
//         </div>
//     );
// };

//export default DropColumn;


const DropColumn: React.FC<DropColumnProps> = ({ name, children }) => {
    const {setNodeRef, active, isOver} = useDroppable({
        id: name,
    });

    // const backgroundColor = active ? 'lightgreen' : isOver ? 'lightcoral' : '';

    const columnClassName = name.toLowerCase().includes("don't") ? 'dont-column' : 'do-column'; 

    return (
        <div 
            className={`drop-column ${columnClassName} ${active ? 'over' : ''}`} 
            // style={{ backgroundColor }}
            ref={setNodeRef} >
            <h3>{name}</h3>
            {children}
        </div>
    );
};

export default DropColumn;