export const MAIN_SCREEN = '';
export const PHONE_VER_SCREEN = 'phone-verification';
export const SEARCH_PAGE_SIZE = 5;
export const MATCH_PAGE_SIZE = 100;
export const DATE_INTERVAL_MIN = 5;
export const HOURS_MINUTES_FORMAT_AM_PM = 'hh:mm a';
export const HOURS_MINUTES_MILITARY = 'HH:mm';
export const BOOKINGS = 'booking';
export const LIVE_VIDEO_REQUEST_EXPIRES_IN_MINS = 1;
export const LIVE_PAGE = 'live';

export const WALLET_PAGE = 'wallet';
export const FEEDBACK_PAGE = 'feedback-view';
export const TRANSACTIONS_PAGE_SIZE = 10;
export const SUMMARY_HISTORY_PAGE_SIZE = 20;

export const LAST_X_SESSION_SUMMARY_CONTEXT = 3;

export const BOOKING_LIST_PAGE_SIZE = 25;
export const FEEDBACK_LIST_PAGE_SIZE = 10;

export const CONTACT_EMAIL = 'contact@extraverse.app';

export const REDDIT_GROUP = 'https://www.reddit.com/r/Extraverse/';

export const DISCORD_GROUP = 'https://discord.gg/UDdKxj3TKs';

export const FEEDBACK_SUMMARY_LOADING = "Loading...";

export const TITLE_PLACEHOLDER = `Enter a short title for your scenario. Example, "Front Desk Engagement"`

export const DESCRIPTION_PLACEHOLDER = `Enter a description of your scenario. For example,
 
"A male patient over 60 years old approaches you as the front desk staff member at a cardiovascular clinic.  Many of these older patients enjoy engaging in small talk during check-in, possibly due to limited social interactions. Your goal is to provide a warm and engaging conversation, brightening their day with a pleasant interaction amidst their health-related concerns."`

export const PROMPT_PLACEHOLDER = `This is where you tell the AI to act and converse with you in a certain way. For example, 

"You play the role of an elderly male patient, typically over 60 years old, visiting a cardiovascular clinic. You appreciate making small talk with the front desk staff like me while checking in for your appointment. The elderly patient is more focused on the appointment and prefers to keep the conversation light and brief. This conversation is often a significant moment of human interaction for you, and you enjoy sharing and listening during these exchanges.

Instructions:

- Act and reply naturally, using polite and gentle language.
- Initiate the conversation with a friendly greeting or a comment about me, the weather, your health, or a light, appropriate topic.
- Keep your replies short.
- You should only speak your replies; do not describe actions such as "Smiling" or "Adjusting glasses".
- If you feel the conversation should end due to feeling tired or overwhelmed, politely indicate that you need to rest or focus on the appointment.

Your output is just the sentence(s) you would speak."`

export const FEEDBACK_PLACEHOLDER = `This is where you tell the AI on how to evaluate your performance after the interaction. For example, 

"Briefly summarize the interaction between me, the front desk worker and the elderly patient, highlighting important aspects such as my friendliness, professionalism, and ability to engage with the patient and small talk. 
Suggest any specific aciton items I could take to improve my small talk skills."`;

export const BACKGROUND_AUDIO_URL = `An optional URL of an audio file (.mp3, .wav, .ogg) to be played in the background`;

export const CONTENT_TABLE = 'content';
export const SCENARIO_TABLE = 'situation';
export const QUIZ_TABLE = 'quiz';
export const LLMCHECK_TABLE = 'llmcheck';
export const DO_DONT_TABLE = 'do_dont';
export const PAIR_MATCHING_TABLE = 'pair_matching';
export const STORY_TABLE = 'stories';