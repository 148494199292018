import { useEffect, useState } from "react";
import { Content, LLMCheck, Module, Quiz, DoDont, PairMatching, Story} from "../../../../types/ai_pivot/contentTypes";
import { IonProgressBar,IonLoading  } from '@ionic/react';
import PracticeModule from "./module/PracticeModule";
import InfoContentModule from "./module/InfoContentModule";
import QuizModule from "./module/quiz/QuizModule";
import DoDontModule from "./module/do_dont/DoDontModule";
import LLMCheckModule from "./module/llmcheck/LLMCheckModule";
import PairMatchingModule from "./module/pair_matching/PairMatchingModule";
import StoryModule from "./module/story/StoryModule";
import { Situation } from "../../../../types/ai_pivot/situationTypes";
import { CONTENT_TABLE, QUIZ_TABLE, SCENARIO_TABLE, LLMCHECK_TABLE, DO_DONT_TABLE, PAIR_MATCHING_TABLE, STORY_TABLE } from "../../../../util/constants";
import { getContent, getQuiz, getLLMCheck, getDoDont, getPairMatching, getStory } from "../../../../service/contentService";
import { getSituation } from "../../../../service/situationService";
import { PostgrestError } from "@supabase/supabase-js";
import AudioManager from "./AudioManager";

interface PreloadedModule extends Module {
    object?: Content | Quiz | Situation | LLMCheck | DoDont | PairMatching | Story;
    preloadedImages?: { [key: string]: HTMLImageElement };
}



const preloadImage = (src: string, retries = 2) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const attemptLoad = (retryCount: number) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(img);
            img.onerror = () => {
                if (retryCount > 0) {
                    console.log("Retry image")
                    setTimeout(() => attemptLoad(retryCount - 1), 1000); // Retry after a delay
                } else {
                    console.error(`Failed to preload image: ${src}`);
                    reject();
                }
            };
        };

        attemptLoad(retries);
    });
};


const LessonSections: React.FC<{
    modules: Module[],
    handleCompletion: () => void,
    closeLesson: () => void,
    updateProgress: (progress: number) => void,
    initialProgress: number
}> = ({ modules, handleCompletion, closeLesson, updateProgress, initialProgress }) => {
    const numModules = modules.length;
    // const [currModule, setCurrModule] = useState(0);
    const [currModule, setCurrModule] = useState(initialProgress || 0);
    const [preloadedModules, setPreloadedModules] = useState<PreloadedModule[]>([]);
    const [loading, setLoading] = useState(true);
    const [progressBar, setProgressBar] = useState((initialProgress / numModules) || 0.05);

    useEffect(() => {
        // setLoading(true);
        const handleLoadedModuleObj = async (
            mod: Module, { data, error }: { data: Content | Quiz | Situation | LLMCheck | DoDont | PairMatching | Story | null, error: PostgrestError | null }) => {
            if (error) {
                console.error(JSON.stringify(error));
            } else if (data) {
                const preloadedImages: { [key: string]: HTMLImageElement } = {};
                
                if (mod.table === QUIZ_TABLE || mod.table === LLMCHECK_TABLE || mod.table === STORY_TABLE) {
                    const imageUrls = [
                        (data as Quiz).image_content,
                        (data as Quiz).image_option_1,
                        (data as Quiz).image_option_2,
                        (data as Quiz).image_option_3,
                        (data as Quiz).image_option_4,
                        (data as LLMCheck).image_content
                    ].filter(Boolean);
                    
                    await Promise.all(imageUrls.map(async (url) => {
                        if (url) {
                            try {
                                const img = await preloadImage(url);
                                preloadedImages[url] = img;
                            } catch {
                                console.warn(`Skipping preloading image: ${url}`);
                            }
                        }
                    }));

                    const audioUrls = [
                        (data as Quiz).audio_content,
                        (data as Quiz).audio_option_1,
                        (data as Quiz).audio_option_2,
                        (data as Quiz).audio_option_3,
                        (data as Quiz).audio_option_4,
                        (data as LLMCheck).audio_content,
                        ...((data as Story).items?.map((item) => item.content?.audio_url) || [])
                    ].filter(Boolean);

                    await Promise.all(audioUrls.map(async (url) => {
                        if (url) {
                            try {
                                await AudioManager.preloadAudio(url);
                            } catch {
                                console.warn(`Skipping preloading audio: ${url}`);
                            }
                        }
                    }));
                }

                return { id: mod.id, table: mod.table, object: data, preloadedImages };
                // return { id: mod.id, table: mod.table, object: data }
            }
            return { id: mod.id, table: mod.table };
        }

        const preloadModules = async () => {
            const loadedModules = await Promise.all(modules.map(async (mod) => {
                if (mod.table === CONTENT_TABLE) {
                    return getContent(mod.id).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error }));
                } else if (mod.table === QUIZ_TABLE) {
                    return getQuiz(mod.id).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error }));
                } else if (mod.table === SCENARIO_TABLE) {
                    return getSituation(`${mod.id}`).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error }));
                } else if (mod.table === LLMCHECK_TABLE) {
                    return getLLMCheck(mod.id).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error })); 
                } else if (mod.table === DO_DONT_TABLE) {
                    return getDoDont(mod.id).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error }));
                } else if (mod.table === PAIR_MATCHING_TABLE) {
                    return getPairMatching(mod.id).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error }));
                } else if (mod.table === STORY_TABLE) {
                    return getStory(mod.id).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error }));                 
                } else { return { id: mod.id, table: mod.table } }
            }));
            setPreloadedModules(loadedModules);
            setLoading(false);
        };

        preloadModules();
        // Promise.all((modules.map(async (mod) => {
        //     if (mod.table === CONTENT_TABLE) {
        //         return getContent(mod.id).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error }));
        //     } else if (mod.table === QUIZ_TABLE) {
        //         return getQuiz(mod.id).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error }));
        //     } else if (mod.table === SCENARIO_TABLE) {
        //         return getSituation(`${mod.id}`).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error }));
        //     } else if (mod.table === LLMCHECK_TABLE) {
        //         return getLLMCheck(mod.id).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error })); 
        //     } else if (mod.table === DO_DONT_TABLE) {
        //         return getDoDont(mod.id).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error }));
        //     } else if (mod.table === PAIR_MATCHING_TABLE) {
        //         return getPairMatching(mod.id).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error }));
        //     } else if (mod.table === STORY_TABLE) {
        //         return getStory(mod.id).then(({ data, error }) => handleLoadedModuleObj(mod, { data, error }));                 
        //     } else { return { id: mod.id, table: mod.table } }
        // }))).then((ans) => {
        //     setPreloadedModules(ans);
        //     setLoading(false);
        // });
    }, []);

    const isCurrModuleLast = () => {
        return currModule + 1 >= numModules;
    }

    const moveToNextModule = () => {
        if (isCurrModuleLast()) {
            handleCompleteLesson();
        } else {
            let nextModule = currModule + 1;
            setCurrModule(nextModule);
            setProgressBar(nextModule / numModules);
            updateProgress(nextModule);
        }
    }

    const handleUpdateModuleProgressBar = (moduleProgress: number) => {
        // 1 - 100% complete
        let moduleProgressAdapted = moduleProgress === 1 && isCurrModuleLast() ? 1 : moduleProgress * 0.9;
        setProgressBar((moduleProgressAdapted + currModule) / numModules);
    }

    const handleCompleteLesson = () => {
        handleCompletion();
        closeLesson();
    }

    // if (loading) {
    //     return <div></div>;
    // }

    return (
        <div>
            <IonLoading
                isOpen={loading}
                message={'Loading lesson...'}
            />
            {!loading && (
                <>
                <IonProgressBar
                    value={progressBar}
                    color={'success'} />
                <div>
                    {
                        preloadedModules.length > 0 && {
                            [CONTENT_TABLE]: <InfoContentModule
                                content={preloadedModules[currModule].object as Content}
                                moveToNextModule={moveToNextModule}
                                updateProgressBar={handleUpdateModuleProgressBar}
                            />,
                            [QUIZ_TABLE]: <QuizModule
                                key={`quiz-${currModule}`}
                                quiz={preloadedModules[currModule].object as Quiz}
                                preloadedImages={preloadedModules[currModule].preloadedImages}
                                moveToNextModule={moveToNextModule}
                                updateProgressBar={handleUpdateModuleProgressBar}
                            />,
                            [LLMCHECK_TABLE]: <LLMCheckModule
                                key={`llmcheck-${currModule}`}
                                llmCheck={preloadedModules[currModule].object as LLMCheck}
                                preloadedImages={preloadedModules[currModule].preloadedImages}
                                moveToNextModule={moveToNextModule}
                                updateProgressBar={handleUpdateModuleProgressBar}
                            />,
                            [DO_DONT_TABLE]: <DoDontModule
                                doDont={preloadedModules[currModule].object as DoDont}
                                moveToNextModule={moveToNextModule}
                                updateProgressBar={handleUpdateModuleProgressBar}
                            />,
                            [PAIR_MATCHING_TABLE]: <PairMatchingModule
                                pairMatching={preloadedModules[currModule].object as PairMatching}
                                moveToNextModule={moveToNextModule}
                                updateProgressBar={handleUpdateModuleProgressBar}
                            />,
                            [STORY_TABLE]: <StoryModule
                                key={`story-${currModule}`}
                                story={preloadedModules[currModule].object as Story}
                                moveToNextModule={moveToNextModule}
                                updateProgressBar={handleUpdateModuleProgressBar}
                            />,
                            [SCENARIO_TABLE]: <PracticeModule
                                situation={preloadedModules[currModule].object as Situation}
                                moveToNextModule={moveToNextModule}
                                updateProgressBar={handleUpdateModuleProgressBar}
                            />
                        }[preloadedModules[currModule].table]
                    }
                </div>
            </>
            )}
        </div>
    );
}

export default LessonSections;
