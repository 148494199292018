import React, { useState, useEffect } from 'react';
import { IonTitle, IonToolbar, IonHeader, IonAvatar, TextareaChangeEventDetail, IonModal, IonButton, IonContent, IonList, IonItem, IonLabel, IonTextarea, IonIcon } from '@ionic/react';
import { send } from 'ionicons/icons';
import { supabase } from '../../../service/authService';
import { Comment } from "../../../types/ai_pivot/situationTypes";
import { useAuth } from '../../../context/AuthContext';
import { formatDistanceToNow } from 'date-fns';
import { getUserDetails } from '../../../service/userService';
import { UserDetails } from "../../../types/userTypes";

interface CommentModalProps {
    isOpen: boolean;
    onClose: () => void;
    situationId: number;
    situation_version: number;
}

const CommentModal: React.FC<CommentModalProps> = ({ isOpen, onClose, situationId, situation_version }) => {
    const [newComment, setNewComment] = useState('');
    const [comments, setComments] = useState<Comment[]>([]);
    const { session } = useAuth();
    const currentUser = session?.user;

    
    useEffect(() => {
        console.log(isOpen)
        if (isOpen) {
            fetchComments();
        }
    }, [isOpen, situationId]);

    // async function fetchComments() {
    //     const { data, error } = await supabase
    //         .from('comments')
    //         .select('*')
    //         .eq('situation_id', situationId);
    //     if (error) {
    //         console.error('Error fetching comments:', error.message);
    //         return;
    //     }
    //     setComments(data);
    // }

    async function fetchComments() {
        const { data, error } = await supabase
            .from('comments')
            .select(`
                *,
                user:user_id (
                    username
                )
            `)
            .eq('situation_id', situationId)
            .order('created_at', { ascending: true });
        console.log(data)
        if (error) {
            console.error('Error fetching comments:', error.message);
            return;
        }
    
        // Map over the fetched comments to include the user's name directly at the root level of each comment object.
        const enrichedComments = data.map(comment => ({
            ...comment,
            userName: comment.user.username // Assuming 'name' is the field in the users table
        }));
    
        setComments(enrichedComments);
    }

    async function handlePostComment(event: React.FormEvent) {
        event.preventDefault(); 
        const trimmedComment = newComment.trim();
        console.log('Trimmed Comment:', trimmedComment);  

        if (!trimmedComment) {
            alert('Cannot post an empty comment.'); 
            return;
        }

        const { data, error } = await supabase
            .from('comments')
            .insert([{ text: newComment.trim(), situation_id: situationId, user_id: currentUser?.id, situation_version: situation_version }])
            .select();        
            if (error) {
            console.error('Error posting comment:', error.message);
            return;
        }
        if (data) { 
            console.log('Comment posted:', data);
            setComments(currentComments => [...currentComments, ...data]);
        } else {
            console.log('No data returned from insert operation');
        }
        fetchComments();
        setNewComment('');  
    }

    const handleInputChange = (event: CustomEvent<TextareaChangeEventDetail>) => {
        const newValue = event.detail.value ?? '';  
        setNewComment(newValue);
        console.log('Input Changed:', newValue);  
    };


    return (
        
        <IonModal isOpen={isOpen} onDidDismiss={onClose}>
            <IonHeader>
                <IonToolbar>
                <IonTitle style={{ textAlign: 'center', width: '100%' }}>Comments</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                {/* {comments.map((comment, index) => (
                    <IonItem key={comment.id}> 
                        <IonLabel>
                            <p>{comment.userName}</p>
                            <p>{comment.text}</p>
                            <p>{formatDistanceToNow(new Date(comment.created_at), { addSuffix: true })}</p>
                        </IonLabel>
                    </IonItem>
                ))} */}
                {comments.map((comment, index) => (
                    <IonItem key={comment.id} lines="full" style={{ marginBottom: "10px" }}>
                        
                        <IonLabel>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h3 style={{ fontWeight: "bold", marginRight: "10px" }}>{comment.userName}</h3>
                                <p style={{ fontSize: "small", color: "gray" }}>
                                    {formatDistanceToNow(new Date(comment.created_at), { addSuffix: true })}
                                </p>
                                <span style={{ color: 'gray', fontSize: 'small' }}>
                                    V{comment.situation_version}
                                </span>
                            </div>
                            <p style={{ marginTop: "5px" }}>{comment.text}</p>
                
                        </IonLabel>
                    </IonItem>
                ))}
                    {/* <form onSubmit={handlePostComment}>
                        <IonItem>
                            <IonTextarea
                                value={newComment}
                                onIonInput= {handleInputChange}
                                placeholder="Write a comment..."
                            />
                            <IonButton type="submit">
                                <IonIcon icon={send} />
                            </IonButton>
                        </IonItem>
                    </form> */}

                    <form onSubmit={handlePostComment} style={{
                        borderTop: "2px solid #ccc",  
                    }}>
                        <IonItem>
                        <IonTextarea
                            value={newComment}
                            onIonInput={handleInputChange}
                            placeholder="Write a comment..."
                            
                        />
                        <IonButton type="submit">
                            <IonIcon icon={send} />
                        </IonButton>
                        </IonItem>
                    </form>
                    
                </IonList>
            </IonContent>
            <IonButton onClick={onClose}>Close</IonButton>
        </IonModal>
    );
};

export default CommentModal;
