import axios from 'axios';
import { VoicesResponse, Voice } from '../types/voiceTypes';

const API_URL = 'https://api.elevenlabs.io/v1/voices';

// interface Voices {
//     [key: string]: Voice;  
//   }

export const fetchVoices = async (apiKey: string): Promise<Voice[]> => {
    try {
        const response = await axios.get<VoicesResponse>(API_URL, {
            headers: { 'Xi-Api-Key': apiKey }
        });
        // console.log(response.data.voices)
        // let voicesData: Voices = {};
        // response.data.voices.forEach((voice, index) => {
        //     const key = voice.voice_id || `voice_${index}`;
        //     if (!voice.labels) {
        //         // Handle the case where labels might be undefined
        //         // You must define what should happen here. Maybe set default values or skip adding this voice.
        //         console.log(`Skipping voice ${key} as it lacks label data.`);
        //         return;  // Skip this entry
        //     }

        //     voicesData[key] = {
        //         voice_id: voice.voice_id,
        //         name: voice.name,
        //         description: voice.description,
        //         preview_url: voice.preview_url,
        //         labels: {
        //             accent: voice.labels.accent,
        //             age: voice.labels.age,
        //             description: voice.labels.description,
        //             gender: voice.labels.gender,
        //             use_case: voice.labels.use_case
        //         } 
        //     };
        //   });
        //   console.log(voicesData);
        return response.data.voices;  
    } catch (error) {
        console.error('Failed to fetch voices:', error);
        throw error;
    }
};