import Picture from "../../../../home/Picture";
import { useState, useEffect } from "react";
import { IonSpinner } from "@ionic/react";

interface ImageContentProps {
    image_url: string;
    preloadedImage?: HTMLImageElement;  
}

const ImageContent: React.FC<ImageContentProps> = ({ image_url, preloadedImage }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (!preloadedImage) {
            const img = new Image();
            img.src = image_url;
            img.onload = () => setIsLoaded(true);
            img.onerror = () => setHasError(true);
        } else {
            setIsLoaded(true);
        }
    }, [image_url, preloadedImage]);

    if (hasError) {
        return <div style={{ color: 'red' }}>Image failed to load</div>;
    }
    // return (
    //     <div
    //         style={{
    //             position: 'relative',
    //             width: '100%',
    //             display: 'flex',
    //             justifyContent: 'center',
    //             marginTop: '30px',
    //             marginBottom: '30px'
    //         }}>
    //         <img style={{
    //             borderRadius: '5px',
    //             width: '100%',
    //             maxWidth: '350px',
    //             objectFit: 'cover',
    //             height: '350px'
    //         }}
    //             alt="image"
    //             src={image_url} />
    //     </div >
    // );
    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '30px',
                marginBottom: '30px'
            }}
        >
            {!isLoaded && (
                <IonSpinner name="crescent" style={{ margin: 'auto' }} /> // Loading indicator while preloading
            )}
            {isLoaded && (
                <img
                    style={{
                        borderRadius: '5px',
                        width: '100%',
                        maxWidth: '350px',
                        objectFit: 'cover',
                        height: '350px'
                    }}
                    alt="image"
                    src={preloadedImage ? preloadedImage.src : image_url}
                />
            )}
        </div>
    );
}

export default ImageContent;