import { IonButton } from "@ionic/react";

const TextButton: React.FC<{ option: string, selectOption: () => void, isSelected: boolean, canClick: boolean }> = (
    { option, selectOption, isSelected, canClick
    }) => {

    return (
        <IonButton
            className="ion-text-wrap"
            expand="block"
            onClick={() => {
                if (canClick) {
                    selectOption();
                }
            }}
            color={isSelected ? 'medium' : 'light'}
            style={{ height: '100%'}}>{option}</IonButton>
    );
}

export default TextButton;