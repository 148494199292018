// import React, { useState } from 'react';
// import { createAvatar } from '@dicebear/core';
// import { personas } from '@dicebear/collection';
// import { IonPage, IonContent, IonButton, IonSelect, IonSelectOption, IonItem, IonLabel, IonHeader, IonToolbar, IonTitle, IonImg } from "@ionic/react";

// type HairType = "shortCombover" | "long" | "sideShave" | "curlyHighTop" | "bobCut" | "curly" | "pigtails" | "curlyBun" | "buzzcut" | "bobBangs" | "bald" | "balding" | "cap" | "bunUndercut" | "mohawk";
// type FacialHairType = "beardMustache" | "pyramid" | "walrus" | "goatee" | "shadow" | "soulPatch";
// type BodyType = "small" | "rounded" | "squared" | "checkered";
// type EyesType = "happy" | "open" | "sleep" | "wink" | "glasses" | "sunglasses";
// type MouthType = "smile" | "frown" | "surprise" | "pacifier" | "bigSmile" | "smirk" | "lips";
// type NoseType = "smallRound" | "mediumRound" | "wrinkles";

// const CharacterCustomizationPage: React.FC = () => {
//     const [skinColor, setSkinColor] = useState<string>('e5a07e');
//     const [hair, setHair] = useState<HairType>('shortCombover');
//     const [facialHair, setFacialHair] = useState<FacialHairType>('beardMustache');
//     const [body, setBody] = useState<BodyType>('rounded');
//     const [eyes, setEyes] = useState<EyesType>('happy');
//     const [mouth, setMouth] = useState<MouthType>('smile');
//     const [nose, setNose] = useState<NoseType>('smallRound');
//     const [backgroundColor, setBackgroundColor] = useState<string>('ffffff');

//     const generateAvatar = () => {
//         return createAvatar(personas, {
//             seed: 'custom-avatar', // Keep consistent or change based on user input to vary the avatar
//             skinColor: [skinColor],
//             hair: [hair],
//             facialHair: [facialHair],
//             body: [body],
//             eyes: [eyes],
//             mouth: [mouth],
//             nose: [nose],
//             backgroundColor: [backgroundColor]
//         });
//     };

//     const avatarSvg = generateAvatar().toString();

//     const svgToUrl = (svg: string): string => {
//         // Properly handle non-Latin1 characters
//         const encoded = encodeURIComponent(svg)
//             .replace(/%([0-9A-F]{2})/g, function(match, p1) {
//                 return String.fromCharCode(parseInt(p1, 16))
//             });
//         return `data:image/svg+xml;base64,${btoa(encoded)}`;
//     };

//     return (
//         <IonPage>
//             <IonHeader>
//                 <IonToolbar>
//                     <IonTitle>Customize Your AI Friend</IonTitle>
//                 </IonToolbar>
//             </IonHeader>
//             <IonContent className="ion-padding">
//                 <IonItem>
//                     <IonLabel>Skin Color</IonLabel>
//                     <IonSelect value={skinColor} onIonChange={e => setSkinColor(e.detail.value)}>
//                         <IonSelectOption value="e5a07e">Light</IonSelectOption>
//                         <IonSelectOption value="92594b">Medium</IonSelectOption>
//                         <IonSelectOption value="623d36">Dark</IonSelectOption>
//                     </IonSelect>
//                 </IonItem>
//                 {/* Repeat similar blocks for hair, facialHair, body, eyes, mouth, nose, and backgroundColor */}
//                 <IonImg src={svgToUrl(avatarSvg)} />
//                 <IonButton expand="block" onClick={() => console.log("Save Changes")}>Save Changes</IonButton>
//             </IonContent>
//         </IonPage>
//     );
// };

// export default CharacterCustomizationPage;

import React, { useState } from 'react';
import { createAvatar } from '@dicebear/core';
import { personas } from '@dicebear/collection';
import { IonPage, IonContent, IonButton, IonSelect, IonSelectOption, IonItem, IonLabel, IonHeader, IonToolbar, IonTitle, IonImg } from "@ionic/react";

// Define types for each customizable attribute
type HairType = "shortCombover" | "long" | "sideShave" | "curlyHighTop" | "bobCut" | "curly" | "pigtails" | "curlyBun" | "buzzcut" | "bobBangs" | "bald" | "balding" | "cap" | "bunUndercut" | "mohawk";
type FacialHairType = "beardMustache" | "pyramid" | "walrus" | "goatee" | "shadow" | "soulPatch";
type BodyType = "small" | "rounded" | "squared" | "checkered";
type EyesType = "happy" | "open" | "sleep" | "wink" | "glasses" | "sunglasses";
type MouthType = "smile" | "frown" | "surprise" | "pacifier" | "bigSmile" | "smirk" | "lips";
type NoseType = "smallRound" | "mediumRound" | "wrinkles";

const CharacterCustomizationPage: React.FC = () => {
    const [skinColor, setSkinColor] = useState<string>('e5a07e');
    const [hair, setHair] = useState<HairType>('shortCombover');
    const [facialHair, setFacialHair] = useState<FacialHairType>('beardMustache');
    const [body, setBody] = useState<BodyType>('rounded');
    const [eyes, setEyes] = useState<EyesType>('happy');
    const [mouth, setMouth] = useState<MouthType>('smile');
    const [nose, setNose] = useState<NoseType>('smallRound');
    const [backgroundColor, setBackgroundColor] = useState<string>('ffffff');

    const generateAvatar = () => {
        return createAvatar(personas, {
            seed: 'custom-avatar',
            skinColor: [skinColor],
            hair: [hair],
            facialHair: [facialHair],
            body: [body],
            eyes: [eyes],
            mouth: [mouth],
            nose: [nose],
            backgroundColor: [backgroundColor]
        });
    };

    const avatarSvg = generateAvatar().toString();

    const svgToUrl = (svg: string): string => {
        const encoded = encodeURIComponent(svg)
            .replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16)));
        return `data:image/svg+xml;base64,${btoa(encoded)}`;
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Customize Your AI Friend</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem>
                    <IonLabel>Skin Color</IonLabel>
                    <IonSelect value={skinColor} onIonChange={e => setSkinColor(e.detail.value)}>
                        <IonSelectOption value="e5a07e">Light</IonSelectOption>
                        <IonSelectOption value="92594b">Medium</IonSelectOption>
                        <IonSelectOption value="623d36">Dark</IonSelectOption>
                    </IonSelect>
                </IonItem>
                {/* Hair Type Selection */}
                <IonItem>
                    <IonLabel>Hair</IonLabel>
                    <IonSelect value={hair} onIonChange={e => setHair(e.detail.value as HairType)}>
                        <IonSelectOption value="shortCombover">Short Combover</IonSelectOption>
                        <IonSelectOption value="long">Long</IonSelectOption>
                        <IonSelectOption value="bunUndercut">Bun Undercut</IonSelectOption>
                        {/* Add more options as needed */}
                    </IonSelect>
                </IonItem>
                {/* Facial Hair Type Selection */}
                <IonItem>
                    <IonLabel>Facial Hair</IonLabel>
                    <IonSelect value={facialHair} onIonChange={e => setFacialHair(e.detail.value as FacialHairType)}>
                        <IonSelectOption value="beardMustache">Beard Mustache</IonSelectOption>
                        <IonSelectOption value="goatee">Goatee</IonSelectOption>
                        {/* Add more options as needed */}
                    </IonSelect>
                </IonItem>
                {/* Body Type Selection */}
                <IonItem>
                    <IonLabel>Body</IonLabel>
                    <IonSelect value={body} onIonChange={e => setBody(e.detail.value as BodyType)}>
                        <IonSelectOption value="rounded">Rounded</IonSelectOption>
                        <IonSelectOption value="squared">Squared</IonSelectOption>
                        {/* Add more options as needed */}
                    </IonSelect>
                </IonItem>
                {/* Eyes Type Selection */}
                <IonItem>
                    <IonLabel>Eyes</IonLabel>
                    <IonSelect value={eyes} onIonChange={e => setEyes(e.detail.value as EyesType)}>
                        <IonSelectOption value="happy">Happy</IonSelectOption>
                        <IonSelectOption value="wink">Wink</IonSelectOption>
                        {/* Add more options as needed */}
                    </IonSelect>
                </IonItem>
                {/* Mouth Type Selection */}
                <IonItem>
                    <IonLabel>Mouth</IonLabel>
                    <IonSelect value={mouth} onIonChange={e => setMouth(e.detail.value as MouthType)}>
                        <IonSelectOption value="smile">Smile</IonSelectOption>
                        <IonSelectOption value="bigSmile">Big Smile</IonSelectOption>
                        {/* Add more options as needed */}
                    </IonSelect>
                </IonItem>
                {/* Nose Type Selection */}
                <IonItem>
                    <IonLabel>Nose</IonLabel>
                    <IonSelect value={nose} onIonChange={e => setNose(e.detail.value as NoseType)}>
                        <IonSelectOption value="smallRound">Small Round</IonSelectOption>
                        <IonSelectOption value="mediumRound">Medium Round</IonSelectOption>
                        {/* Add more options as needed */}
                    </IonSelect>
                </IonItem>
                {/* Background Color Selection */}
                <IonItem>
                    <IonLabel>Background Color</IonLabel>
                    <IonSelect value={backgroundColor} onIonChange={e => setBackgroundColor(e.detail.value)}>
                        <IonSelectOption value="ffffff">White</IonSelectOption>
                        <IonSelectOption value="f0f0f0">Light Gray</IonSelectOption>
                        {/* Add more options as needed */}
                    </IonSelect>
                </IonItem>
                <IonImg src={svgToUrl(avatarSvg)} />
                <IonButton expand="block" onClick={() => console.log("Save Changes")}>Save Changes</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default CharacterCustomizationPage;
