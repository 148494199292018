import React, { useState, useEffect, useRef } from 'react';
import {
    IonPage, IonInput, IonTextarea, IonHeader, IonToolbar, IonTitle, 
    IonContent, IonButton, IonSelect, IonSelectOption, IonLabel, IonItem, 
    IonCard, IonCardHeader, IonToggle, 
    IonModal, IonCardTitle, IonCardContent, 
    IonIcon, IonLoading, IonButtons, IonList, IonRange, useIonToast, IonSpinner
} from '@ionic/react';
import { supabase } from '../../../service/authService';
import { useAuth } from '../../../context/AuthContext';
import { Situation } from '../../../types/ai_pivot/situationTypes';
import { useLocation } from 'react-router-dom';
import { TextFieldTypes } from '@ionic/core';
import { camera, chevronDown, diceOutline, helpCircleOutline, imageOutline, informationCircleOutline } from 'ionicons/icons';
import './CreateEdit.css';
import VoicesList from './DisplayVoices';
import axios from 'axios';
import { BACKGROUND_AUDIO_URL, DESCRIPTION_PLACEHOLDER, FEEDBACK_PLACEHOLDER, PROMPT_PLACEHOLDER, TITLE_PLACEHOLDER } from '../../../util/constants';

import '../../../theme/layout.css';
import { Voice } from '../../../types/voiceTypes';
import { fetchVoices } from '../../../service/voiceService';

interface InputFieldProps {
    label: string;
    type?: TextFieldTypes;
    value: string;
    onChange: (e: CustomEvent) => void;
}

interface TextAreaFieldProps {
    label: string;
    value: string;
    onChange: (e: CustomEvent) => void;
}

const InputField: React.FC<InputFieldProps> = ({ label, type = "text", value, onChange }) => {
    return (
        <div className="input-field-container">
            <label className="input-label">{label}</label>
            <IonItem lines="full" className="rounded-input">
                <IonInput type={type} value={value} onIonChange={onChange} />
            </IonItem>
        </div>
    );
};


const TextAreaField: React.FC<TextAreaFieldProps> = ({ label, value, onChange }) => {
    return (
        <div className="input-field-container">
            <label className="input-label">{label}</label>
            <IonItem lines="full" className="rounded-input">
                <IonTextarea value={value} onIonChange={onChange} />
            </IonItem>
        </div>

    );
};

const CreateEditScenarioForm: React.FC<{
    initialData?: Situation,
    show: boolean,
    dismiss: () => void
}> = ({ initialData, show, dismiss }) => {
    const { userProfile } = useAuth();
    const [imageUrl, setImageUrl] = useState(initialData?.image_url || '');
    const [uploading, setUploading] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [preview_url, setPreviewUrl] = useState('');
    const [showVoicesModal, setShowVoicesModal] = useState(false);
    const [voiceSelected, setSelectedVoice] = useState<string>();
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [voices, setVoices] = useState<Voice[]>([]);
    const [popUp] = useIonToast();
    const [isRandomizing, setIsRandomizing] = useState(false);

    const apiKey = '0e53a64fbff1b23e8537f45831b6561c';
    useEffect(() => {
        const loadVoices = async () => {
            const voicesData = await fetchVoices(apiKey);
            setVoices(voicesData);

            if (initialData) {
                voicesData.forEach((v) => {
                    if (v.voice_id === initialData.voice_id) {
                        setSelectedVoice(v.name);
                    }
                })
            }
        };

        loadVoices();
    }, []);

    const [scenario, setScenario] = useState(initialData || {
        title: '',
        description: '',
        prompt: '',
        feedback_prompt: '',
        image_url: '',
        audio_url: '',
        audio_volume: 0.5,
        voice_id: '',
        author_id: userProfile?.userid,
        duration: 15,
        type: 'community',
        author_username: userProfile?.username || '',
        category: "Other",
        public: true,
    });

    useEffect(() => {
        if (userProfile && !(scenario.author_id || scenario.author_username)) {
            setScenario({...scenario, author_id: userProfile.userid, author_username: userProfile.username});
        }
    }, [userProfile, scenario]);

    const handleRandomize = async () => {
        try {
            setIsRandomizing(true);
            popUp({
                message: `Generating random fields. It will take around 20 seconds. Please, wait.`,
                duration: 5000,
                position: 'middle',
            });
            const response = await axios.post('https://finalbackend-nuob.onrender.com/randomize');
            console.log(response)
            if (response.data) {
                setScenario({
                    ...scenario,
                    title: response.data.title,
                    description: response.data.description,
                    // prompt: response.data.prompt + "Act and reply naturally as the character described. - Reply in short sentences. - Not ask many questions; let the user lead the conversation. - Only verbalize responses; do not describe actions (e.g., 'Runs off' or 'Smiles').",
                    prompt: response.data.prompt,
                    feedback_prompt: response.data.feedback_prompt,
                    author_id: userProfile?.userid || '',
                    author_username: userProfile?.username || '',
                    image_url: response.data.image_url,
                    voice_id: response.data.voice_id
                });
                console.log(scenario.voice_id)
            }
            setImageUrl(response.data.image_url);
            const voicesData = await fetchVoices(apiKey);
            voicesData.forEach((v) => {
                if (v.voice_id === response.data.voice_id) {
                    setSelectedVoice(v.name);
                }
            })
        } catch (error) {
            console.error('Error fetching random data:', error);
        } finally {
            setIsRandomizing(false);
        }
    };
    useEffect(() => {
        // const loadInitialVoiceName = async () => {
        //     if (initialData && !voiceSelected) {
        //         // load name of the corresponding voice id
        //         const { data, error } = await supabase.from('')
        //     }
        // }
        // loadInitialVoiceName();
    }, [initialData])

    const handleChange = (field: keyof Situation) => (e: CustomEvent) => {
        console.log('handing change');
        setScenario({ ...scenario, [field]: e.detail.value });
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }
        const file = event.target.files[0];
        setUploading(true);

        const user = supabase.auth.getUser();
        if (!user) return;
        const fileExt = file.name.split('.').pop() || 'png';
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${(await user).data.user?.id}/${fileName}`;


        try {
            const { error: uploadError } = await supabase.storage
                .from('situation-images')
                .upload(filePath, file);

            if (uploadError) {
                throw new Error('Failed to upload image: ' + uploadError.message);
            }

            const { data: { publicUrl } } = supabase.storage
                .from('situation-images')
                .getPublicUrl(filePath);

            // if (urlError) {
            //     throw new Error('Failed to get image URL: ' + urlError.message);
            // }

            setImageUrl(publicUrl);
            setScenario(prevState => ({ ...prevState, image_url: publicUrl }));
        } catch (error) {
            // alert(error.message);
        } finally {
            setUploading(false);
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
    };

    const handleSubmit = async () => {
        if (!scenario.title || !scenario.description || !scenario.prompt) {
            alert('Please fill in all required fields.');
            return;
        }
        console.log(scenario)

        const table = supabase.from('situation');
        const action = initialData ? table.update(scenario).match({ id: initialData.id }) : table.insert(scenario);

        const { data, error } = await action;
        if (error) {
            alert(`Error: ${error.message}`);
            return;
        }
        popUp({
            message: `Scenario ${initialData ? 'updated' : 'created'} successfully.`,
            duration: 1500,
            position: 'middle',
          });
        dismiss();

        if (window.location.pathname === '/profile') {
            window.location.reload();
        }
    };

    return (
        <IonModal
            className='modal'
            isOpen={show}
            canDismiss={true}
            onWillDismiss={() => dismiss()}
            mode='ios'
        >
            <IonPage className='modal-page'>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={() => dismiss()}>
                                Cancel
                            </IonButton>
                        </IonButtons>
                        <IonTitle>{initialData ? 'Edit scenario' : 'Create new scenario'}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton strong={true} onClick={handleSubmit}>
                                Save
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen={true} color="light">
                    <IonModal
                        isOpen={showVoicesModal}
                        onDidDismiss={() => setShowVoicesModal(false)}
                        mode="ios"
                    >
                        <VoicesList
                            onSelectVoice={(selectedVoice) => {
                                setShowVoicesModal(false);
                                scenario.voice_id = selectedVoice.voice_id;
                                setPreviewUrl(selectedVoice.preview_url);
                                setSelectedVoice(selectedVoice.name);
                            }}
                            dismiss={() => setShowVoicesModal(false)}
                            currentlySelected={scenario.voice_id}
                            voices={voices}
                        />
                    </IonModal>
                    <IonModal
                        mode='ios'
                        className='info-modal'
                        isOpen={openInfoModal}
                        onDidDismiss={() => setOpenInfoModal(false)}
                        initialBreakpoint={1}
                        breakpoints={[0, 1]}
                    >
                        <div className='blank-space'></div>
                        <div className="info-block content-block">
                            {infoText}
                        </div>
                        <div className='blank-space'></div>
                    </IonModal>
                    <div className="content-block ion-padding">
                        <br />
                        <br />
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end', marginRight: 16, marginLeft: 15}}>
                            <input type="file" accept="image/*" onChange={handleFileChange} hidden ref={inputRef} />
                            {
                                imageUrl ?
                                    (<img
                                        onClick={() => inputRef.current?.click()}
                                        src={imageUrl}
                                        alt="Preview"
                                        className='image-preview' />
                                    )
                                    :
                                    (<IonIcon
                                        className="upload-image img-color"
                                        icon={imageOutline}
                                        slot="start"
                                        onClick={() => inputRef.current?.click()}
                                    />)
                            }
                            <div>
                                {isRandomizing ?
                                    <IonSpinner></IonSpinner> 
                                        : 
                                    <IonButton onClick={handleRandomize}><IonIcon icon={diceOutline} size='small' slot="start" />
                                        Randomize
                                    </IonButton>}
                            </div>
                        </div>
                        <IonList inset={true}>
                            <IonItem
                                style={{ '--padding-end': '0px', '--inner-padding-end': '8px' }}
                            >
                                <IonInput
                                    label-placement="floating"
                                    label="Title"
                                    value={scenario.title}
                                    onIonInput={handleChange('title')}
                                    counter={true}
                                    maxlength={60}
                                    placeholder={TITLE_PLACEHOLDER}
                                    style={{ '--border-width': 0, '--padding-top': '5px', '--padding-end': '8px' }}
                                ></IonInput>
                                <IonIcon
                                    icon={informationCircleOutline}
                                    style={{
                                        // position: 'absolute',
                                        // top: 0,
                                        // right: 0,
                                        // padding: 4,
                                        fontSize: 21
                                    }}
                                    onClick={() => {
                                        setInfoText(TITLE_PLACEHOLDER);
                                        setOpenInfoModal(true);
                                    }}
                                    color='medium'
                                ></IonIcon>
                            </IonItem>
                        </IonList>

                        <IonList inset={true}>
                            <IonItem style={{ '--padding-end': '0px', '--inner-padding-end': '8px' }} >
                                <IonTextarea
                                    label="Description"
                                    label-placement="floating"
                                    rows={3}
                                    value={scenario.description}
                                    onIonInput={handleChange('description')}
                                    counter={true} 
                                    maxlength={500}
                                    placeholder={DESCRIPTION_PLACEHOLDER}
                                    style={{ '--border-width': 0, '--padding-top': '5px', '--padding-end': '8px' }}
                                >
                                </IonTextarea>
                                <IonIcon
                                    slot='end'
                                    icon={informationCircleOutline}
                                    style={{
                                        // position: 'absolute',
                                        // top: 0,
                                        // right: 0,
                                        // padding: 4,
                                        fontSize: 21
                                    }}
                                    color='medium'
                                    onClick={() => {
                                        setInfoText(DESCRIPTION_PLACEHOLDER);
                                        setOpenInfoModal(true);
                                    }}
                                ></IonIcon>
                            </IonItem>
                        </IonList>

                        <IonList inset={true}>
                            <IonItem style={{ '--padding-end': '0px', '--inner-padding-end': '8px' }} >
                                <IonTextarea
                                    label="Prompt"
                                    label-placement="floating"
                                    rows={15}
                                    value={scenario.prompt}
                                    onIonInput={handleChange('prompt')}
                                    counter={true} maxlength={32000}
                                    style={{ '--border-width': 0, '--padding-top': '5px', '--padding-end': '8px' }}
                                    placeholder={PROMPT_PLACEHOLDER}
                                >
                                </IonTextarea>
                                <IonIcon
                                    slot='end'
                                    icon={informationCircleOutline}
                                    style={{
                                        // position: 'absolute',
                                        // top: 0,
                                        // right: 0,
                                        // padding: 4,
                                        fontSize: 21
                                    }}
                                    color='medium'
                                    onClick={() => {
                                        setInfoText(PROMPT_PLACEHOLDER);
                                        setOpenInfoModal(true);
                                    }}
                                ></IonIcon>
                            </IonItem>
                        </IonList>

                        <IonList inset={true}>
                            <IonItem style={{ '--padding-end': '0px', '--inner-padding-end': '8px' }} >
                                <IonTextarea
                                    label="Feedback prompt"
                                    label-placement="floating"
                                    rows={10}
                                    value={scenario.feedback_prompt}
                                    onIonInput={handleChange('feedback_prompt')}
                                    counter={true} maxlength={6000}
                                    style={{ '--border-width': 0, '--padding-top': '5px', '--padding-end': '8px' }}
                                    placeholder={FEEDBACK_PLACEHOLDER}
                                >
                                </IonTextarea>
                                <IonIcon
                                    slot='end'
                                    icon={informationCircleOutline}
                                    style={{
                                        // position: 'absolute',
                                        // top: 0,
                                        // right: 0,
                                        // padding: 4,
                                        fontSize: 21
                                    }}
                                    color='medium'
                                    onClick={() => {
                                        setInfoText(FEEDBACK_PLACEHOLDER);
                                        setOpenInfoModal(true);
                                    }}
                                ></IonIcon>
                            </IonItem>
                        </IonList>

                        <IonList inset={true}>
                            <IonItem style={{ '--padding-end': '0px', '--inner-padding-end': '8px' }} >
                                <IonInput
                                    label-placement="floating"
                                    label="Background audio URL"
                                    value={scenario.audio_url}
                                    onIonInput={handleChange('audio_url')}
                                    style={{ '--border-width': 0, '--padding-top': '5px', '--padding-end': '8px' }}
                                    counter={true}
                                    maxlength={1000}
                                    placeholder={BACKGROUND_AUDIO_URL}
                                ></IonInput>
                                <IonIcon
                                    icon={informationCircleOutline}
                                    style={{
                                        fontSize: 21
                                    }}
                                    color='medium'
                                    onClick={() => {
                                        setInfoText(BACKGROUND_AUDIO_URL);
                                        setOpenInfoModal(true);
                                    }}
                                ></IonIcon>
                            </IonItem>
                            {scenario.audio_url && <IonItem>
                                <IonRange
                                    mode="ios"
                                    labelPlacement="fixed"
                                    label="Volume"
                                    min={0}
                                    max={1}
                                    onIonChange={(e: CustomEvent) => setScenario({ ...scenario, audio_volume: parseFloat(e.detail.value) })}
                                    step={0.1}
                                    value={scenario.audio_volume || 0.5}
                                >
                                </IonRange>
                            </IonItem>
                            }
                        </IonList>
                        <IonList inset={true}>
                            <IonItem onClick={() => setShowVoicesModal(true)}>
                                <IonLabel> Voice </IonLabel>
                                {voiceSelected ? voiceSelected : <IonIcon icon={chevronDown} size='small'></IonIcon>}
                            </IonItem>
                        </IonList>

                        <IonList inset={true}>
                            <IonItem>
                                <IonLabel>Category</IonLabel>
                                <IonSelect value={scenario.category} onIonChange={handleChange('category')}>
                                    <IonSelectOption value="Dating">Dating</IonSelectOption>
                                    <IonSelectOption value="Therapy">Therapy</IonSelectOption>
                                    <IonSelectOption value="Small talk">Small talk</IonSelectOption>
                                    <IonSelectOption value="Philosophy">Philosophy</IonSelectOption>
                                    <IonSelectOption value="Other">Other</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Public</IonLabel>
                                <IonToggle
                                    checked={scenario.public}
                                    onIonChange={e => setScenario({ ...scenario, public: e.detail.checked })}
                                />
                            </IonItem>
                        </IonList>

                        <IonList inset={true} style={{ backgroundColor: 'transparent' }}>
                            <IonButton expand="block" onClick={handleSubmit} disabled={uploading}>
                                {uploading ? 'Processing...' : (initialData ? 'Update' : 'Create')}
                            </IonButton>
                        </IonList>
                    </div>
                </IonContent>
            </IonPage>
        </IonModal>
    );
};

export default CreateEditScenarioForm;




interface VolumeControlFieldProps {
    label: string;
    value: string;
    onChange: (event: CustomEvent) => void;
}

const VolumeControlField: React.FC<VolumeControlFieldProps> = ({ label, value, onChange }) => {
    return (
        // <IonItem>
        <IonRange
            mode="ios"
            labelPlacement="fixed"
            label="Volume"
            min={0}
            max={1}
            onIonChange={({ detail }) => console.log('ionChange emitted value: ' + detail.value)}
            step={0.1}
        >
        </IonRange>);
    {/* <IonLabel>{label}</IonLabel>
            <input type="range" min="0" max="1" step="0.1" value={value}
                onChange={(e) => onChange(new CustomEvent('change', { detail: { value: e.target.value } }))}
            /> */}
    // </IonItem>
};

interface ImageUploadFieldProps {
    label: string;
    value: string;
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageUploadField = ({ label, value, onFileChange }: ImageUploadFieldProps) => {
    return (
        <IonItem>
            <IonLabel>{label}</IonLabel>
            <input type="file" accept="image/*" onChange={onFileChange} />
            {value && <img src={value} alt="Preview" style={{ width: '100%', marginTop: '10px' }} />}
        </IonItem>
    );
};

