import { useEffect, useState } from "react";
import { Situation } from "../../../types/ai_pivot/situationTypes";
import { getSituations } from "../../../service/situationService";
import React from "react";
import SituationCategorySelector from "./SituationCategorySelector";
import Situations from "../situation/Situations";
import { IonSpinner } from "@ionic/react";

const LibraryComponent: React.FC = () => {
    const [situations, setSituations] = useState<Situation[]>();
    const [category, setCategory] = useState<Situation['category'] | 'For you'>('For you');

    useEffect(() => {
        const fetchSituations = async () => {
            const { data, error } = await getSituations(category);
            if (error) {
                console.error('error fetching situations: ', JSON.stringify(error));
            } else if (data) {
                setSituations(data);
                console.log(data[0]);
            }
        }

        fetchSituations();
    }, [category]);

    if (!situations) {
        return (
            <div style={{ width: '100%', maxWidth: '550px', margin: 'auto', textAlign: 'center' }}>
                <IonSpinner style={{width: 100, height: 100, marginTop: 100}} name="crescent"></IonSpinner>
            </div>
        );
    }

    return (
        <div style={{width: '100%', maxWidth: '550px', margin: 'auto'}}>
            <SituationCategorySelector 
                category={category} 
                selectCategory={(c: Situation['category'] | 'For you') => setCategory(c)} 
                />
            <Situations situations={situations} />
        </div>
    );
}

export default LibraryComponent;