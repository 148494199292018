import { IonButton, IonIcon } from "@ionic/react";
import { volumeMedium, pause } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import AudioManager from '../../AudioManager';

const AudioButton: React.FC<{ 
    optionNum: number, 
    url: string, 
    selectOption: () => void, 
    isSelected: boolean, 
    canClick: boolean,
}> = (
    { 
        optionNum, 
        url, 
        selectOption, 
        isSelected, 
        canClick, 
    }) => {

    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const handleAudioEnd = () => {
        setIsPlaying(false);
    };

    const toggleAudio = () => {
        AudioManager.toggleAudioPlayback(url, handleAudioEnd);
        setIsPlaying(prevState => !prevState);
    };

    useEffect(() => {
        return () => {
            AudioManager.stopCurrentAudio();
            setIsPlaying(false);
        };
    }, [url]);
    
    useEffect(() => {
        if (!isSelected && isPlaying) {
            setIsPlaying(false);
        }
    }, [isSelected, isPlaying]);
    // return (
    //     <IonButton
    //         className="ion-text-wrap"
    //         expand="block"
    //         onClick={() => {
    //             AudioManager.playAudio(url);
    //             if (canClick) {
    //                 selectOption();
    //             }
    //         }}
    //         color={isSelected ? 'medium' : 'light'}
    //         style={{ height: '100%' }}>
    //             {optionNum}
    //         <IonIcon src={volumeMedium} size="large"></IonIcon>
    //     </IonButton>
    // );
    return (
        <IonButton
            className="ion-text-wrap"
            expand="block"
            onClick={() => {
                toggleAudio();
                if (canClick) {
                    selectOption();
                }
            }}
            color={isSelected ? 'medium' : 'light'}
            style={{ height: '100%' }}>
                {optionNum}
            <IonIcon src={isPlaying ? pause : volumeMedium} size="large"></IonIcon>
        </IonButton>
    );
}

export default AudioButton;