import { useEffect, useState } from "react";
import Situations from "./Situations";
import { useAuth } from "../../../context/AuthContext";
import { Situation } from "../../../types/ai_pivot/situationTypes";
import { getLikedSituations } from "../../../service/situationService";
import { IonSpinner } from "@ionic/react";


const LikedSituations: React.FC = () => {
    const [situations, setSituations] = useState<Situation[] | null>(null);
    const { session } = useAuth();
    const [error, setError] = useState<string | null>(null);
    const currentUser = session?.user;

    useEffect(() => {
        const fetchSituations = async () => {
            if (currentUser) {
                const { data, error } = await getLikedSituations(currentUser.id);
                if (error) {
                    console.error('Error fetching situations: ', error.message);
                    setError(error.message);
                } else {
                    console.log(data);
                    setSituations(data);
                }
            }
        }

        fetchSituations();
    }, [currentUser]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!situations) {
        return (
            <div style={{ width: '100%', maxWidth: '550px', margin: 'auto', textAlign: 'center' }}>
                <IonSpinner style={{width: 100, height: 100, marginTop: 100}} name="crescent"></IonSpinner>
            </div>
        );
    }

    return (
        <>
            {situations && <Situations situations={situations} />}
        </>
    );
}

export default LikedSituations;