import React, { useState } from 'react';

const TextToSpeechComponent: React.FC = () => {
  const [voiceId, setVoiceId] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [audioUrl, setAudioUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const XI_API_KEY = '0e53a64fbff1b23e8537f45831b6561c';

  const fetchVoices = async () => {
    const url = "https://api.elevenlabs.io/v1/voices";
    try {
      const response = await fetch(url, {
        headers: {
          "Accept": "application/json",
          "xi-api-key": "0e53a64fbff1b23e8537f45831b6561c",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch voices');
      }
      const data = await response.json();
      console.log(data.voices); 
    } catch (error) {
      console.error("Error fetching voices:", error);
    }
  };

  const generateSpeech = async () => {
    if (!voiceId || !text) {
      console.log('Voice ID and text are required');
      return;
    }

    setLoading(true);

    const ttsUrl = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`;
    const payload = {
      text: text, 
      model_id: "eleven_multilingual_v2", 
      voice_settings: {
        stability: 0.5,
        similarity_boost: 0.8,
        style: 0.0,
        use_speaker_boost: true
      },
      
    };
  
    try {
      const response = await fetch(ttsUrl, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "xi-api-key": "0e53a64fbff1b23e8537f45831b6561c",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Response error:', errorText);
        throw new Error('Failed to generate speech');
      }
  
      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
      setAudioUrl(audioUrl);
    } catch (error) {
        alert(`Error generating speech: ${error}`);
        console.error("Error generating speech:", error);
      } finally {
        setLoading(false);
      }
  };

  return (
    <div>
      <button onClick={fetchVoices}>Fetch Voices</button>
      <input
        type="text"
        value={voiceId}
        onChange={(e) => setVoiceId(e.target.value)}
        placeholder="Voice ID"
      />
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Enter text to synthesize"
      />
      <button onClick={generateSpeech} disabled={loading}>
        {loading ? 'Generating Speech...' : 'Generate Speech'}
      </button>
      {audioUrl && (
        <audio controls src={audioUrl} style={{marginTop: '20px'}}>
          Your browser does not support the audio element.
        </audio>
      )}
    </div>
  );
};

export default TextToSpeechComponent;
