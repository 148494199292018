import React, { useState, useEffect } from 'react';
import {
    IonButton, IonLabel, IonIcon, IonModal, IonPage, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent
} from '@ionic/react';
import { flameOutline } from 'ionicons/icons';
import StreakModal from './StreakModal';
import { fetchUserStreak } from '../../../service/skillService';
import { StreakData } from '../../../types/ai_pivot/skillTypes';
import { useAuth } from '../../../context/AuthContext';

const StreakComponent: React.FC = () => {
    const [show, setShow] = useState<boolean>(false);
    const [streakData, setStreakData] = useState<StreakData | null>(null);
    const { user } = useAuth();

    useEffect(() => {
        if (user) {
            fetchUserStreak(user.id).then((data) => {
                console.log("Streak Data", data);
                setStreakData(data);
            });
        }
    }, [user?.id]);

    const handleOpenStreakModal = () => {
        setShow(true);
    }

    const handleCloseStreakModal = () => {
        setShow(false);
    };

    return (
        <>
            <IonButton onClick={handleOpenStreakModal} size='small' shape='round' fill='clear'>
                <IonIcon icon={flameOutline} />
                {streakData && (
                <IonLabel style={{ marginLeft: '5px', color: 'var(--calendar-today-day-color)', fontWeight: 'bold', fontSize: '18px' }}>
                    {streakData.current_streak} 
                </IonLabel>
            )}
            </IonButton>
            {/* <IonModal isOpen={show} canDismiss={true} onWillDismiss={handleCloseStreakModal}>
                <IonPage> */}
                    {/* <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="end">
                                <IonButton onClick={handleCloseStreakModal}>Close</IonButton>
                            </IonButtons>
                            <IonTitle>Streak</IonTitle>
                        </IonToolbar>
                    </IonHeader> */}
                    <IonContent fullscreen={true} color="light">
                        {streakData && (
                            <StreakModal
                                isOpen={show}
                                onClose={handleCloseStreakModal}
                                streakData={streakData}
                            />
                        )}
                    </IonContent>
                {/* </IonPage>
            </IonModal> */}
        </>
    );
}

export default StreakComponent;
