import {
  IonApp,
  IonNav,
  setupIonicReact
} from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { AuthProvider } from './context/AuthContext';
import AppRoutesHelper from './routes/AppRoutesHelper';

import './config.ts';

setupIonicReact({
  mode: 'ios',
});

// TODO: remove tab bar from sign in page

const App: React.FC = () => {
  console.log(process.env.NODE_ENV);

  return (
    <AuthProvider>
        <IonApp>
          <AppRoutesHelper />
        </IonApp>
    </AuthProvider>
  )
}

export default App;
