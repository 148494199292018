import { IonButton, IonText } from "@ionic/react";

import './PaginationActions.css';

const PaginationActions: React.FC<{
    currPage: number; 
    totalPages: number; 
    handlePrev: () => void; 
    handleNext: () => void
}> = ({currPage, totalPages, handlePrev, handleNext}) => {
    return (
        <div className='page-nav-container'>
            <IonButton style= {{'margin-bottom': 20}} className= "duo" disabled={currPage <= 1} onClick={handlePrev}>Prev</IonButton>
                <IonText>
                    <p>{currPage} / {totalPages}</p>
                </IonText>
            <IonButton style= {{'margin-bottom': 20}} className= "duo" disabled={currPage >= totalPages} onClick={handleNext}>Next</IonButton>
        </div>
    );
}

export default PaginationActions;