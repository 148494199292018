import React, { useState } from "react";
import { IonButton, IonInput, IonTextarea } from "@ionic/react";
import AnswerCheckPopup from "../quiz/AnswerCheckPopup";
import { ModuleProps } from "../shared/ModuleProps";
import '../shared/ModuleBottomBar.css';
import axios from 'axios';
import { LLMCheck } from "../../../../../../types/ai_pivot/contentTypes";
import ModuleAudioContent from "../shared/AudioContent";
import ModuleTextContent from "../shared/TextContent";
import ImageContent from "../shared/ImageContent";
import PassageContent from "../shared/PassageContent";
import './LLMCheck.css';
import AudioManager from '../../AudioManager';

interface LLMCheckProps extends ModuleProps {
    llmCheck: LLMCheck;
    preloadedImages?: { [key: string]: HTMLImageElement };
  }
  
  const LLMCheckModule: React.FC<LLMCheckProps> = ({ llmCheck, preloadedImages, moveToNextModule, updateProgressBar, hideButton }) => {
    const [userAnswer, setUserAnswer] = useState<string>("");
    const [isOpen, setIsOpen] = useState(false);
    const [feedback, setFeedback] = useState<string>("");
    const [isCorrect, setIsCorrect] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    
    // // ==> audio related stuff, can copy into other modules
    // const [audios, setAudios] = useState<HTMLAudioElement[]>([]);
    // const initAudio = (url: string) => {
    //     if (!audios.find((obj) => obj.src === url)) {
    //         const updatedAudios = [...audios];
    //         updatedAudios.push(new Audio(url));
    //         setAudios(updatedAudios);
    //     }
    // }

    // const playAudio = (url: string) => {
    //     audios.forEach((audio) => {
    //         audio.pause();
    //         audio.currentTime = 0;

    //         if (audio.src === url) {
    //             audio.play();
    //         }
    //     })
    // }
    // // <== end audio related stuff

    const checkAnswer = async () => {
      setIsLoading(true);
    //   console.log("1",llmCheck.question)
    //   console.log("2",userAnswer)
      try {
        let fullQuestion = llmCheck.question;

        if (llmCheck.text_content) {
          fullQuestion += `\n\n${llmCheck.text_content}`;
        }

        if (llmCheck.passage_content && llmCheck.passage_content.length > 0) {
          const passageText = llmCheck.passage_content.map((passage: any) => passage.text).join('\n');
          fullQuestion += `\n\n${passageText}`;
        }

        const postData = {
          question: fullQuestion,
          user_answer: userAnswer,
          llm_instructions: llmCheck.llm_instructions
        };
        const response = await axios.post(process.env.NODE_ENV === 'development' ? 'http://localhost:3000/check_answer' : 'https://finalbackend-nuob.onrender.com/check_answer', postData);
        const data = response.data;
        setFeedback(data.feedback);
        setIsCorrect(data.is_correct);
      } catch (error) {
        console.error("Error:", error);
        setFeedback("There was an error processing your answer.");
        setIsCorrect(false);
      } finally {
        setIsLoading(false);
        setIsOpen(true);
      }
    };
    console.log(llmCheck.text_content)
    return (
      <div>
        <div style={{ maxWidth: "550px", margin: "auto", marginBottom: "12vh", padding: "20px" }} className="ion-padding">
          <h2>{llmCheck.question}</h2>
          {/* <textarea
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            placeholder="Type your answer here"
            style={{ width: "100%", height: "150px" }}
          /> */}
          {
            {
                'audio': <ModuleAudioContent audio_url={llmCheck.audio_content!}/>,
                'text': <ModuleTextContent text_content={llmCheck.text_content!} />,
                'image': <ImageContent image_url={llmCheck.image_content!} preloadedImage={preloadedImages?.[llmCheck.image_content]}/>,
                'passage': <PassageContent passage={llmCheck.passage_content!} />
            }[llmCheck.content_type]
          }
          <IonTextarea 
            className="custom-input"
            value={userAnswer}
            onIonChange={(e) => setUserAnswer(e.detail.value!)}
            placeholder="Type Your Answer"
            autoGrow={true}
            rows= {4}
        />
        </div>
        {!hideButton && (
        <div className="button-bar">
          <div className="center-content">
            <div className="bottom-btn-wrapper">
              <IonButton onClick={checkAnswer} disabled={isLoading || !userAnswer} expand="block">
                {isLoading ? "Checking..." : "Check Answer"}
              </IonButton>
            </div>
          </div>
        </div>
        )}
        <AnswerCheckPopup
          correct={isCorrect}
          feedback={feedback}
          moveToNextModule={() => {
            setIsOpen(false);
            setUserAnswer("");
            setFeedback("");
            setIsCorrect(false);
            moveToNextModule();
          }}
          canContinue={true}
          isOpen={isOpen}
          closeSelf={() => setIsOpen(false)}
        />
      </div>
    );
  };
  
  export default LLMCheckModule;