import { IonModal, IonText } from "@ionic/react";
import ContinueButton from "../shared/ContinueButton";
import './AnswerCheckPopup.css';

import '../shared/ModuleBottomBar.css';
import { useRef } from "react";

const AnswerCheckPopup: React.FC<{
    correct: boolean,
    correctAns?: string,
    feedback?: string,
    moveToNextModule: () => void,
    canContinue: boolean,
    isOpen: boolean,
    closeSelf: () => void
}> = ({ correct, correctAns, feedback, moveToNextModule, canContinue, isOpen, closeSelf }) => {
    console.log('is open?: ', isOpen);
    return (
        <IonModal
            isOpen={isOpen}
            canDismiss={true}
            className="popup"
            initialBreakpoint={1}
            breakpoints={[0, 1]}
            showBackdrop={false}
            handle={false}
            backdropDismiss={false}
            backdropBreakpoint={1}
            animated={false}
        >
            <div className="center-content" style={{ backgroundColor: 'var(--ion-color-light)', padding: '0 15px 15px 15px' }}>
                <div className="bottom-btn-wrapper">
                    <IonText color={correct ? 'success' : 'danger'} >
                        <h2>
                            {correct ? 'Good job!' : 'Correct answer: '}
                        </h2>

                        {!correct && <div>{correctAns}</div>}
                        <div>{feedback}</div>
                    </IonText>
                    <br />

                    <ContinueButton
                        color={correct ? 'success' : 'danger'}
                        onContinue={moveToNextModule}
                        canContinue={canContinue}
                    />
                </div>
            </div>
        </IonModal>
    );
}

export default AnswerCheckPopup;