import { format, utcToZonedTime } from 'date-fns-tz';
import { DATE_INTERVAL_MIN, HOURS_MINUTES_FORMAT_AM_PM } from './constants';
import { roundToNearestMinutes, addMinutes } from 'date-fns';

export const getLocalTimeZone = (): string => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimeZone;
}

export const getHoursAndMinutesInLocalTimeZone = (date: Date, userTimeZone: string, formatter?: string): string => {
    const zonedTime = utcToZonedTime(date, userTimeZone);
    
    return format(zonedTime, formatter ? formatter : HOURS_MINUTES_FORMAT_AM_PM, { timeZone: userTimeZone });
}

export const getYearMonthDayInLocalTz = (date: Date, userTimeZone: string) => {
    const zonedTime = utcToZonedTime(date, userTimeZone);
    return format(zonedTime, "y-MM-dd", { timeZone: userTimeZone });
}

export const roundMeetingTimeToNearestAllowed = (date: Date) => {
    let roundedDate = roundToNearestMinutes(date, {
        nearestTo: DATE_INTERVAL_MIN, 
        roundingMethod: "floor"
    })

    if (roundedDate < date) {
        roundedDate = addMinutes(roundedDate, DATE_INTERVAL_MIN);
    }

    return roundedDate;
}
