import { useState } from "react";
import { IonPage, IonContent, IonInput, IonButton, IonHeader, IonToolbar, IonTitle, useIonToast } from '@ionic/react';
import { updatePassword } from "../../service/authService";
import { useHistory } from "react-router";

import '../../theme/layout.css';
import { displayErrorToUser } from "../../util/errorUtils";

const UpdatePassword: React.FC = () => {
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const history = useHistory();
    const [present] = useIonToast();
    
    const handleUpdatePassword = async () => {
        if (newPassword === repeatNewPassword) {
            const {data, error} = await updatePassword(newPassword);
            
            if (error) {
                displayErrorToUser({present, error});
              } else {
                setNewPassword('');
                setRepeatNewPassword('');
                present({message: 'Password update successful!', duration: 1500, position: 'middle'});
                history.push('/');
              }
        } else {
            present({header: 'Error: check input' , message: 'Passwords do not match!', duration: 2500, position: 'middle', buttons: ['My B']});
        }

    };
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Password update</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <div className="content-block ion-padding">
            <div className="form-container">
              <IonInput
                placeholder="New Password"
                type="password"
                onIonInput={(e) => setNewPassword(e.detail.value!)}
                clearInput
                value={newPassword}
              />
              <IonInput
                placeholder="Repeat new Password"
                type="password"
                onIonInput={(e) => setRepeatNewPassword(e.detail.value!)}
                clearInput
                value={repeatNewPassword}
              />
              <IonButton expand="block" onClick={handleUpdatePassword}>Update password</IonButton>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  };
  
  export default UpdatePassword;
  