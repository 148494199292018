import React, { useState, useEffect } from 'react';
import { IonPage, IonContent, IonInput, IonButton, IonHeader, IonToolbar, IonTitle, IonIcon, IonRow, IonCol, useIonToast } from '@ionic/react';
import './SignIn.css'; // Make sure to create a corresponding CSS file
import { resetPassword, signIn } from '../../service/authService'; 
import { useHistory, useLocation } from 'react-router-dom';
import { signInWithGoogle, signInWithFacebook, signInWithApple } from '../../service/authService';
import { logoGoogle, logoApple } from 'ionicons/icons';
import { MAIN_SCREEN } from '../../util/constants';
import { Device } from '@capacitor/device';
import '../../theme/layout.css';
import { displayErrorToUser } from '../../util/errorUtils';
import { Capacitor } from '@capacitor/core';

interface LocationState {
  from: {
    pathname: string
  }
}

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { state }: { state: LocationState } = useLocation();
  const [showAppleSignIn, setShowAppleSignIn] = useState(false);
  const history = useHistory();
  const [present] = useIonToast();
  
  useEffect(() => {
    checkAppleSignInAvailability();
  }, []);

  const checkAppleSignInAvailability = async () => {
    if (Capacitor.isNativePlatform()) {
      const info = await Device.getInfo();
      setShowAppleSignIn(info.platform === 'ios' && parseInt(info.osVersion.split('.')[0]) >= 13);
    }
  };

  const handleSignIn = async () => {
    const { user, error } = await signIn(email, password);
    if (error) {
      displayErrorToUser({present, error});
    } else {
      history.push(state?.from || `/${MAIN_SCREEN}`);
    }
  };

  const handleGoogleSignIn = async () => {
    const { data, error } = await signInWithGoogle(state?.from.pathname);
    if (error) {
      console.error('Google sign in error:', error.message);
    } else {
      console.log('Google sign in successful:', data);
    }
  };

  const handleAppleSignIn = async () => {
    const { data, error } = await signInWithApple(state?.from?.pathname);
    if (error) {
      displayErrorToUser({present, error, header: 'Apple Sign In Error'});
    } else {
      console.log('Apple sign in successful:', data);
    }
  };

  const handleFacebookSignIn = async () => {
    const { data, error } = await signInWithFacebook(state?.from.pathname);
    if (error) {
      console.error('Facebook sign in error:', error.message);
    } else {
      console.log('Facebook sign in successful:', data);
    }
  };

  const goToSignUp = () => {
    history.push('/signup_'); 
  };

  const handleForgotPassword = async () => {
    const { data, error} = await resetPassword(email);
    if (error) {
      displayErrorToUser({present, error, header: 'Password reset error'});
    } else {
      present({message: `Password reset link was sent to your email!`, duration: 2000, position: 'top'});
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sign In</IonTitle>
        </IonToolbar>
      </IonHeader>
      {/* <IonHeader mode='ios'>
        <IonToolbar>
          <IonTitle>Sign In</IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonContent fullscreen>
        <div className="content-block ion-padding">
          <div className="form-container">
            <IonInput
              placeholder="Email"
              type="email"
              onIonInput={(e) => setEmail(e.detail.value!)}
              clearInput
            />
            <IonInput
              placeholder="Password"
              type="password"
              onIonInput={(e) => setPassword(e.detail.value!)}
              clearInput
            />
            <IonButton expand="block" onClick={handleSignIn}>Sign In</IonButton>
            <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <IonButton fill="clear" onClick={handleGoogleSignIn}>
                Sign In with Google <IonIcon icon={logoGoogle} />
              </IonButton>
            </IonCol>
            {showAppleSignIn && (
                <IonCol size="auto">
                  <IonButton fill="clear" onClick={handleAppleSignIn}>
                    Sign In with Apple <IonIcon icon={logoApple} />
                  </IonButton>
                </IonCol>
              )}
            <IonCol size="auto">
              {/* <IonButton fill="clear" onClick={handleFacebookSignIn}>
                <IonIcon icon={logoFacebook} />
              </IonButton> */}
            </IonCol>
          </IonRow>
            <IonButton expand="block" fill="clear" onClick={handleForgotPassword}>Forgot Password?</IonButton>
            <IonButton expand="block" fill="clear" onClick={goToSignUp}>Not a registered user? Sign Up</IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignIn;

