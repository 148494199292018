import React from 'react';
import { IonText } from '@ionic/react';
import './ChatMessage.css';
import ReactMarkdown from 'react-markdown';
import { ContentItem } from "../../../../types/ai_pivot/situationTypes";

interface ChatMessageProps {
    content: string | ContentItem[] | { text: string; type: string };
    isUser: boolean;
  }

const ChatMessage: React.FC<ChatMessageProps> = ({ content, isUser }) => {
    const renderContent = () => {
      if (typeof content === 'string') {
        return <ReactMarkdown>{content}</ReactMarkdown>;
      } else if (Array.isArray(content)) {
        return content.map((item, index) => (
          <ReactMarkdown key={index}>{item.text}</ReactMarkdown>
        ));
      } else if (typeof content === 'object' && 'text' in content) {
        return <ReactMarkdown>{content.text}</ReactMarkdown>;
      }
      return null;
    };
  
    return (
      <div className={`chat-message ${isUser ? 'user' : 'assistant'}`}>
        <div className="message-bubble">
          <IonText>{renderContent()}</IonText>
        </div>
      </div>
    );
  };
  
  export default ChatMessage;