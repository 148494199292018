import { useEffect, useState } from "react";
import { Situation, SituationInstance } from "../../../../types/ai_pivot/situationTypes";
import { createSituationInstance, getLatestXSituationSummaries, getSituation } from "../../../../service/situationService";
import { useParams } from "react-router";
import {
    IonCard, IonCardHeader, IonCardTitle,
    IonCardSubtitle, IonCardContent, IonButton,
    useIonToast, IonText, IonAlert, IonModal, IonHeader, IonToolbar,
    IonTitle, IonContent, IonList, IonItem, IonLabel, IonFooter, IonIcon
} from "@ionic/react";
import axios from 'axios';
import DailyIframe, { DailyCall, DailyEventObjectTrack } from "@daily-co/daily-js";
import { useAuth } from "../../../../context/AuthContext";
import { supabase } from "../../../../service/authService";
import PlanUpgradeComponent from "../../payment/PlanUpgradeComponent";
import Picture from "../../home/Picture";
import LikeDislikeComponent from "../../home/LikeDislikeComponent";
import Loading from "../../../generic/Loading";
import Feedback from "../../home/Feedback";
import { hourglass, play, stop } from "ionicons/icons";
import SitFeedback from "../SitFeedback";
import { da } from "date-fns/locale";
import { FEEDBACK_SUMMARY_LOADING } from "../../../../util/constants";

interface InstructionDetails {
    title: string;
    steps: string[];
    // imageUrl: string;
}

const Scenario: React.FC<{
    title: string,
    description: string,
    prompt: string,
    feedback_prompt: string,
    image_url: string,
    audio_url: string,
    audio_volume: number,
    voice_id: string,
    author_id: string,
    duration: number,
    type: string,
    author_username: string,
    category: 'Other',
    isPublic: false,
    version: number
}> = ({
    title, description, prompt, feedback_prompt,
    image_url, audio_url, audio_volume, voice_id,
    author_id, duration, type, author_username,
    category, isPublic, version
}) => {
        const [situation, setSituation] = useState<Situation>();
        const [inProgress, setInProgress] = useState(false);
        const [backgroundAudio, setBackgroundAudio] = useState<HTMLAudioElement>();
        const [present] = useIonToast();
        const params = useParams<{ id: string }>();
        const [isLoading, setIsLoading] = useState(false);
        const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);
        const { user, userProfile } = useAuth();
        const [callObject, setCallObject] = useState<DailyCall | null>(null);
        const [situationInstance, setSituationInstance] = useState<SituationInstance>();
        const [showPurchaseOptions, setShowPurchaseOptions] = useState<boolean>(false);
        const [showModal, setShowModal] = useState(false);
        const [modalContent, setModalContent] = useState('');

        function getBrowserAndOS() {
            const userAgent = navigator.userAgent;
            console.log(userAgent)
            const isMac = userAgent.includes('Macintosh');
            const isiPhone = userAgent.includes('iPhone');
            const isWindows = userAgent.includes('Windows');
            const isAndroid = userAgent.includes('Android');
            const isChrome = userAgent.includes('Chrome');
            const isSafari = userAgent.includes('Safari') && !userAgent.includes('Chrome');

            if (isMac) {
                return isSafari ? 'Mac - Safari' : 'Mac - Chrome';
            } else if (isiPhone) {
                return isSafari ? 'iPhone - Safari' : 'iPhone - Chrome';
            } else if (isWindows) {
                return 'Windows - Chrome';
            } else if (isAndroid) {
                return 'Android - Chrome';
            } else {
                return 'Other';
            }
        }


        useEffect(() => {
            if (situationInstance) {
                console.log('situation instance is present: ', situationInstance);
                const channel = supabase
                    .channel('schema-db-changes')
                    .on(
                        'postgres_changes',
                        {
                            event: 'UPDATE',
                            schema: 'public',
                            table: 'situation_instance',
                            filter: `id=eq.${situationInstance.id}`,
                        },
                        (payload) => {
                            let si = payload.new as SituationInstance;
                            if (si.feedback_as_text && isFeedbackLoading) {
                                setIsFeedbackLoading(false);
                            }
                            setSituationInstance(payload.new as SituationInstance)
                        }
                    )
                    .subscribe();

                return () => {
                    channel.unsubscribe();
                }
            }
        }, [situationInstance?.id])

        useEffect(() => {
            // const fetchSituations = async () => {
            //     const { data, error } = await getSituation(params.id);
            //     if (error) {
            //         console.error('error fetching situation: ', JSON.stringify(error));
            //     } else if (data) {
            //         setSituation(data);
            //         if (data.audio_url && data.audio_volume) {
            //             const audio = new Audio(data.audio_url);
            //             audio.loop = true;
            //             console.log(data.audio_volume);
            //             audio.volume = data.audio_volume; //data.audio_volume;
            //             setBackgroundAudio(audio);
            //         }
            //     }
            // }

            // fetchSituations();
            return () => {
                console.log('Stopping conversation');
                handleStop();
            }
        }, []);

        useEffect(() => {
            const call = DailyIframe.createCallObject({
                audioSource: true,
                videoSource: false,
                subscribeToTracksAutomatically: true
                // subscribeToTracksAutomatically: true
            });
            setCallObject(call);

            call.on('participant-joined', participant => {
                console.log('[participant-joined]', participant);
            });
            call.on('participant-left', participant => {
                console.log('[participant-left]', participant);
            });
            call.on('track-started', playAudio);
            call.on('track-stopped', (e) => console.log('[track-stopped]', e));

            return () => {
                call.leave();
                call.destroy();
            };
        }, []);

        const playAudio = (event: DailyEventObjectTrack) => {
            console.log('Track started event:', event);


            if (!event.participant || !event.track || event.participant.local) {
                console.log('Ignoring track: either participant or track is null, or it\'s the local participant\'s track.');
                return;
            }

            if (event.track.kind !== 'audio') {
                console.log('Ignoring non-audio track');
                return;
            }

            let audioEl = document.querySelector(`audio[data-participant-id='${event.participant.session_id}']`) as HTMLAudioElement;
            if (!audioEl) {
                console.log('Creating new audio element for participant:', event.participant.session_id);
                audioEl = document.createElement('audio');
                audioEl.setAttribute('data-participant-id', event.participant.session_id);
                audioEl.autoplay = true;
                document.body.appendChild(audioEl);
            }

            console.log('Attaching the track and playing it');
            audioEl.srcObject = new MediaStream([event.track]);
            audioEl.play().catch((error) => {
                console.error('Error playing the audio:', error);
                handlePermissionIssue();
            });
        };


        const showPrompt = () => {
            present({
                header: "Let's get started!",
                message: 'AI might lag and be unresponsive at times - we do everything we can to ensure you have the best possible experience',
                position: 'middle',
                buttons: [
                    {
                        text: 'Okay',
                        role: 'info',
                        handler: () => {
                            handleStart();
                        },
                    }
                ]
            });
        }

        const getAdditionalContext = async () => {

            if (situation && user) {
                const { data, error } = await getLatestXSituationSummaries(situation, user.id);
                if (error) {
                    console.error('Error loading last therapy meeting summaries');
                } else if (data) {
                    let res = "";
                    // first loop to check if there is feedback recorded and initialize additional context
                    for (let i = 0; i < data.length; i++) {
                        let currFeedback = data[i].feedback_as_text;
                        if (currFeedback && currFeedback.length > 0 && res.length === 0) {
                            res = `
Additional instructions: 
- Review previous session summary notes below. If there were planned action items, start by asking the user if he/she took action noted during previous sessions.

Context:
Previous session summary notes: `
                        }
                    }

                    // second loop to add previous session summary notes
                    for (let i = 0; i < data.length; i++) {
                        let currFeedback = data[i].feedback_as_text;
                        if (currFeedback && currFeedback.length > 0) {
                            res += `

''' ${currFeedback}' '''`
                        }
                    }

                    return res;
                }
            }

            return "";
        }


        const handleStart = async () => {
            try {
                const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });
                switch (permissionStatus.state) {
                    case 'granted':
                        startCall();
                        break;
                    case 'prompt':
                        try {
                            await navigator.mediaDevices.getUserMedia({ audio: true });
                            startCall();
                        } catch (error) {
                            console.error('Microphone access denied by user:', error);
                            handlePermissionIssue();
                        }
                        break;
                    case 'denied':
                        handlePermissionIssue();
                        break;
                }
            } catch (error) {
                console.error('Error checking microphone permissions:', error);
                handlePermissionIssue();
            }
        };

        const startCall = async () => {
            let newSituation: Situation | undefined = undefined;
            if (!situation) {
                const { error, data } = await supabase
                    .from('situation')
                    .insert({title, description, prompt, feedback_prompt,
                        image_url, audio_url, audio_volume, voice_id,
                        author_id, duration, type, author_username,
                        category, public: isPublic, version})
                    .select('*')
                    .single();

                if (error) {
                    console.error('sheesh! ', JSON.stringify(error));
                } else if (data) {
                    setSituation(data);
                    newSituation = data;
                }
            } else {
                newSituation = situation;
            }

            if (newSituation && callObject && user && userProfile && newSituation.id !== undefined) {
                setIsLoading(true);

                const { data, error } = await createSituationInstance(newSituation.id);
                if (error || !data) {
                    if (error?.message.includes('You ran out of available sessions.')) {
                        setShowPurchaseOptions(true);
                    } else {
                        console.error("Failed to initialize situation instance:", error);
                        alert('Error encountered, please try again later');
                    }
                    setIsLoading(false);
                    return;
                }
                // console.log(data);
                setSituationInstance(data);

                let additionalContext = await getAdditionalContext();
                console.log('additinal context: ', additionalContext);
                let completePrompt = newSituation.prompt + additionalContext;
                completePrompt = completePrompt.replace('{name}', userProfile.name);
                console.log('complete prompt: ', completePrompt);

                const postData = {
                    instance_id: data.id,
                    prompt: completePrompt,
                    voice_id: newSituation.voice_id,
                    feedback_prompt: newSituation.feedback_prompt
                };
                try {
                    console.log(postData);
                    const response = await axios.post('https://finalbackend-nuob.onrender.com/start', postData);
                    if (response.data && response.data.url) {
                        console.log('Joining Daily room:', response.data.url);
                        await callObject.join({ url: response.data.url });
                        if (backgroundAudio)
                            backgroundAudio.play();
                        setInProgress(true);

                        // update ballance
                        // const { error } = await supabase
                        //         .from('users')
                        //         .update({balance: userProfile.balance - 1})
                        //         .eq('userid', userProfile.userid);
                        if (error) {
                            console.error('error decreasing user balance: ', JSON.stringify(error));
                        }
                    }
                } catch (error) {
                    console.error('Error starting the call:', error);
                    alert('Error encountered while starting the call. Please reload the page and retry.');
                }
                setIsLoading(false);
            }
        };

        const handlePermissionIssue = () => {
            console.log("test")
            const browserAndOS = getBrowserAndOS();
            const instructions = getInstructionByBrowser(browserAndOS);
            setModalContent(`Microphone access is required to start the session. ${instructions}`);
            setShowModal(true);

            handleStop(); //Verify on future 
            setIsFeedbackLoading(false); //Verify on future 
            setIsLoading(false); //Verify on future 
        };

        const getInstructionByBrowser = (browserAndOS: string): InstructionDetails => {
            const instructionsMap: { [key: string]: InstructionDetails } = {
                'Mac - Safari': {
                    title: 'Enable Microphone Access in Safari on Mac',
                    steps: [
                        'Open Safari.',
                        'Click on Safari in the menu bar, then select Preferences.',
                        'Navigate to the Websites tab.',
                        'Scroll to the Microphone section.',
                        'Next to the website you want to allow, change the setting to Allow.'
                    ],
                    // imageUrl: '/path_to_safari_mac_image.png'
                },
                'Mac - Chrome': {
                    title: 'Enable Microphone Access in Chrome on Mac',
                    steps: [
                        'Open Chrome.',
                        'Click on Chrome in the menu bar, then select Preferences.',
                        'Scroll to the Privacy and Security section, and click on Site Settings.',
                        'Find and click on Microphone.',
                        'Make sure the setting is set to Ask before accessing or Allow for specific sites.'
                    ],
                    // imageUrl: '/path_to_chrome_mac_image.png'
                },
                'iPhone - Safari': {
                    title: 'Enable Microphone Access in Safari on iPhone',
                    steps: [
                        'Open the Settings app.',
                        'Scroll down and tap Safari.',
                        'Scroll down to the Privacy & Security section.',
                        'Tap on Microphone and ensure it’s set to Allow.'
                    ],
                    // imageUrl: '/path_to_safari_iphone_image.png'
                },
                'iPhone - Chrome': {
                    title: 'Enable Microphone Access in Chrome on iPhone',
                    steps: [
                        'Open the Chrome app.',
                        'Tap on the menu at the bottom-right (three dots).',
                        'Tap on Settings.',
                        'Scroll down to Site Settings.',
                        'Tap on Microphone and change it to Ask first or Allow.'
                    ],
                    // imageUrl: '/path_to_chrome_iphone_image.png'
                },
                'Windows - Chrome': {
                    title: 'Enable Microphone Access in Chrome on Windows',
                    steps: [
                        'Open Chrome.',
                        'Click on the three dots in the upper right corner to open the menu.',
                        'Click on Settings.',
                        'Scroll down and click on Privacy and security.',
                        'Click on Site settings.',
                        'Under Permissions, click on Microphone.',
                        'Ensure that Ask before accessing is on, or allow specific sites to use the microphone.'
                    ],
                    // imageUrl: '/path_to_chrome_windows_image.png'
                },
                'Android - Chrome': {
                    title: 'Enable Microphone Access in Chrome on Android',
                    steps: [
                        'Open the Chrome app.',
                        'Tap on the menu icon (three dots) at the top right.',
                        'Tap on Settings.',
                        'Scroll down to Site settings.',
                        'Tap on Microphone and ensure access is allowed.'
                    ],
                    // imageUrl: '/path_to_chrome_android_image.png'
                },
                'Other': {
                    title: 'Enable Microphone Access',
                    steps: [
                        'Open your browser.',
                        'Navigate to the browser’s settings or preferences.',
                        'Find the privacy or security section.',
                        'Locate microphone settings and enable it for the required website.'
                    ],
                    // imageUrl: '/generic_browser_settings_image.png'
                }
            };

            return instructionsMap[browserAndOS] || instructionsMap['Other'];
        };

        const handleStop = () => {
            if (callObject) {
                callObject.leave();
            }
            setInProgress(false);
            setIsFeedbackLoading(true);
            backgroundAudio?.pause();
            console.log('Call ended');
        };

        const PermissionModal = () => {
            const instructions = getInstructionByBrowser(getBrowserAndOS());
            const onClose = () => setShowModal(false);
            return (
                <IonModal isOpen={showModal} onDidDismiss={onClose}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle style={{ textAlign: 'center' }}>Enable Microphone Access</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <div style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px' }}>
                            {instructions.title}
                        </div>
                        <IonList>
                            {instructions.steps.map((step, index) => (
                                <IonItem key={index} lines="none">
                                    <IonLabel>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div style={{ fontWeight: 'bold', marginRight: '8px' }}>{index + 1}.</div>
                                            <div>{step}</div>
                                        </div>
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                        {/* {instructions.imageUrl && (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={instructions.imageUrl} alt="Visual Guide" style={{ width: '100%', maxWidth: '500px', padding: '10px' }} />
                                </div>
                            )} */}
                        <IonButton expand="block" onClick={onClose} style={{ marginTop: '20px' }}>Close</IonButton>
                    </IonContent>
                </IonModal>
            );
        };

        return (
            <div>
                <PermissionModal />
                <PlanUpgradeComponent show={showPurchaseOptions} close={() => setShowPurchaseOptions(false)} />
                <IonCard mode="ios">
                    <IonCardHeader style={{ paddingTop: '35px' }}>
                        <IonCardSubtitle>{description}</IonCardSubtitle>
                        <br />
                        <IonText>
                            <div>By @{author_username} • v{version}</div>
                        </IonText>
                        {/* <br /> */}
                        <IonCardTitle>{title}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div style={{ width: '100%', height: '100%', backgroundColor: 'danger' }}
                            onClick={() => {inProgress ? handleStop() : handleStart()}}
                        >
                            <IonIcon style={
                                {
                                    position: 'absolute',
                                    zIndex: 2,
                                    left: 0,
                                    right: 0,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    top: 0,
                                    bottom: 0,
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    color: 'white',
                                    width: '50px',
                                    height: '50px'
                                }} icon={isLoading ? hourglass : inProgress ? stop : play} size="large" />
                            <Picture image_url={image_url} />
                        </div>
                    </IonCardContent>
                    <IonFooter className="button-bar" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        {user && situation && situation?.id !== undefined && situation.version !== undefined ? (
                            <LikeDislikeComponent
                                situationId={situation.id}
                                userId={user.id}
                                situation_version={situation.version}
                                session_count={situation.sessions} />
                        ) : <div style={{height: 30}}></div>}
                    </IonFooter>
                </IonCard>
                {
                    situationInstance 
                    &&
                    <SitFeedback feedback={situationInstance.feedback_as_text ? situationInstance.feedback_as_text : FEEDBACK_SUMMARY_LOADING} />
                }
            </div>
        );
    }

export default Scenario;