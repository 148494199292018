import { useEffect, useState } from "react";
import { Situation, SituationInstance } from "../../../types/ai_pivot/situationTypes";
import SituationOverviewCard from "./SituationOverviewCard";
import React from "react";
import DailyIframe, { DailyCall } from "@daily-co/daily-js";
import { supabase } from "../../../service/authService";
import { useAuth } from "../../../context/AuthContext";
import { FEEDBACK_SUMMARY_LOADING } from "../../../util/constants";
import { IonIcon, IonItem, IonLabel, IonList, IonPopover } from "@ionic/react";
import { createOutline, ellipsisHorizontal } from "ionicons/icons";
import CreateEditScenarioForm from "../home/CreateEditScenarioForm";

const Situations: React.FC<{ situations?: Situation[], edit?: boolean }> = ({ situations, edit }) => {
    const [currActiveSitId, setCurrActiveSitId] = useState<Situation['id'] | null>(null);
    const [callObject, setCallObject] = useState<DailyCall | null>(null); // can only have one DailyIframe instance at a time
    const [situationFeedback, setSituationFeedback] = useState<{ [key: number]: string | null }>({});
    const { session } = useAuth();
    const [showEdit, setShowEdit] = useState(false);
    const [scenarioToEdit, setScenarioToEdit] = useState<Situation>();

    useEffect(() => {
        setCurrActiveSitId(null);
    }, [situations]);

    const updateCurrentlyActive = async (sitId: Situation['id'] | null) => {
        console.log('situation id: ', sitId);
        await destroyCallObject(); // destroy any previous info
        if (sitId) {
            const call = DailyIframe.createCallObject({
                audioSource: true,
                videoSource: false,
                subscribeToTracksAutomatically: true
                // subscribeToTracksAutomatically: true
            });

            setCallObject(call);
        }
        setCurrActiveSitId(sitId);
    }

    const destroyCallObject = async () => {
        try {
            await callObject?.leave();
            await callObject?.destroy();
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (situations && session?.user?.id) {
            const channel = supabase
                .channel('schema-db-changes')
                .on(
                    'postgres_changes',
                    {
                        event: '*',
                        schema: 'public',
                        table: 'situation_instance',
                        filter: `user_id=eq.${session.user.id}`
                    },
                    (payload) => {
                        let si = payload.new as SituationInstance;
                        if (payload.eventType === "DELETE") {
                            return;
                        }

                        console.log('Payload is this: ', payload);
                        console.log('Situation feedback is this: ', situationFeedback);
                        const updatedFeedback = { ...situationFeedback };
                        if (payload.eventType === "INSERT") {
                            updatedFeedback[si.situation_id] = FEEDBACK_SUMMARY_LOADING;
                        } else if (payload.eventType === "UPDATE" && si.feedback_as_text) {
                            updatedFeedback[si.situation_id] = si.feedback_as_text;
                        }

                        setSituationFeedback(updatedFeedback);
                    }
                )
                .subscribe();

            return () => {
                channel.unsubscribe();
            }
        }
    }, [situations, session?.user?.id, situationFeedback]);

    return (
        <div style={{ width: '100%', maxWidth: '550px', margin: 'auto' }}>
            {situations?.map((res) => (
                <div key={res.id} style={{ position: 'relative' }}>
                    <SituationOverviewCard
                        situation={res}
                        isActive={res.id === currActiveSitId}
                        updateCurrentlyActive={updateCurrentlyActive}
                        callObject={callObject}
                        feedback={situationFeedback[res.id]}
                    />
                    {
                        edit
                        &&
                        <>
                            <IonIcon
                                id={`open-popover-${res.id}`}
                                icon={ellipsisHorizontal}
                                style={{
                                    position: 'absolute',
                                    zIndex: 2,
                                    top: 0,
                                    right: 25,
                                    backgroundColor: 'var(--background-btn-gray)',
                                    borderRadius: '50%',
                                    padding: 5,
                                    color: 'var(--text-btn-dark-gray)',
                                }}
                                slot="icon-only"
                            ></IonIcon>
                            <IonPopover trigger={`open-popover-${res.id}`} id={`popover-${res.id}`}>
                                <IonList class="ion-padding">
                                    <IonItem onClick={() => {
                                        setShowEdit(true);
                                        setScenarioToEdit(res);
                                        (document.getElementById(`popover-${res.id}`) as HTMLIonPopoverElement)?.dismiss();
                                    }
                                    }>
                                        <IonIcon icon={createOutline} slot="start" aria-hidden="true"></IonIcon>
                                        <IonLabel>Edit</IonLabel>
                                    </IonItem>
                                </IonList>
                            </IonPopover>
                        </>
                    }
                </div>
            ))}
            {edit && scenarioToEdit &&
                <CreateEditScenarioForm
                    initialData={scenarioToEdit}
                    show={showEdit}
                    dismiss={() => {
                        setShowEdit(false)
                        setScenarioToEdit(undefined)
                    }} />
            }
        </div>
        //     currPage={currPage}
        //     totalPages={totalPages}
        //     handlePrev={handlePrev}
        //     handleNext={handleNext}
        // />
    );
}

export default Situations;