import './Picture.css';

const Picture: React.FC<{image_url: string}> = ({image_url}) => {
    return (
        <div className="pic-container">
            <img className="pic"
                        alt="image"
                        src={image_url} />
        </div>
    );
}

export default Picture;