import React from 'react';
import './ImageButton.css';

interface ImageButtonProps {
    canClick: boolean;
    imageUrl: string;
    preloadedImage?: HTMLImageElement;
    selectOption: () => void;
    isSelected: boolean;
    index: number;
}

const ImageButton: React.FC<ImageButtonProps> = ({ index, canClick, imageUrl, preloadedImage, selectOption, isSelected }) => {
    return (
        // <div onClick={canClick ? selectOption : undefined} style={{ border: isSelected ? '2px solid white' : 'none', cursor: canClick ? 'pointer' : 'default' }}>
        //     <img src={imageUrl} alt="Option" style={{ width: '100%', height: 'auto' }} />
        // </div>
        <div 
            className={`image-button ${isSelected ? 'selected' : ''} ${canClick ? 'clickable' : ''}`}
            onClick={canClick ? selectOption : undefined}
        >   
            <img src={preloadedImage ? preloadedImage.src : imageUrl} alt="Option" />
            <div className={'image-number'}>
                {index}
            </div>
        </div>
    );
}

export default ImageButton;

