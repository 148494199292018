import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonNav } from "@ionic/react";
import { homeOutline, personOutline, addCircleOutline, shuffleOutline, diceOutline, chatbubblesOutline, libraryOutline, flame  } from "ionicons/icons";
import Routes from "./Routes";
import { useState } from "react";
import { publish } from "../util/events";
import CreateEditScenarioForm from "../components/ai_pivot/home/CreateEditScenarioForm";

const homeRef = '/';
const profileRef = '/profile';
const randomRef = '/random';
const feedbackRef = '/feedback';
const libraryRef = '/library';

const AppTabs: React.FC = () => {
    const [currTab, setCurrTab] = useState<string>(window.location.pathname);
    // const [showCreate, setShowCreate] = useState(false);

    const handleTabClick = (pageUri: string) => {
        publish('scrollTop', 'do it!');
        setCurrTab(pageUri);
    }

    return (
        <>
            {/* <CreateEditScenarioForm
                show={showCreate}
                dismiss={() => {
                    setShowCreate(false)
                }} /> */}
            <IonTabs>
                <IonRouterOutlet>
                    <Routes />
                </IonRouterOutlet>
                <IonTabBar slot="bottom" mode="md">
                    <IonTabButton tab="Home" href={homeRef} selected={currTab === homeRef} onClick={() => handleTabClick(homeRef)}>
                        <IonIcon aria-hidden="true" icon={homeOutline} />
                    </IonTabButton>
                    {/* <IonTabButton tab="Random" style={{ fontSize: 50 }} href={randomRef} selected={currTab === randomRef} onClick={() => handleTabClick(randomRef)}>
                        <IonIcon aria-hidden="true" icon={diceOutline} />
                    </IonTabButton> */}
                    {/* <IonTabButton tab="Create" onClick={() => {
                        console.log('here');
                        setShowCreate(true)
                    }}>
                        <IonIcon aria-hidden="true" icon={addCircleOutline} style={{ fontSize: 35 }} />
                    </IonTabButton> */}
                    <IonTabButton tab="Library" href={libraryRef} selected={currTab === libraryRef} onClick={() => handleTabClick(libraryRef)}>
                        <IonIcon aria-hidden="true" icon={libraryOutline} />
                    </IonTabButton>
                    <IonTabButton tab="Profile" href={profileRef} selected={currTab === profileRef} onClick={() => handleTabClick(profileRef)}>
                        <IonIcon aria-hidden="true" icon={personOutline} />
                    </IonTabButton>
                    <IonTabButton tab="Feedback" href={feedbackRef} selected={currTab === feedbackRef} onClick={() => handleTabClick(feedbackRef)}>
                        <IonIcon aria-hidden="true" icon={chatbubblesOutline} />
                    </IonTabButton>
                    {/* <IonTabButton tab="therapy" href="/therapy/4">
                    <IonIcon aria-hidden="true" icon={peopleOutline} />
                    <IonLabel>Therapy</IonLabel>
                </IonTabButton> */}
                    {/* <IonTabButton tab="booking" href="/booking">
                    <IonIcon aria-hidden="true" icon={calendarOutline} />
                    <IonLabel>Schedule</IonLabel>
                </IonTabButton>
                <IonTabButton tab="profile" href="/profile">
                    <IonIcon aria-hidden="true" icon={personCircleOutline} />
                    <IonLabel>Profile</IonLabel>
                </IonTabButton>
                <IonTabButton tab="live" href="/live">
                    <IonIcon aria-hidden="true" icon={flameOutline} />
                    <IonLabel>Live</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Video" href="/video">
                    <IonIcon aria-hidden="true" icon={videocamOutline} />
                    <IonLabel>Video</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Voice" href="/voice">
                    <IonIcon aria-hidden="true" icon={videocamOutline} />
                    <IonLabel>Voice</IonLabel>
                </IonTabButton> */}
                </IonTabBar>
            </IonTabs>
        </>
    );
}

export default AppTabs;