import ProtectedRoute from "../components/ProtectedRoute";
import { Route, Redirect } from "react-router";

import UnauthRoute from "../components/UnauthRoute";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import UpdatePassword from "../pages/auth/UpdatePassword";
import TextToSpeechComponent from "../pages/text_to_speech";
import LibraryPage from "../pages/ai_pivot/LibraryPage";
import SituationComponent from "../components/ai_pivot/home/SituationComponent";
import Situation from "../pages/ai_pivot/Situation";
import { MAIN_SCREEN } from "../util/constants";
import History from "../pages/ai_pivot/History";
import CharacterCustomizationPage from "../pages/ai_pivot/CharacterCustomizationPage";
import AIfriendpage from '../pages/ai_pivot/AIfriendpage';
import QuickStartOptions from '../pages/ai_pivot/QuickStartOptions';
import BasicInfo from '../pages/ai_pivot/BasicInfo';
import AvatarCustomization from '../pages/ai_pivot/AvatarCustomization';
import CreateEditScenarioForm from "../components/ai_pivot/home/CreateEditScenarioForm";
import UserSituations from "../components/ai_pivot/situation/UserSituations";
import RecordingsList from "../components/ai_pivot/situation/RecordingsList";
import Profile from "../pages/ai_pivot/profile/Profile";
import { IonNav } from "@ionic/react";
import RandomScenarioPage from "../pages/ai_pivot/RandomScenarioPage";
import VoiceGenerator from "../components/ai_pivot/home/GenerateVoice";
import PublicFeedback from "../components/ai_pivot/public_feedback/PublicFeedback";
import FeedbackListComponent from "../components/ai_pivot/profile/FeedbackListComponent";
import CreateScenarioPage from "../pages/ai_pivot/CreateScenarioPage";
import HomePage from "../pages/ai_pivot/Home";
import Onboarding from "../pages/auth/Onboarding";
import DeleteAccount from '../pages/auth/DeleteAccount';

const Routes: React.FC = () => {
    return (
        <>
            <UnauthRoute exact path="/signin_" component={SignIn}/>
            <UnauthRoute exact path="/signup_" component={SignUp}/>
            <UnauthRoute exact path="/onboarding" component={Onboarding}/>
            <ProtectedRoute exact path="/update-password" component={UpdatePassword}/>
            <ProtectedRoute exact path="/library" component={LibraryPage} />
            <ProtectedRoute exact path="/" component={HomePage} />
            <ProtectedRoute exact path="/scenario/:id" component={Situation}/>
            <ProtectedRoute exact path="/therapy/:id" component={Situation}/>
            <ProtectedRoute exact path="/profile" component={Profile}/>
            <ProtectedRoute exact path="/random" component={RandomScenarioPage} />
            {/* <ProtectedRoute exact path="/profile" component={Profile}/> */}
            {/* <ProtectedRoute exact path="/phone-verification" component={SignInWithOtp}/>
            <PhoneProtectedRoute exact path="/gender-selection" component={GenderSelectionPage}/> */}
            {/* <PhoneProtectedRoute exact path="/chat" component={ChatComponent}/> */}
            {/* <PhoneProtectedRoute exact path="/video" component={VideoChat}/>
            <PhoneProtectedRoute exact path="/profile" component={ProfilePage}/>
            <PhoneProtectedRoute exact path="/feedback-view" component={FeedbackViewPage}/>
            <PhoneProtectedRoute exact path="/search" component={SearchPage}/>

            <PhoneProtectedRoute exact path="/booking" component={ListBookingsPage}/>
            <PhoneProtectedRoute exact path="/booking/new/:userid" component={NewBookingPage}/>
            <PhoneProtectedRoute exact path="/live" component={LiveVideoRequestsPage}/>

            <PhoneProtectedRoute exact path="/booking/:bookingId" component={ManageBookingPage}/>

            <PhoneProtectedRoute exact path="/wallet" component={WalletPage}/>
            <PhoneProtectedRoute exact path="/transaction/:transactionId" component={TransactionPage}/> */}

            <ProtectedRoute exact path="/voice" component={TextToSpeechComponent}/>   
            <ProtectedRoute exact path="/customize" component={CharacterCustomizationPage}/>
            <ProtectedRoute exact path="/secret" component={AIfriendpage} />
            <ProtectedRoute exact path="/quick-start" component={QuickStartOptions} />
            <ProtectedRoute exact path="/custom-creation" component={BasicInfo} />
            <ProtectedRoute exact path="/avatar" component={AvatarCustomization} />
            <ProtectedRoute exact path="/create-situation" component={CreateEditScenarioForm} />
            <ProtectedRoute exact path="/edit-situation/:id" component={CreateEditScenarioForm} />
            <ProtectedRoute exact path="/usersit" component={UserSituations} />
            <ProtectedRoute exact path="/voicegen" component={VoiceGenerator} />
            <ProtectedRoute exact path="/record" component={RecordingsList} />
            <ProtectedRoute exact path="/feedback" component={PublicFeedback} />
            <ProtectedRoute exact path="/feedback/:id" component={FeedbackListComponent} />
            <ProtectedRoute exact path="/create" component={CreateScenarioPage} />
            <ProtectedRoute exact path="/delete-account" component={DeleteAccount} />
            {/* 
                <Route exact path="/">
                    <Redirect to={`/${MAIN_SCREEN}`} />
                </Route> */
            }
      </>
    )
}

export default Routes;