import { IonButton } from "@ionic/react";
import "react-circular-progressbar/dist/styles.css";
import './LessonButton.css';
import starr from './starW.svg'
import award from './award.svg'
import checkmark from './checkmark2.svg'
import barbell from './dumbell.svg';
import story from './book.svg';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import { Lesson } from "../../../../types/ai_pivot/skillTypes";
const LessonButton: React.FC<{ 
    type: Lesson["lesson_type"], isCompleted: boolean, isLocked: boolean, id: string; index: number; total: number; progress: number; showProgress: boolean;
}> = ({ type, isCompleted, isLocked, id, index, total, progress, showProgress  }) => {
    const cycleLength = 8;
    const cycleIndex = index % cycleLength;

    let identationLevel;

    if (cycleIndex <= 2) {
        identationLevel = cycleIndex;
    } else if (cycleIndex <= 4) {
        identationLevel = 4 - cycleIndex;
    } else if (cycleIndex <= 6) {
        identationLevel = 4 - cycleIndex
    } else {
        identationLevel = cycleIndex - 8;
    }

    const rightPos = identationLevel * 45;
    const isFirst = index === 0;
    const isLast = index === total - 1;

    const getIcon = () => {
        if (isLast && !isCompleted) {
            return <img src={award} alt="Trophy" className="lesson-button-icon" />;
        }

        if (type === "scenario") {
            return <img src={barbell} alt="Practice" className="lesson-button-icon" />;
        } else if (type === "story") {
            return <img src={story} alt="Story" className="lesson-button-icon" />;
        } else {
            return isCompleted ? (
                <img src={checkmark} alt="Completed" className="lesson-button-icon" />
            ) : (
                <img src={starr} alt="Star" className="lesson-button-icon" />
            )
        }
    }

    return (
        <div style={{ position: 'relative', marginTop: 30, right: `${rightPos}px` }}>
            <div style={{ width: '120px', height: '120px', position: 'absolute', top: '-16px', left: '-15px' }}>
                {showProgress && (
                    <CircularProgressbarWithChildren
                        value={(progress / total) * 100}
                        styles={{
                            path: {
                                stroke: `#58CC02`,
                            },
                            trail: {
                                stroke: 'var(--border-color-m)',
                            },
                        }}
                    >
                        {/* Empty  */}
                    </CircularProgressbarWithChildren>
                )}
            </div>
            <IonButton
                id={id}
                className={`lesson-button ${isLocked ? 'locked' : ''}`}
                size="large"
                onClick={(e) => {
                    if (isLocked) {
                        e.preventDefault();
                    }
                }}
            >
                {getIcon()}
            </IonButton>
        </div>
    );

}

export default LessonButton;