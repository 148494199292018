import { useState } from "react";
import SituationComponent from "../../../home/SituationComponent";
import { Situation } from "../../../../../types/ai_pivot/situationTypes";
import ContinueButton from "./shared/ContinueButton";
import { ModuleProps } from "./shared/ModuleProps";

import './shared/ModuleBottomBar.css';

interface PracticeModuleProps extends ModuleProps {
    situation: Situation;
}

export const PracticeModule: React.FC<PracticeModuleProps> = ({ situation, moveToNextModule, updateProgressBar }) => {
    const [isCompleted, setIsCompleted] = useState(false);

    const handleComplete = () => {
        updateProgressBar(1);
        setIsCompleted(true);
    }

    return (
        <div>
            <div
                style={{ maxWidth: '550px', margin: 'auto', marginBottom: '12vh', padding: '20px' }}
                className="ion-padding">
                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    {situation &&
                        <>
                            <SituationComponent
                                preloadedSituation={situation}
                                handleMarkCompleted={handleComplete}
                            />
                        </>
                    }
                </div>
            </div>
            <div className="button-bar">
                <div className="center-content">
                    <div className="bottom-btn-wrapper">
                        <ContinueButton
                            onContinue={moveToNextModule}
                            canContinue={isCompleted}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PracticeModule;