import { PostgrestError } from "@supabase/supabase-js";
import { Content, Quiz, LLMCheck, DoDont, PairMatching, Story } from "../types/ai_pivot/contentTypes";
import { supabase } from "./authService";

export const getContent = async (content_id: number) => {
    const { data, error }: {data: Content | null, error: PostgrestError | null} = await supabase
        .from('content')
        .select('*')
        .eq('id', content_id)
        .single();

    return { data, error };
}

export const getQuiz = async (quiz_id: number) => {
    const { data, error }: {data: Quiz | null, error: PostgrestError | null} = await supabase
        .from('quiz')
        .select('*')
        .eq('id', quiz_id)
        .single();

    return { data, error };
}

export const getLLMCheck = async (llmcheck_id: number) => {
    const { data, error }: {data: LLMCheck | null, error: PostgrestError | null} = await supabase
        .from('llmcheck')
        .select('*')
        .eq('id', llmcheck_id)
        .single();

    return { data, error };
}

export const getDoDont = async (doDontId: number) => {
    const { data, error }: { data: DoDont | null, error: PostgrestError | null } = await supabase
        .from('do_dont')
        .select('*')
        .eq('id', doDontId)
        .single();
    console.log("fetch", data)
    return { data, error };
};

export const getPairMatching = async (pairMatchingId: number) => {
    const { data, error }: { data: PairMatching | null; error: PostgrestError | null } = await supabase
        .from('pair_matching')
        .select('*')
        .eq('id', pairMatchingId)
        .single();

    return { data, error };
};

export const getStory = async (storyId: number): Promise<{ data: Story | null; error: PostgrestError | null }> => {
    const { data, error } = await supabase
        .from('stories')
        .select('*')
        .eq('id', storyId)
        .single();
    console.log(data)
    if (error) {
        console.error('Error fetching story:', error);
        return { data: null, error };
    }

    return { data, error };
};