import { IonButtons, IonContent, IonHeader, IonNav, IonPage, IonToolbar } from "@ionic/react";
import ProfileComponent from "../../../components/ai_pivot/profile/ProfileComponent";
import SettingsComponent from "../../../components/ai_pivot/settings/SettingsComponent";
import HelpComponent from "../../../components/ai_pivot/help/HelpComponent";

const Profile: React.FC = () => {
    return (
        <IonPage>
            <IonHeader mode="ios" collapse="fade">
                <IonToolbar>
                    <IonButtons slot="end">
                        <SettingsComponent />
                        <HelpComponent />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <div className="content-block ion-padding">
                    <ProfileComponent />
                </div>
            </IonContent>
        </IonPage>
    );
}

export default Profile;