import { useEffect, useState } from "react";
import { IonButton, IonSpinner, useIonToast } from "@ionic/react";
import axios from 'axios';
import { useAuth } from "../../../../context/AuthContext";
import Scenario from "./Scenario";

const RandomScenarioComponent: React.FC = () => {
    const { userProfile } = useAuth();
    const [situationDesc, setSituationDesc] = useState({
        title: "",
        description: "",
        prompt: "",
        feedback_prompt: "",
        image_url: "",
        audio_url: '',
        audio_volume: 0.5,
        voice_id: '',
    });
    const [refresh, setRefresh] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [present] = useIonToast();

    useEffect(() => {
        const generateRandom = async () => {
            setIsLoading(true);
            present({
                message: 'Generating a scenario for you! Will take around 20 seconds',
                duration: 10000,
                position: 'middle',
              });
            try {
                const response = await axios.post('https://finalbackend-nuob.onrender.com/randomize');
                if (response.data) {
                    setSituationDesc({
                        ...situationDesc,
                        title: response.data.title,
                        description: response.data.description,
                        prompt: response.data.prompt + "Act and reply naturally as the character described. - Reply in short sentences. - Not ask many questions; let the user lead the conversation. - Only verbalize responses; do not describe actions (e.g., 'Runs off' or 'Smiles').",
                        feedback_prompt: response.data.feedback_prompt,
                        image_url: response.data.image_url,
                        voice_id: response.data.voice_id
                    });
                }
            } catch (error) {
                console.error('Error fetching random data:', error);
                alert(error);
            }
            setIsLoading(false);
        }

        generateRandom();
    }, [refresh]);

    if (isLoading) {
        return (
            <div style={{ width: '100%', maxWidth: '550px', margin: 'auto', textAlign: 'center' }}>
                <IonSpinner style={{ width: 100, height: 100, marginTop: 100 }} name="crescent"></IonSpinner>
            </div>
        );
    }

    return (
        <div>
            {userProfile && 
                <Scenario 
                    title={situationDesc.title} 
                    description={situationDesc.description} 
                    prompt={situationDesc.prompt} 
                    feedback_prompt={situationDesc.feedback_prompt} 
                    image_url={situationDesc.image_url} 
                    audio_url={situationDesc.audio_url} 
                    audio_volume={situationDesc.audio_volume} 
                    voice_id={situationDesc.voice_id} 
                    author_id={userProfile.userid} 
                    duration={15} 
                    type={'community'} 
                    author_username={userProfile.username} 
                    category={"Other"} 
                    isPublic={false} 
                    version={1}
                />
            }
            <IonButton onClick={() => setRefresh(!refresh)} mode="ios" expand="block">Next</IonButton>
        </div>
    );
}

export default RandomScenarioComponent;