import { useEffect, useState } from "react";
import { SituationInstance } from "../../../types/ai_pivot/situationTypes";
import { getPublicSituationInstances, submitFeedback, checkFeedback }  from "../../../service/situationService";
import { IonCard, IonContent, IonList, IonInput,IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonIcon, IonTextarea, IonSpinner, IonLabel, IonItem, IonCardSubtitle, IonText } from "@ionic/react";
import { chevronDownOutline, chevronForwardOutline, hourglass, stop, play } from 'ionicons/icons';
import RecordingItem from "../situation/RecordingItem";
import Picture from "../home/Picture";
import { useAuth } from "../../../context/AuthContext";
import './PublicFeedback.css';
import PaginationActions from "../../generic/PaginationActions";

const PublicFeedback: React.FC = () => {
    const [situationInstances, setSituationInstances] = useState<SituationInstance[]>([]);
    const [currPage, setCurrPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [expandedId, setExpandedId] = useState<number | null>(null);
    const [feedbackText, setFeedbackText] = useState<{ [key: number]: { [section: string]: string } }>({}); 
    const [loading, setLoading] = useState<boolean>(true);
    const [feedbackGiven, setFeedbackGiven] = useState<{ [key: number]: boolean }>({});
    const { user } = useAuth();
    const userId = user?.id;

    useEffect(() => {
        const fetchPublicSituationInstances = async () => {
            if (!userId) return;
            const { data, error, totalPages } = await getPublicSituationInstances(userId, currPage);
            if (error) {
                console.error('Error fetching public situation instances:', error);
            } else {
                console.log('Fetched data:', data);
                // setSituationInstances(data || []);
                const mappedData = data?.map((item: any) => ({
                    id: item.id,
                    start_time: item.start_time,
                    end_time: item.end_time,
                    situation_id: item.situation_id,
                    user_id: item.user_id,
                    feedback_as_text: item.feedback_as_text,
                    recording_id: item.recording_id,
                    public: item.public,
                    user: {
                        username: item.username
                    },
                    feedback_count: item.feedback_count,
                    situation: {
                        id: item.situation_id,
                        title: item.title,
                        description: item.description,
                        image_url: item.image_url,
                        author_username: item.author_username,
                        version: item.version
                    },
                    messages: item.messages || []
                }));

                setSituationInstances(mappedData || []);
                setTotalPages(totalPages || 1);

                if (data) {
                    const feedbackCheckPromises = data.map(si =>
                        checkFeedback(userId, si.id)
                    );
                    const feedbackCheckResults = await Promise.all(feedbackCheckPromises);
                    const feedbackGivenStatus = feedbackCheckResults.reduce((acc, curr, index) => {
                        acc[data[index].id] = !!curr;
                        return acc;
                    }, {});
                    setFeedbackGiven(feedbackGivenStatus);
                }
            }
            setLoading(false);
        };

        fetchPublicSituationInstances();
    }, [currPage, userId]);

    const handleExpand = (id: number) => {
        setExpandedId(expandedId === id ? null : id);
    };

    // const handleFeedbackChange = (id: number, text: string) => {
    //     setFeedbackText({ ...feedbackText, [id]: text });
    // };

    const handleFeedbackChange = (id: number, section: string, text: string) => {
        setFeedbackText({
            ...feedbackText,
            [id]: {
                ...feedbackText[id],
                [section]: text
            }
        });
    };

    const handleFeedbackSubmit = async (id: number) => {
        if (!userId) return;

        const feedback = {
            user_id: userId,
            situation_instance_id: id,
            went_well: feedbackText[id]?.whatWentWell || '',
            to_improve: feedbackText[id]?.areasForImprovement || '',
            came_across_as: feedbackText[id]?.howCameAcross || ''
        };

        try {
            await submitFeedback(feedback);
            setFeedbackGiven({ ...feedbackGiven, [id]: true });
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    };

    const handlePrev = () => {
        setCurrPage(currPage - 1);
    }

    const handleNext = () => {
        setCurrPage(currPage + 1);
    }

    if (loading) {
        return (
            <div style={{ width: '100%', maxWidth: '550px', margin: 'auto', textAlign: 'center' }}>
                <IonSpinner style={{ width: 100, height: 100, marginTop: 100 }} name="crescent"></IonSpinner>
            </div>
        );
    }

return (
    <IonContent>
        <div className="public-feedback" style={{ width: '100%', maxWidth: '550px', margin: 'auto', overflowY: 'auto', paddingTop: '40px' }}>
            {situationInstances.map(si => (
                <IonCard key={si.id} className="public-feedback-card" mode="ios">
                    <IonItem style={{ marginBottom: '-10px', marginTop: '5px', fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }}>
                        <IonLabel>Completed by: @{si.user?.username }</IonLabel>
                    </IonItem>
                    <IonCardHeader>
    
                        <IonCardSubtitle>{si.situation?.description }</IonCardSubtitle>
                        <IonText>
                            <div>By @{si.situation?.author_username } • v{si.situation?.version }</div>
                        </IonText>
                        <IonCardTitle>{si.situation?.title }</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div style={{ position: 'relative' }}>
                            <Picture image_url={si.situation?.image_url || ''} />
                        </div>
                        {/* <IonItem>
                            {si.recording_id && <RecordingItem recordingId={si.recording_id} wrapped={false}/>}
                        </IonItem> */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '30px' }}>
                            {si.recording_id && (
                                <>
                                    <IonText style={{ marginBottom: '15px', marginTop: '15px', fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }}>Audio Recording</IonText>
                                    <RecordingItem recordingId={si.recording_id} wrapped={false} />
                                </>
                            )}
                        </div> 
                        {feedbackGiven[si.id] ? (
                            <IonText color="success">Feedback already given</IonText>
                        ) : (
                            <>
                                <IonButton expand="block" onClick={() => handleExpand(si.id)}>
                                    {expandedId === si.id ? 'Collapse' : 'Give Feedback'}
                                    <IonIcon slot="end" icon={expandedId === si.id ? chevronDownOutline : chevronForwardOutline} />
                                </IonButton>
                                {expandedId === si.id && (
                                    <div className="feedback-form">
                                        <IonList inset={true}>
                                                <IonItem style={{ '--padding-end': '0px', '--inner-padding-end': '8px' }}>
                                                    <IonTextarea
                                                        label-placement="floating"
                                                        label="Went well:"
                                                        style={{ '--border-width': 0, '--padding-top': '5px', '--padding-end': '8px' }}
                                                        counter={true}
                                                        maxlength={5000}
                                                        placeholder="Write here..."
                                                        value={feedbackText[si.id]?.whatWentWell || ''}
                                                        onIonChange={e => handleFeedbackChange(si.id, 'whatWentWell', e.detail.value!)}
                                                        autoGrow
                                                    />
                                                </IonItem>
                                                <IonItem style={{ '--padding-end': '0px', '--inner-padding-end': '8px' }}>
                                                    <IonTextarea
                                                        label-placement="floating"
                                                        label="To improve:"
                                                        style={{ '--border-width': 0, '--padding-top': '5px', '--padding-end': '8px' }}
                                                        counter={true}
                                                        maxlength={5000}
                                                        placeholder="Write here..."
                                                        value={feedbackText[si.id]?.areasForImprovement || ''}
                                                        onIonChange={e => handleFeedbackChange(si.id, 'areasForImprovement', e.detail.value!)}
                                                        autoGrow
                                                    />
                                                </IonItem>
                                                <IonItem style={{ '--padding-end': '0px', '--inner-padding-end': '8px' }}>
                                                    <IonTextarea
                                                        label-placement="floating"
                                                        label="Came across as:"
                                                        style={{ '--border-width': 0, '--padding-top': '5px', '--padding-end': '8px' }}
                                                        counter={true}
                                                        maxlength={5000}
                                                        placeholder="Write here..."
                                                        value={feedbackText[si.id]?.howCameAcross || ''}
                                                        onIonChange={e => handleFeedbackChange(si.id, 'howCameAcross', e.detail.value!)}
                                                        autoGrow
                                                    />
                                                </IonItem>
                                            </IonList>
                                        <IonButton color="secondary" expand="block" onClick={() => handleFeedbackSubmit(si.id)}>Submit Feedback</IonButton>
                                    </div>
                                )}
                            </>
                        )}
                        
                    </IonCardContent>
                </IonCard>
            ))}
            {/* <div className="pagination" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                <IonButton onClick={handlePrev} disabled={currPage === 1}>Prev</IonButton>
                <IonButton onClick={handleNext} disabled={currPage === totalPages}>Next</IonButton>
            </div> */}
            <PaginationActions
                currPage={currPage}
                totalPages={totalPages}
                handlePrev={handlePrev}
                handleNext={handleNext}
            />
        </div>
    </IonContent>
);
};

export default PublicFeedback;
