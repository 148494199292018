import React, { useState, useEffect } from 'react';
import { IonButton } from '@ionic/react';
import AnswerCheckPopup from '../quiz/AnswerCheckPopup';
import DropColumn from './DropColumn';
import DraggableItem from './DraggableItem';
import { ModuleProps } from '../shared/ModuleProps';
import { DoDont } from "../../../../../../types/ai_pivot/contentTypes";
import '../shared/ModuleBottomBar.css';
import './DoDontModule.css';
import { DndContext, useDroppable } from '@dnd-kit/core';

interface DoDontModuleProps extends ModuleProps {
    doDont: DoDont;
}

type list = { text: string; audio_url?: string }[];

const shuffleArray = (array: list) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const DoDontModule: React.FC<DoDontModuleProps> = ({ doDont, moveToNextModule, updateProgressBar, hideButton }) => {
    const [doList, setDoList] = useState<list>([]);
    const [dontList, setDontList] = useState<list>([]);
    const [remainingItems, setRemainingItems] = useState<list>([...doDont.doitems, ...doDont.dontitems]);

    const [isOpen, setIsOpen] = useState(false);
    const [isCorrect, setIsCorrect] = useState<boolean>(false);
    const [audios, setAudios] = useState<HTMLAudioElement[]>([]);

    const RemainingItemsDropZone: React.FC = () => {
        const { setNodeRef } = useDroppable({
            id: 'remaining'
        });

        return (
            <div ref={setNodeRef} className="items-container">
                {remainingItems.map((item, index) => (
                    <DraggableItem 
                        key={`${item.text}-${index}`} 
                        text={item.text} 
                        audio_url={item.audio_url} 
                        playAudio={playAudio} />
                ))}
            </div>
        );
    };

    const playAudio = (url: string) => {
        const audio = audios.find(audio => audio.src === url);
        if (audio) {
            if (audio.paused || audio.currentTime === 0) {
                stopAllAudio();
                audio.play();
            } else {
                stopAllAudio();
            }
        }
    };

    const stopAllAudio = () => {
        audios.forEach((audio) => {
            audio.pause();
            audio.currentTime = 0;
        });
    };

    useEffect(() => {
        return () => {
            stopAllAudio();
        };
    }, []);


    useEffect(() => {
        const doItems = doDont.doitems;
        const dontItems = doDont.dontitems;
        
        const allItems = shuffleArray([...doItems, ...dontItems]);
        // const allItems = [...doItems, ...dontItems];
        const preloadedAudios = allItems.map(item => {
            if (item.audio_url) {
                const audio = new Audio(item.audio_url);
                audio.load();
                return audio;
            }
            return null;
        }).filter((audio): audio is HTMLAudioElement => audio !== null);

        setAudios(preloadedAudios);

        setDoList([]);
        setDontList([]);
        setRemainingItems(allItems);

        return () => {
            stopAllAudio();
        };
    }, [doDont]);

    const handleDrop = (item: { text: string; audio_url?: string }, targetList: 'do' | 'dont' | 'remaining') => {
        setDoList((prev) => prev.filter((i) => i.text !== item.text));
        setDontList((prev) => prev.filter((i) => i.text !== item.text));
        setRemainingItems((prev) => prev.filter((i) => i.text !== item.text));


        if (targetList === 'do') {
            setDoList((prev) => [...prev, item]);
        } else if (targetList === 'dont') {
            setDontList((prev) => [...prev, item]);
        } else {
            setRemainingItems((prev) => [...prev, item]);
        }
    };


    const handleRemove = (item: { text: string; audio_url?: string }, sourceList: 'do' | 'dont' | 'remaining') => {
        if (sourceList === 'do') {
            setDoList((prev) => prev.filter((i) => i.text !== item.text));
        } else if (sourceList === 'dont') {
            setDontList((prev) => prev.filter((i) => i.text !== item.text));
        } else {
            setRemainingItems((prev) => prev.filter((i) => i.text !== item.text));
        }
    };

    const handleMove = (item: { text: string; audio_url?: string }, sourceList: 'do' | 'dont' | 'remaining', targetList: 'do' | 'dont' | 'remaining') => {
        if (sourceList !== targetList) {
            handleRemove(item, sourceList);
            handleDrop(item, targetList);
        }
    };

    const checkAnswers = () => {
        const doItems = Array.isArray(doDont.doitems) ? doDont.doitems : [];
        const dontItems = Array.isArray(doDont.dontitems) ? doDont.dontitems : [];

        const correctDo = doItems.every((item) => doList.find((i) => i.text === item.text)) && doItems.length === doList.length;
        const correctDont = dontItems.every((item) => dontList.find((i) => i.text === item.text)) && dontItems.length === dontList.length;
        setIsCorrect(correctDo && correctDont);
        setIsOpen(true);
    };

    const handleDragEnd = (event: { active: any; over: any; }) => {
        // over = droppable
        // active = draggable
        const { active, over } = event;
        console.log('drag even active: ', active);
        console.log('drag even over: ', over);
        if (over) {
            if (over.id === doDont.docolumnname) {
                if (!doList.some((v) => v.text === active.data.current.text)) {
                    handleMove(active.data.current, 'remaining', 'do');
                    handleMove(active.data.current, 'dont', 'do');
                }
            } else if (over.id === doDont.dontcolumnname) {
                if (!dontList.some((v) => v.text === active.data.current.text)) {
                    handleMove(active.data.current, 'remaining', 'dont');
                    handleMove(active.data.current, 'do', 'dont');
                }
            } else {
                if (!remainingItems.some((v) => v.text === active.data.current.text)) {
                    handleMove(active.data.current, 'do', 'remaining');
                    handleMove(active.data.current, 'dont', 'remaining');
                }
            }
        }
    }


    return (
        <DndContext onDragEnd={handleDragEnd} onDragStart={(event) => {
            if (event.active.data.current) {
                let audioUrl = event.active.data.current.audio_url;
                audioUrl && playAudio(audioUrl);
            }
        }}>
            <div>
                <div style={{ maxWidth: '700px', margin: 'auto', marginBottom: '12vh', padding: '0px' }}
                    className="ion-padding">
                    <h2 style={{ padding: '20px' }}>{doDont.question}</h2>
                    <div className="do-dont-container ion-padding">
                        <DropColumn
                            name={doDont.docolumnname}>
                            {doList.map((item, index) => (
                                <DraggableItem 
                                    key={`${item.text}-${index}`}
                                    text={item.text} 
                                    audio_url={item.audio_url} 
                                    playAudio={playAudio} />
                            ))}
                        </DropColumn>
                        <DropColumn
                            name={doDont.dontcolumnname}>
                            {dontList.map((item, index) => (
                                <DraggableItem 
                                    key={`${item.text}-${index}`} 
                                    text={item.text} 
                                    audio_url={item.audio_url} 
                                    playAudio={playAudio} />
                            ))}
                        </DropColumn>
                    </div>
                    <div style={{ padding: '20px' }}>
                        <RemainingItemsDropZone />
                    </div>
                </div>
                {!hideButton && (
                    <div className="button-bar">
                        <div className="center-content">
                            <div className="bottom-btn-wrapper">
                                <IonButton onClick={checkAnswers} expand="block">
                                    Check
                                </IonButton>
                            </div>
                        </div>
                    </div>
                )}
                <AnswerCheckPopup
                    correct={isCorrect}
                    feedback={isCorrect ? "Great job! All items are correctly placed." : "Some items are incorrectly placed. Please try again."}
                    moveToNextModule={() => {
                        setIsOpen(false);
                        stopAllAudio();
                        moveToNextModule();
                    }}
                    canContinue={true}
                    isOpen={isOpen}
                    closeSelf={() => {
                        setIsOpen(false);
                        stopAllAudio();
                    }}
                />
            </div>
        </DndContext>
    );
};

export default DoDontModule;