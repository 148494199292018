import React, { useState, useEffect } from 'react';
import { IonModal, IonButton, IonIcon, IonLabel } from '@ionic/react';
import { heartOutline, heart,chatbubbleEllipsesOutline, heartDislikeOutline, heartDislike, statsChartOutline } from 'ionicons/icons';
import { supabase } from '../../../service/authService';
import CommentModal from "./CommentModal";

interface LikeDislikeCommentProps {
    situationId: number; 
    userId: string; 
    situation_version: number;
    session_count: number;
}

const LikeDislikeComponent: React.FC<LikeDislikeCommentProps> = ({ situationId, userId, situation_version, session_count}) => {
    const [likeCount, setLikeCount] = useState(0);
    const [dislikeCount, setDislikeCount] = useState(0);
    const [liked, setLiked] = useState(false);
    const [disliked, setDisliked] = useState(false);
    const [commentCount, setCommentCount] = useState(0);
    const [showComments, setShowComments] = useState(false);

    useEffect(() => {
        fetchLikesAndDislikes();
        checkUserReaction();
    }, [situationId, userId]);

    useEffect(() => {
        fetchCommentsCount();
    }, [situationId, userId, showComments])

    const fetchCommentsCount = async () => {
        const { data, error, count } = await supabase
            .from('comments')
            .select('*', { count: 'exact' })
            .eq('situation_id', situationId);

        if (error) {
            console.error('Error fetching comment count:', error.message);
        } else {
            setCommentCount(count ?? 0);
        }
    };
    
    const handleComment = () => {
        setShowComments(true);
    };

    const handleCloseComments = () => {
        setShowComments(false);
    };

    const fetchLikesAndDislikes = async () => {
        const { count: likeCount } = await supabase
            .from('likes')
            .select('*', { count: 'exact' })
            .eq('situation_id', situationId);

        const { count: dislikeCount } = await supabase
            .from('dislikes')
            .select('*', { count: 'exact' })
            .eq('situation_id', situationId);

        setLikeCount(likeCount ?? 0);
        setDislikeCount(dislikeCount ?? 0);
    };

    const checkUserReaction = async () => {
        const { data: likes, error: likesError } = await supabase
            .from('likes')
            .select('*')
            .eq('situation_id', situationId)
            .eq('user_id', userId);
        
        if (likesError) console.error('Error fetching likes:', likesError.message);
        setLiked(likes ? likes.length > 0 : false);
    
        const { data: dislikes, error: dislikesError } = await supabase
            .from('dislikes')
            .select('*')
            .eq('situation_id', situationId)
            .eq('user_id', userId);
        
        if (dislikesError) console.error('Error fetching dislikes:', dislikesError.message);
        setDisliked(dislikes ? dislikes.length > 0 : false);
    };

    const handleLike = async () => {
        if (liked) {
            await supabase
                .from('likes')
                .delete()
                .match({ situation_id: situationId, user_id: userId });
            setLikeCount(prev => prev - 1);
            setLiked(false);
        } else {
            if (disliked) {
                await supabase
                    .from('dislikes')
                    .delete()
                    .match({ situation_id: situationId, user_id: userId });
                setDislikeCount(prev => prev - 1);
                setDisliked(false);
            }
            await supabase
                .from('likes')
                .insert({ situation_id: situationId, user_id: userId });
            setLikeCount(prev => prev + 1);
            setLiked(true);
        }
    };

    const handleDislike = async () => {
        if (disliked) {
            await supabase
                .from('dislikes')
                .delete()
                .match({ situation_id: situationId, user_id: userId });
            setDislikeCount(prev => prev - 1);
            setDisliked(false);
        } else {
            if (liked) {
                await supabase
                    .from('likes')
                    .delete()
                    .match({ situation_id: situationId, user_id: userId });
                setLikeCount(prev => prev - 1);
                setLiked(false);
            }
            await supabase
                .from('dislikes')
                .insert({ situation_id: situationId, user_id: userId });
            setDislikeCount(prev => prev + 1);
            setDisliked(true);
        }
    };

    return (

        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingBottom: '10px', width: '100%' }}>
            <IonButton fill="clear" onClick={handleLike} color={liked ? 'danger' : 'medium'} >
                <IonIcon icon={liked ? heart : heartOutline} style={{fontSize: 23}} />
                <span>{likeCount}</span>
            </IonButton>
            <IonButton fill="clear" onClick={handleDislike} color={disliked ? 'dark' : 'medium'} >
                <IonIcon icon={disliked ? heartDislike : heartDislikeOutline} style={{fontSize: 23}} />
            </IonButton>
            <IonButton fill="clear" onClick={handleComment} color={'medium'} >
                <IonIcon icon={chatbubbleEllipsesOutline} style={{fontSize: 23}} />
                <span>{commentCount}</span>
            </IonButton>
            <IonButton fill="clear" color={'medium'}>
                <IonIcon icon={statsChartOutline} style={{fontSize: 23}} />
                <span>{session_count}</span>
            </IonButton>
            <CommentModal
                isOpen={showComments}
                onClose={() => setShowComments(false)}
                situationId={situationId}
                situation_version = {situation_version}
            />
        </div>
    );
};

export default LikeDislikeComponent;
