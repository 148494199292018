import { IonCard, IonToggle, IonCardHeader, IonSegmentButton, IonSegment, IonCardSubtitle, IonText, IonCardTitle, IonCardContent, IonIcon, IonFooter, useIonToast, IonButton, IonContent, IonHeader, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { play, stop, hourglass,chatbubbleEllipsesOutline, micOutline, chatboxOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { useAuth } from "../../../context/AuthContext";
import { Situation, SituationInstance } from "../../../types/ai_pivot/situationTypes";
import Loading from "../../generic/Loading";
import Feedback from "../home/Feedback";
import LikeDislikeComponent from "../home/LikeDislikeComponent";
import Picture from "../home/Picture";
import { useEffect, useState, useRef } from "react";
import { DailyCall, DailyEventObjectTrack } from "@daily-co/daily-js";
import { supabase } from "../../../service/authService";
import { createSituationInstance, getLatestXSituationSummaries } from "../../../service/situationService";
import axios from 'axios';
import PlanUpgradeComponent from "../payment/PlanUpgradeComponent";
import SitFeedback from "./SitFeedback";
import RecordingAudio from './RecordingAudio'; 
import SurveyModal from './SurveyModal';
import TextChatComponent from './TextChatComponent';
import './SituationBaseComponent.css';
interface InstructionDetails {
    title: string;
    steps: string[];
    // imageUrl: string;
}

function getBrowserAndOS() {
    const userAgent = navigator.userAgent;
    const isMac = userAgent.includes('Macintosh');
    const isiPhone = userAgent.includes('iPhone');
    const isWindows = userAgent.includes('Windows');
    const isAndroid = userAgent.includes('Android');
    const isChrome = userAgent.includes('Chrome');
    const isSafari = userAgent.includes('Safari') && !userAgent.includes('Chrome');

    if (isMac) {
        return isSafari ? 'Mac - Safari' : 'Mac - Chrome';
    } else if (isiPhone) {
        return isSafari ? 'iPhone - Safari' : 'iPhone - Chrome';
    } else if (isWindows) {
        return 'Windows - Chrome';
    } else if (isAndroid) {
        return 'Android - Chrome';
    } else {
        return 'Other';
    }
}

const SituationOverviewBase: React.FC<{
    situation: Situation,
    isActive: boolean,
    updateCurrentlyActive: (sitId: Situation['id'] | null) => Promise<void>,
    callObject: DailyCall | null,
    feedback?: string | null
}> = (
    { situation, isActive, updateCurrentlyActive, callObject, feedback }) => {
        const { session } = useAuth();
        const currentUser = session?.user;
        const [inProgress, setInProgress] = useState(false);
        const [backgroundAudio, setBackgroundAudio] = useState<HTMLAudioElement>();
        const [isLoading, setIsLoading] = useState(false);
        const { user, userProfile } = useAuth();
        const [showPurchaseOptions, setShowPurchaseOptions] = useState<boolean>(false);
        const [showModal, setShowModal] = useState(false);
        const [showRecordings, setShowRecordings] = useState(false);
        const [roomName, setRoomName] = useState<string>('');
        const [instanceId, setInstanceId] = useState<number>(1);

        const [showPreSurvey, setShowPreSurvey] = useState(false); 
        const [showPostSurvey, setShowPostSurvey] = useState(false);
        const [surveyCompleted, setSurveyCompleted] = useState(false);
        const preSurveyResponseRef = useRef<{ [key: string]: string }>({});
        const postSurveyResponseRef = useRef<{ [key: string]: string }>({});

        const [useTextChat, setUseTextChat] = useState(false);
        const [showTextChat, setShowTextChat] = useState(false);

        const [pollingStarted, setPollingStarted] = useState(false);

        const [postData, setPostData] = useState<{
            instance_id: number;
            prompt: string;
            feedback_prompt: string;
        } | null>(null);
        const [isExpanded, setIsExpanded] = useState(false);

        const checkBotStatus = async () => {
            try {
                // const response = await axios.get(`http://localhost:3000/bot_status/${instanceId}`);
                const response = await axios.get(`https://finalbackend-nuob.onrender.com/bot_status/${instanceId}`);
                if (response.data.bot_status === "started") {
                    console.log("Started speaking");
                    setInProgress(true); 
                    setIsLoading(false);  
                    setPollingStarted(false); 
                }
            } catch (error) {
                console.error("Error checking bot status", error);
            }
        };

        useEffect(() => {
            let intervalId: NodeJS.Timeout;
    
            if (pollingStarted) {
                intervalId = setInterval(checkBotStatus, 1000); // Poll every second
            }
    
            return () => clearInterval(intervalId); // Clear polling on cleanup
        }, [pollingStarted]);

        const toggleExpand = () => {
            setIsExpanded(!isExpanded);
        };

        useEffect(() => {
            if (!isActive && inProgress) {
                console.log('handle stop: ', situation.id)
                handleStop();
            } else if (isActive && !inProgress && surveyCompleted) {
                console.log('handle start: ', situation.id)
                handleStart();
            }
        }, [isActive, surveyCompleted])

        useEffect(() => {
            return () => {
                try {
                    callObject?.leave();
                    callObject?.destroy();
                } catch (e) {
                    console.log(e);
                }
            }
        }, [callObject])

        useEffect(() => {
            if (situation.audio_url && situation.audio_volume) {
                const audio = new Audio(situation.audio_url);
                audio.loop = true;
                console.log(situation.audio_volume);
                audio.volume = situation.audio_volume; //data.audio_volume;
                setBackgroundAudio(audio);

                return () => {
                    audio.pause();
                }
            }
        }, []);

        const playAudio = (event: DailyEventObjectTrack) => {
            console.log('Track started event:', event);


            if (!event.participant || !event.track || event.participant.local) {
                console.log('Ignoring track: either participant or track is null, or it\'s the local participant\'s track.');
                return;
            }

            if (event.track.kind !== 'audio') {
                console.log('Ignoring non-audio track');
                return;
            }

            let audioEl = document.querySelector(`audio[data-participant-id='${event.participant.session_id}']`) as HTMLAudioElement;
            if (!audioEl) {
                console.log('Creating new audio element for participant:', event.participant.session_id);
                audioEl = document.createElement('audio');
                audioEl.setAttribute('data-participant-id', event.participant.session_id);
                audioEl.autoplay = true;
                document.body.appendChild(audioEl);
            }

            console.log('Attaching the track and playing it');
            audioEl.srcObject = new MediaStream([event.track]);
            audioEl.play().catch((error) => {
                console.error('Error playing the audio:', error);
                handlePermissionIssue();
            });
        };

        const getAdditionalContext = async () => {

            if (situation && user) {
                const { data, error } = await getLatestXSituationSummaries(situation, user.id);
                if (error) {
                    console.error('Error loading last therapy meeting summaries');
                } else if (data) {
                    let res = "";
                    // first loop to check if there is feedback recorded and initialize additional context
                    for (let i = 0; i < data.length; i++) {
                        let currFeedback = data[i].feedback_as_text;
                        if (currFeedback && currFeedback.length > 0 && res.length === 0) {
                            res = `
Additional instructions: 
- Review previous session summary notes below. If there were planned action items, start by asking the user if he/she took action noted during previous sessions.

Context:
Previous session summary notes: `
                        }
                    }

                    // second loop to add previous session summary notes
                    for (let i = 0; i < data.length; i++) {
                        let currFeedback = data[i].feedback_as_text;
                        if (currFeedback && currFeedback.length > 0) {
                            res += `

''' ${currFeedback}' '''`
                        }
                    }

                    return res;
                }
            }

            return "";
        }

        const handlePresurvey= async () => {
            preSurveyResponseRef.current = {};
            setShowPreSurvey(true);
        }

        const handleStart = async () => {
            if (useTextChat) {
                try {
                    setIsLoading(true);
        
                    const { data, error } = await createSituationInstance(situation.id);
                    if (error || !data) {
                        if (error?.message.includes('You ran out of available sessions.')) {
                            setShowPurchaseOptions(true);
                        } else {
                            console.error("Failed to initialize situation instance:", error);
                            alert('Error encountered, please try again later');
                        }
                        // setIsLoading(false);
                        return;
                    }
        
                    const instanceId = data.id;
                    setInstanceId(instanceId);

                    let additionalContext = await getAdditionalContext();
                    let completePrompt = situation.prompt + additionalContext;
                    completePrompt = completePrompt.replace('{name}', userProfile?.name || 'User');
                    const postData = {
                        instance_id: instanceId,
                        prompt: completePrompt,
                        feedback_prompt: situation.feedback_prompt
                    };
        
                    setShowTextChat(true);
                    setInProgress(true);
                    setPostData(postData);

                } catch (error) {
                    console.error('Error starting the chat:', error);
                    alert('Error encountered while starting the chat. Please reload the page and retry.');
                } finally {
                    // setIsLoading(false);
                }
                return;
            }
            
            console.log("act")
            setShowRecordings(false);
            
            try {
                const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });
                switch (permissionStatus.state) {
                    case 'granted':
                        startCall();
                        break;
                    case 'prompt':
                        try {
                            await navigator.mediaDevices.getUserMedia({ audio: true });
                            startCall();
                        } catch (error) {
                            console.error('Microphone access denied by user:', error);
                            handlePermissionIssue();
                        }
                        break;
                    case 'denied':
                        handlePermissionIssue();
                        break;
                }
            } catch (error) {
                console.error('Error checking microphone permissions:', error);
                handlePermissionIssue();
            }
        };

        const startCall = async () => {
            if (callObject) {
                callObject.on('participant-joined', participant => {
                    console.log('[participant-joined]', participant);
                });
                callObject.on('participant-left', participant => {
                    console.log('[participant-left]', participant);
                });
                callObject.on('track-started', playAudio);
                callObject.on('track-stopped', (e) => console.log('[track-stopped]', e));
            }

            if (situation && callObject && user && userProfile && situation.id !== undefined) {
                setIsLoading(true);

                const { data, error } = await createSituationInstance(situation.id);
                if (error || !data) {
                    if (error?.message.includes('You ran out of available sessions.')) {
                        setShowPurchaseOptions(true);
                    } else {
                        console.error("Failed to initialize situation instance:", error);
                        alert('Error encountered, please try again later');
                    }
                    // setIsLoading(false);
                    return;
                }
                // console.log(data);
                //setSituationInstance(data);

                let additionalContext = await getAdditionalContext();
                console.log('additinal context: ', additionalContext);
                let completePrompt = situation.prompt + additionalContext;
                completePrompt = completePrompt.replace('{name}', userProfile.name);
                console.log('complete prompt: ', completePrompt);

                const postData = {
                    instance_id: data.id,
                    prompt: completePrompt,
                    voice_id: situation.voice_id,
                    feedback_prompt: situation.feedback_prompt
                };
                try {
                    console.log(postData);
                    //const response = await axios.post('http://localhost:3000/start', postData);
                    const response = await axios.post(process.env.NODE_ENV === 'development' ? 'https://finalbackend-nuob.onrender.com/start' : 'https://finalbackend-nuob.onrender.com/start', postData);
                    if (response.data && response.data.url) {
                        console.log('Joining Daily room:', response.data.url);
                        console.log('Daily room:', response.data.room_name);
                        setPollingStarted(true);
                        setRoomName(response.data.room_name);
                        setInstanceId(data.id);
                        await callObject.join({ url: response.data.url });
                        await callObject.startRecording({
                            layout: {
                              preset: 'audio-only',
                            },
                          });

                        if (backgroundAudio)
                            backgroundAudio.play();
                        setInProgress(true);

                        // update ballance
                        // const { error } = await supabase
                        //         .from('users')
                        //         .update({balance: userProfile.balance - 1})
                        //         .eq('userid', userProfile.userid);
                        if (error) {
                            console.error('error decreasing user balance: ', JSON.stringify(error));
                        }
                    }
                } catch (error) {
                    console.error('Error starting the call:', error);
                    alert('Error encountered while starting the call. Please reload the page and retry.');
                }
                // setIsLoading(false);
            }
        };

        const handlePermissionIssue = () => {
            console.log("test")
            const browserAndOS = getBrowserAndOS();
            const instructions = getInstructionByBrowser(browserAndOS);
            setShowModal(true);

            handleStop(); //Verify on future 
            //setIsFeedbackLoading(false); //Verify on future 
            setIsLoading(false); //Verify on future 
        };

        const getInstructionByBrowser = (browserAndOS: string): InstructionDetails => {
            const instructionsMap: { [key: string]: InstructionDetails } = {
                'Mac - Safari': {
                    title: 'Enable Microphone Access in Safari on Mac',
                    steps: [
                        'Open Safari.',
                        'Click on Safari in the menu bar, then select Preferences.',
                        'Navigate to the Websites tab.',
                        'Scroll to the Microphone section.',
                        'Next to the website you want to allow, change the setting to Allow.'
                    ],
                    // imageUrl: '/path_to_safari_mac_image.png'
                },
                'Mac - Chrome': {
                    title: 'Enable Microphone Access in Chrome on Mac',
                    steps: [
                        'Open Chrome.',
                        'Click on Chrome in the menu bar, then select Preferences.',
                        'Scroll to the Privacy and Security section, and click on Site Settings.',
                        'Find and click on Microphone.',
                        'Make sure the setting is set to Ask before accessing or Allow for specific sites.'
                    ],
                    // imageUrl: '/path_to_chrome_mac_image.png'
                },
                'iPhone - Safari': {
                    title: 'Enable Microphone Access in Safari on iPhone',
                    steps: [
                        'Open the Settings app.',
                        'Scroll down and tap Safari.',
                        'Scroll down to the Settings for Websites section.',
                        'Tap on Microphone and ensure it’s set to "Allow" or "Ask".'
                    ],
                    // imageUrl: '/path_to_safari_iphone_image.png'
                },
                'iPhone - Chrome': {
                    title: 'Enable Microphone Access in Chrome on iPhone',
                    steps: [
                        'Open the Chrome app.',
                        'Tap on the menu at the bottom-right (three dots).',
                        'Tap on Settings.',
                        'Scroll down to Site Settings.',
                        'Tap on Microphone and change it to Ask first or Allow.'
                    ],
                    // imageUrl: '/path_to_chrome_iphone_image.png'
                },
                'Windows - Chrome': {
                    title: 'Enable Microphone Access in Chrome on Windows',
                    steps: [
                        'Open Chrome.',
                        'Click on the three dots in the upper right corner to open the menu.',
                        'Click on Settings.',
                        'Scroll down and click on Privacy and security.',
                        'Click on Site settings.',
                        'Under Permissions, click on Microphone.',
                        'Ensure that Ask before accessing is on, or allow specific sites to use the microphone.'
                    ],
                    // imageUrl: '/path_to_chrome_windows_image.png'
                },
                'Android - Chrome': {
                    title: 'Enable Microphone Access in Chrome on Android',
                    steps: [
                        'Open the Chrome app.',
                        'Tap on the menu icon (three dots) at the top right.',
                        'Tap on Settings.',
                        'Scroll down to Site settings.',
                        'Tap on Microphone and ensure access is allowed.'
                    ],
                    // imageUrl: '/path_to_chrome_android_image.png'
                },
                'Other': {
                    title: 'Enable Microphone Access',
                    steps: [
                        'Open your browser.',
                        'Navigate to the browser’s settings or preferences.',
                        'Find the privacy or security section.',
                        'Locate microphone settings and enable it for the required website.'
                    ],
                    // imageUrl: '/generic_browser_settings_image.png'
                }
            };

            return instructionsMap[browserAndOS] || instructionsMap['Other'];
        };

        const handleStop = () => {
            if (inProgress) {
                //setIsFeedbackLoading(true);
                setInProgress(false);
                setPollingStarted(false);
                setShowRecordings(true);
                setShowPostSurvey(true);
                console.log("audio restart")
                console.log(preSurveyResponseRef.current);
                setSurveyCompleted(false);
            }
            
            backgroundAudio?.pause();
            console.log('Call ended');
            setShowTextChat(false);
        };

        const updateSurveyResponse = async () => {
            console.log("Updating Supabase with survey responses...");
            console.log(preSurveyResponseRef.current);
            console.log(postSurveyResponseRef.current);
            
            const isPublic = preSurveyResponseRef.current['make_public'] === 'true';
            console.log("id",instanceId)
            const { error } = await supabase
                .from('situation_instance')
                .update({
                    survey_response: {
                        preSurvey: preSurveyResponseRef.current,
                        postSurvey: postSurveyResponseRef.current
                    },
                    public: isPublic,
                })
                .eq('id', instanceId);
        
            if (error) {
                console.error('Error updating survey responses in Supabase:', error);
            } else {
                console.log('Survey responses successfully updated in Supabase');
            }
        };

        const PermissionModal = () => {
            const instructions = getInstructionByBrowser(getBrowserAndOS());
            const onClose = () => setShowModal(false);
            return (
                <IonModal isOpen={showModal} onDidDismiss={onClose}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle style={{ textAlign: 'center' }}>Enable Microphone Access</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <div style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px' }}>
                            {instructions.title}
                        </div>
                        <IonList>
                            {instructions.steps.map((step, index) => (
                                <IonItem key={index} lines="none">
                                    <IonLabel>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div style={{ fontWeight: 'bold', marginRight: '8px' }}>{index + 1}.</div>
                                            <div>{step}</div>
                                        </div>
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                        {/* {instructions.imageUrl && (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={instructions.imageUrl} alt="Visual Guide" style={{ width: '100%', maxWidth: '500px', padding: '10px' }} />
                                </div>
                            )} */}
                        <IonButton expand="block" onClick={onClose} style={{ marginTop: '20px' }}>Close</IonButton>
                    </IonContent>
                </IonModal>
            );
        };

        return (
            <>
                <PermissionModal />
                <PlanUpgradeComponent show={showPurchaseOptions} close={() => setShowPurchaseOptions(false)} />
                <IonCard mode="ios" className="custom-ion-card">
                    <IonCardHeader style={{ paddingTop: '30px' }}>
                        {/* <IonCardSubtitle>{situation.description}</IonCardSubtitle> */}
                        <IonCardSubtitle>
                            <div className={`description ${isExpanded ? 'expanded' : ''}`}>
                                {situation.description}
                            </div>
                            {!isExpanded && (
                                <IonText onClick={toggleExpand} className="read-more">
                                    Read More
                                </IonText>
                            )}
                            {isExpanded && (
                                <IonText onClick={toggleExpand} className="read-less">
                                    Show Less
                                </IonText>
                            )}
                        </IonCardSubtitle>
                        <br />
                        <IonText style={{ paddingTop: '6px' }}>
                            <div>By @{situation.author_username} • v{situation.version}</div>
                        </IonText>
                        {/* <br /> */}
                        <IonCardTitle>{situation.title}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div style={{ width: '100%', height: '100%', backgroundColor: 'danger' }}
                            onClick={() => {
                                if (situation) {
                                    if (!isLoading) {
                                        if (!inProgress) {
                                            handlePresurvey();
                                            updateCurrentlyActive(situation.id)
                                        } else {
                                            updateCurrentlyActive(null);
                                        }
                                    }
                                }
                            }
                            }
                        >
                            <IonIcon style={
                                {
                                    position: 'absolute',
                                    zIndex: 2,
                                    left: 0,
                                    right: 0,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    top: 0,
                                    bottom: 0,
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    color: 'white',
                                    width: '50px',
                                    height: '50px'
                                }} icon={isLoading ? hourglass : inProgress ? stop : play} size="large" />
                            <Picture image_url={situation.image_url} />
                        </div>
                        {/* <div className="toggle-container">
                            <span className="toggle-label">Voice</span>
                            <IonToggle
                                checked={useTextChat}
                                onIonChange={() => setUseTextChat(!useTextChat)}
                            />
                            <span className="toggle-label">Text</span>
                        </div> */}
                        {/* <div className="toggle-container">
                            <IonIcon className="toggle-label" icon={chatbubbleEllipsesOutline} />
                            <IonToggle
                                checked={useTextChat}
                                onIonChange={() => setUseTextChat(!useTextChat)}
                            />
                            <IonIcon className="toggle-label" icon={micOutline} />
                        </div> */}

                        {/* <IonSegment value={useTextChat ? 'text' : 'voice'} onIonChange={(e) => setUseTextChat(e.detail.value === 'text')}>
                            <IonSegmentButton value="text">
                                <IonLabel>Text</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="voice">
                                <IonLabel>Voice</IonLabel>
                            </IonSegmentButton>
                        </IonSegment> */}



                        {/* <IonButton onClick={() => useTextChat ? handleStart() : showPrompt()} expand="block" color="primary">
                            {useTextChat ? 'Start Chat' : 'Start'}
                        </IonButton> */}
                    </IonCardContent>
                    <IonFooter style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        {currentUser && situation.id !== undefined && situation.version !== undefined && (
                            <LikeDislikeComponent
                                situationId={situation.id}
                                userId={currentUser.id}
                                situation_version={situation.version}
                                session_count={situation.sessions} />
                        )}
                    </IonFooter>
                </IonCard>
                {
                    feedback
                    &&
                    <SitFeedback feedback={feedback} />
                }
                {showRecordings && <RecordingAudio roomName={roomName} InstanceId={instanceId}/>}
                <SurveyModal
                    isOpen={showPreSurvey}
                    justCloseModal={() => setShowPreSurvey(false)}
                    onClose={(data) => {
                        setShowPreSurvey(false);
                        console.log('Pre-call survey data:', data);
                        if(data){
                            preSurveyResponseRef.current = data;
                            setSurveyCompleted(true); 
                            setUseTextChat(data.useTextChat === 'true');
                        }
                            
                    }}
                    title="Pre-call Survey"
                    
                    questions={[
                        { id: 'q1', text: 'What are you afraid might happen?', type: 'text' },
                        { id: 'make_public', text: 'Get feedback from others?', type: 'boolean' },
                        { id: 'useTextChat', text: 'Use text chat?', type: 'toggle' }
                    ]}
                />
                <SurveyModal
                    isOpen={showPostSurvey}
                    onClose={(data) => {
                        setShowPostSurvey(false);
                        console.log('Post-call survey data:', data);
                        if(data){
                            postSurveyResponseRef.current = data;
                            updateSurveyResponse();
                        }
                        
                        
                        }}
                    title="Post-call Survey"
                    questions={[
                        { id: 'q5', text: 'Anxiety level', type: 'range'},
                        { id: 'q6', text: 'How realistic / engaging did it feel?', type: 'range'},
                        { id: 'q1', text: 'Did the thing that you were afraid of actually happened and why do you think so?', type: 'text' },
                        { id: 'q2', text: 'If what you feared happened, was it as bad as you thought?', type: 'text' },
                        { id: 'q3', text: 'What have you learned?', type: 'text' },
                        { id: 'q4', text: 'Any feedback for this scenario?', type: 'text' }
                    ]}
                />
                {showTextChat && postData && (
                    <TextChatComponent
                        postData={postData}
                        situationData={{
                            title: situation.title,
                            description: situation.description,
                            author_username: situation.author_username,
                            version: situation.version,
                            image_url: situation.image_url
                        }}
                        isOpen={showTextChat}
                        onClose={() => {
                            setIsLoading(false);
                            setShowTextChat(false);
                            handleStop();
                        }}
                    />
                )}
            </>
        );
    }

export default SituationOverviewBase;