import React, { useEffect, useState } from "react";
import { Situation } from "../../../types/ai_pivot/situationTypes";
import { getUserSituations } from "../../../service/situationService";
import { useAuth } from '../../../context/AuthContext';
import {
    IonSpinner,
} from '@ionic/react';
import Situations from "./Situations";
import { supabase } from "../../../service/authService";

const UserSituations: React.FC = () => {
    const [situations, setSituations] = useState<Situation[] | null>(null);
    const [error, setError] = useState<string | null>(null);
    const { session } = useAuth();
    const currentUser = session?.user;

    useEffect(() => {
        const fetchSituations = async () => {
            if (currentUser) {
                const { data, error } = await getUserSituations(currentUser.id);
                if (error) {
                    console.error('Error fetching situations: ', error.message);
                    setError(error.message);
                } else {
                    console.log(data);
                    setSituations(data);
                }
            }
        }

        fetchSituations();
    }, []);

    // useEffect(() => {
    //     if (session?.user?.id && situations) {
    //         const channel = supabase
    //             .channel('schema-db-changes')
    //             .on(
    //                 'postgres_changes',
    //                 {
    //                     event: 'UPDATE',
    //                     schema: 'public',
    //                     table: 'situation',
    //                     filter: `author_id=eq.${session.user.id}`,
    //                 },
    //                 (payload) => {
    //                     console.log('updated')
    //                     console.log(payload);
    //                     let updated = payload.new as Situation;
    //                     let current = situations.find((s) => s.id === updated.id);

    //                     if (current) {
    //                         // TODO: update only if needed fields have changed
                        
    //                         let updatedSituations = [...situations].map((s) => {
    //                             if (s.id === updated.id) {
    //                                 return updated;
    //                             } else {
    //                                 return s;
    //                             }
    //                         })

    //                         setSituations(updatedSituations);
    //                     }
    //                 }
    //             )
    //             .on(
    //                 'postgres_changes',
    //                 {
    //                     event: 'INSERT',
    //                     schema: 'public',
    //                     table: 'situation',
    //                     filter: `author_id=eq.${session.user.id}`,
    //                 },
    //                 (payload) => {
    //                     let s = payload.new as Situation;
    //                     let updateSituations = [...[s], ...situations];
    //                     setSituations(updateSituations);
    //                 }
    //             )
    //             .subscribe();

    //         return () => {
    //             channel.unsubscribe();
    //         }
    //     }
    // }, [session?.user?.id, situations]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!situations) {
        return (
            <div style={{ width: '100%', maxWidth: '550px', margin: 'auto', textAlign: 'center' }}>
                <IonSpinner style={{ width: 100, height: 100, marginTop: 100 }} name="crescent"></IonSpinner>
            </div>
        );
    }

    return (
        <div>
            {/* <Link to="/create-situation">
                <IonButton expand="block">Create New Situation</IonButton>
            </Link> */}
            {situations && <Situations 
                                situations={situations} 
                                edit={true} 
                            />}
        </div>
    );
}

export default UserSituations;
