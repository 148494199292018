import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonIcon, IonText, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";
import { happyOutline, buildOutline, callOutline, personCircleOutline  } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import { supabase } from '../../service/authService';
import { useAuth } from '../../context/AuthContext';
import './AIprofile.css';

interface AIFriendProfile {
  user_id: string;
  name: string;
  age: number;
  gender: string;
  interests: string[];
  personality_traits: { [key: string]: number };
  voice_id: string;
  custom: boolean;  // Assuming this is a flag indicating whether the profile is custom
}

const AIfriendpage: React.FC = () => {
  const [aiFriend, setAiFriend] = useState<AIFriendProfile | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { session } = useAuth();
  const currentUser = session?.user;

  useEffect(() => {
    const fetchAiFriend = async () => {
      
      if (currentUser) {
        const { data, error } = await supabase
          .from('ai_profiles')
          .select('*')
          .eq('user_id', currentUser.id)
          .single();
        if (error) {
          console.error('Error fetching AI friend:', error);
        } else {
          setAiFriend(data);
        }
      }
      setIsLoading(false);
    };

    fetchAiFriend();
  }, []);

  const startCall = () => {
    console.log("Starting call with AI...");
    // Logic to start the call
  };

  const getAiStatusCard = () => {
    if (!aiFriend) return <IonText>No AI friend set up yet.</IonText>;

    return (
      <IonCard>
        <IonCardContent>
          <IonIcon icon={aiFriend.custom ? buildOutline : happyOutline} style={{ fontSize: '24px', color: 'var(--ion-color-primary)' }} />
          <IonText>{aiFriend.custom ? "You have a custom AI friend created." : "You have a preset AI friend selected."}</IonText>
          <IonButton fill="clear" onClick={startCall}>
            <IonIcon slot="start" icon={callOutline} />
            Start Call
          </IonButton>
        </IonCardContent>
      </IonCard>
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    // <IonPage>
    //   <IonHeader>
    //     <IonToolbar>
    //       <IonTitle>Welcome to AI Friends</IonTitle>
    //     </IonToolbar>
    //   </IonHeader>
    //   <IonContent className="ion-padding">
    //     <IonText>
    //       <h2>Start your journey</h2>
    //       <p>Choose to start interacting with an AI friend immediately or customize your own!</p>
    //     </IonText>
    //     <IonButton expand="block" routerLink="/quick-start" style={{ marginTop: '20px' }}>
    //       <IonIcon slot="start" icon={happyOutline} />
    //       Quick Start
    //     </IonButton>
    //     <IonButton expand="block" routerLink="/customize" style={{ marginTop: '20px' }}>
    //       <IonIcon slot="start" icon={buildOutline} />
    //       Custom Creation
    //     </IonButton>
    //     {getAiStatusCard()}
    //   </IonContent>
    // </IonPage>
    <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Welcome to AI Friends</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonText>
                    <h2>Start your journey</h2>
                    <p>Choose to start interacting with an AI friend immediately or customize your own!</p>
                </IonText>
                <ProfileStatusCard aiFriend={aiFriend} />
            </IonContent>
        </IonPage>
  );
};

export default AIfriendpage;

// const ProfileStatusCard: React.FC<{ aiFriend: AIFriendProfile | null }> = ({ aiFriend }) => {
//   return (
//       <IonCard>
//           <IonCardContent>
//               {aiFriend ? (
//                   <>
//                       <IonIcon icon={aiFriend.custom ? buildOutline : happyOutline} style={{ fontSize: '28px', color: 'var(--ion-color-primary)' }} />
//                       <IonText>
//                           <h2>{aiFriend.custom ? "Custom AI Friend" : "Preset AI Friend"}</h2>
//                           <p>{`Name: ${aiFriend.name}`}</p>
//                       </IonText>
//                       <IonButton expand="block" onClick={() => console.log("Starting call...")} style={{ marginTop: '10px' }}>
//                           Start Call
//                       </IonButton>
//                       <IonButton expand="block" routerLink="/customize" color="secondary" style={{ marginTop: '10px' }}>
//                           Edit Profile
//                       </IonButton>
//                   </>
//               ) : (
//                   <>
//                       <IonText>
//                           <h2>No AI Friend Set Up</h2>
//                           <p>Setup an AI friend to start interacting.</p>
//                       </IonText>
//                       <IonButton expand="block" routerLink="/customize" style={{ marginTop: '10px' }}>
//                           Create AI Friend
//                       </IonButton>
//                   </>
//               )}
//           </IonCardContent>
//       </IonCard>
//   );
// };

const getAvatarIcon = (aiFriend: AIFriendProfile | null): string => {
  if (!aiFriend) return personCircleOutline; // Default icon
  // Example conditional logic for different icons
  return aiFriend.custom ? buildOutline : happyOutline;
};

const ProfileStatusCard: React.FC<{ aiFriend: AIFriendProfile | null }> = ({ aiFriend }) => {
  return (
      <IonCard>
          <IonCardHeader>
              <IonIcon icon={getAvatarIcon(aiFriend)} style={{ fontSize: '48px', color: 'var(--ion-color-secondary)' }} />
              <IonCardTitle>{aiFriend ? `${aiFriend.name}'s Profile` : "No AI Friend Set Up"}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
              {aiFriend ? (
                  <>
                      <IonText>
                          <h3>{aiFriend.custom ? "Custom AI Friend" : "Preset AI Friend"}</h3>
                          <p>{`Interests: ${aiFriend.interests.join(", ")}`}</p>
                      </IonText>
                      <IonButton expand="block" onClick={() => console.log("Starting call...")} style={{ marginTop: '10px' }}>
                          Start Call
                      </IonButton>
                      <IonButton expand="block" routerLink="/customize" color="secondary" style={{ marginTop: '10px' }}>
                          Edit Profile
                      </IonButton>
                      <IonButton expand="block" routerLink="/avatar" color="secondary" style={{ marginTop: '10px' }}>
                          Edit Avatar
                      </IonButton>
                  </>
              ) : (
                  <>
                      <IonText>Create or select an AI friend to start interacting.</IonText>
                      <IonButton expand="block" routerLink="/customize" style={{ marginTop: '10px' }}>
                          Create AI Friend
                      </IonButton>
                  </>
              )}
          </IonCardContent>
      </IonCard>
  );
};
