import { PostgrestError } from "@supabase/supabase-js"
import { Skill, UserProgress, StreakData } from "../types/ai_pivot/skillTypes"
import { supabase } from "./authService"

export const loadAllSkills = async () => {
    const { data, error }: {data: Skill[] | null, error: PostgrestError | null} = await supabase
        .from('skill')
        .select(`
            skill_id, 
            skill_name, 
            level, 
            lesson!lesson_skill_id_fkey (lesson_id, skill_id, lesson_number, modules, lesson_name, lesson_type)
            `)
        .order('level', {ascending: true});
    
    return { data, error };
}

export const loadUserProgress = async (user_id: string) => {
    const { data, error }: { data: UserProgress[] | null, error: PostgrestError | null } = await supabase
        .from('user_progress')
        .select('*')
        .eq('user_id', user_id)

    return { data, error };
}

export const updateUserProgress = async (user_id: string, skill_id: number, lesson_id: number, completion_status: boolean, progress: number) => {
    const { data, error}: {data: UserProgress | null, error: PostgrestError | null} = await supabase
        .from('user_progress')
        .upsert({user_id, skill_id, lesson_id, last_practiced: new Date(), completion_status, progress})
        .select()
        .single();

    return { data, error };
}


export const fetchUserStreak = async (userId: string): Promise<StreakData | null> => {
    // Fetch streak data, now including completed_dates
    const { data: streakData, error: streakError, status } = await supabase
        .from('user_streaks')
        .select('*')
        .eq('user_id', userId)
        .single();
  
    if (streakError && status !== 406) { // 406 means no rows were returned
        console.error('Error fetching streak data:', streakError);
        return null;
    }

    if (!streakData) {
        // Return a default StreakData if no streak data exists
        return {
            user_id: userId,
            current_streak: 0,
            last_active_date: '',
            longest_streak: 0,
            completedDates: []
        };
    }

    return {
        user_id: streakData.user_id,
        current_streak: streakData.current_streak,
        last_active_date: streakData.last_active_date,
        longest_streak: streakData.longest_streak,
        completedDates: streakData.completed_dates || []
    };
};