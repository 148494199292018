import { IonFooter, IonButton, IonIcon, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonText } from "@ionic/react";
import { Situation } from "../../../types/ai_pivot/situationTypes";
import { useHistory } from "react-router";
import Picture from "../home/Picture";
import { closeCircleOutline, closeCircle, heartDislike, heartDislikeOutline, heartOutline, heart, chatbubbleEllipsesOutline, playCircleOutline, playOutline, play } from 'ionicons/icons';
import { useEffect, useState } from "react";
import { useAuth } from '../../../context/AuthContext';
import '../home/Card.css';
import './SituationOverviewCard.css';
import LikeDislikeComponent from '../home/LikeDislikeComponent';
import SituationOverviewBase from "./SituationBaseComponent";
import { DailyCall } from "@daily-co/daily-js";

const SituationOverviewCard: React.FC<{ 
    situation: Situation,
    isActive: boolean,
    updateCurrentlyActive: (sitId: Situation['id'] | null) => Promise<void>,
    callObject: DailyCall | null,
    feedback?: string | null
     }> = (
    { situation, isActive, updateCurrentlyActive, callObject, feedback }) => {
    return <SituationOverviewBase 
                situation={situation} 
                isActive={isActive} 
                updateCurrentlyActive={updateCurrentlyActive}
                callObject={callObject}
                feedback={feedback}
            />
}

export default SituationOverviewCard;