import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, useIonViewWillEnter, useIonViewDidEnter } from "@ionic/react";
import LibraryComponent from "../../components/ai_pivot/home/LibraryComponent";

import '../../theme/layout.css';
import HelpComponent from "../../components/ai_pivot/help/HelpComponent";
import React, { useEffect, useRef } from "react";
import { subscribe, unsubscribe } from "../../util/events";

const LibraryPage: React.FC = () => {
    const myRef = useRef<HTMLIonContentElement | null>(null);
    
    useEffect(() => {
        const scrollUp = () => {
            myRef?.current?.scrollToTop(500);
        }
        subscribe('scrollTop', scrollUp);

        return () => {
            unsubscribe('scrollTop', scrollUp)
        }
    }, []);

    return (
        <IonPage>
            <IonHeader mode="ios" collapse="fade">
                <IonToolbar>
                    <IonButtons slot="end">
                        <HelpComponent />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} ref={myRef}>
                <div className="content-block ion-padding">
                    <LibraryComponent />
                </div>
            </IonContent>
        </IonPage>
    );
}

export default LibraryPage;