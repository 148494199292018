import React from 'react';
import './CustomCalendar.css';
import { IonIcon } from '@ionic/react';
import { flame,snow } from 'ionicons/icons';
import frozen from './frozen.png';

interface CustomCalendarProps {
    currentMonth: Date;
    setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>;
    completedDates: string[];
}

const CustomCalendar: React.FC<CustomCalendarProps> = ({ currentMonth, setCurrentMonth, completedDates }) => {
    const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
    const firstDayIndex = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
    const today = new Date();
    // const today = new Date(2024, 7, 30); 
    const isCurrentMonth = today.getMonth() === currentMonth.getMonth() && today.getFullYear() === currentMonth.getFullYear();
    const todayDate = isCurrentMonth ? today.getDate() : undefined;

    const earliestCompletedDate = new Date(Math.min(...completedDates.map(date => new Date(date).getTime())));
    //console.log("Earliest Completed Date:", earliestCompletedDate.toISOString().split('T')[0]);
    const latestCompletedDate = new Date(Math.max(...completedDates.map(date => new Date(date).getTime())));

    const handlePrevMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
    };

    const missedDates = [];
    for (let d = new Date(earliestCompletedDate); d <= new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1); d.setDate(d.getDate() + 1)) {
        const dateStr = d.toISOString().split('T')[0];
        if (!completedDates.includes(dateStr)) {
            missedDates.push(dateStr);
        }
    }

    const isWithinStreakPeriod = (date: Date) => date >= earliestCompletedDate && date <= today;
    const isEarliestCompletedMonth = currentMonth.getMonth() === earliestCompletedDate.getMonth() && currentMonth.getFullYear() === earliestCompletedDate.getFullYear();
    let earliestWeekIdentified = false;

    const calendarRows: JSX.Element[] = [];
    let weekDays: JSX.Element[] = [];

    for (let day = 1 - firstDayIndex; day <= daysInMonth; day++) {
        const currentDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
        const dateStr = currentDay.toISOString().split('T')[0];
        const isCompleted = completedDates.includes(dateStr);
        const isMissed = missedDates.includes(dateStr);
        const isToday = todayDate === day;
        const isWithinStreak = isWithinStreakPeriod(currentDay);
        const isPartialWeekDay = (isEarliestCompletedMonth && !earliestWeekIdentified && isWithinStreak);
        // const isCurrentWeekDay = currentDay >= new Date(today.setDate(today.getDate() - today.getDay())) && currentDay <= new Date(today.setDate(today.getDate() + 6 - today.getDay()));

        // const dayClass = `${isCompleted ? 'completed' : ''} ${isMissed ? 'missed' : ''} ${isToday ? 'today' : ''} ${isPartialWeekDay ? 'partial-week-day' : ''} ${isCurrentWeekDay ? 'current-week-day' : ''}`;
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay()); // Start of the current week (Sunday)
        const isCurrentWeekDayBeforeToday = currentDay >= startOfWeek && currentDay < today;

        const dayClass = `${isCompleted ? 'completed' : ''} ${isMissed ? 'missed' : ''} ${isToday ? 'today' : ''} ${isPartialWeekDay ? 'partial-week-day' : ''} ${isCurrentWeekDayBeforeToday ? 'current-week-day' : ''}`;

        weekDays.push(
            <div key={day.toString()} className={`calendar-day ${dayClass}`}>
                {day > 0 && day <= daysInMonth && (
                    <div className="day-content">
                        <div className="day-number">{day}</div>
                        {/* {isCompleted && <IonIcon icon={flame} className="flame-icon" />} */}
                        {/* {isMissed && <IonIcon icon={snow} className="missed-icon" />} */}
                        {isMissed && <img src={frozen} alt="Missed" className="missed-icon" />}
                        {isToday && <div className="today-circle"></div>}
                    </div>
                )}
            </div>
        );

        if (weekDays.length === 7 || day === daysInMonth) {
            const weekContainsAnyDaysInStreak = weekDays.some(dayComponent => {
                const dayKey = dayComponent.key as string;
                return isWithinStreakPeriod(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), parseInt(dayKey)));
            });
            const weekCompletedDays = weekDays.filter(dayComponent => dayComponent.props.className.includes('completed')).length;

            const isCurrentWeek = weekDays.some(dayComponent => dayComponent.props.className.includes('today'));
            const isEarliestCompletedWeek = isEarliestCompletedMonth && !earliestWeekIdentified && weekDays.some(dayComponent => dayComponent.key === earliestCompletedDate.getDate().toString());

            let weekClass = weekContainsAnyDaysInStreak ? 'week-streak' : '';

            if (weekCompletedDays === 7) {
                weekClass = 'full-week-streak';
            }

            if (isCurrentWeek && weekContainsAnyDaysInStreak) {
                const partialStreakLength = weekDays.findIndex(dayComponent => dayComponent.props.className.includes('today'));
                const streakWidth = `calc(${(partialStreakLength * 100) / 7}% - 2px)`;

                calendarRows.push(
                    <div key={day.toString()} className="calendar-row">
                        <div
                            className="short-week-streak"
                            style={{ width: streakWidth }}
                        ></div>
                        {weekDays}
                    </div>
                );
            
            } else if (isEarliestCompletedWeek) {
                const startOfStreak = weekDays.findIndex(dayComponent => {
                    const dayKey = parseInt(dayComponent.key as string, 10);
                    return dayKey === earliestCompletedDate.getDate();
                });
            
                if (startOfStreak >= 0) {
                    const correctedStartOfStreak = startOfStreak + 1;
                    const streakWidth = `calc(${((7 - correctedStartOfStreak) * 100) / 7}% - 2px)`;
                    const streakLeft = `calc(${(correctedStartOfStreak * 100) / 7}%)`;
            
                    calendarRows.push(
                        <div key={day.toString()} className="calendar-row">
                            <div
                                className="short-week-streak"
                                style={{ width: streakWidth, left: streakLeft, position: 'absolute' }}
                            ></div>
                            {weekDays}
                        </div>
                    );
            
                    earliestWeekIdentified = true;
                }
            
            } else {
                calendarRows.push(
                    <div key={day.toString()} className={`calendar-row ${weekClass}`}>
                        {weekDays}
                    </div>
                );
            }

            weekDays = [];
        }
    }

    return (
        <div className="calendar-container-wrapper">
            <div className="calendar-label">Calendar</div>
                <div className="calendar-container">
                    <div className="calendar-header">
                    <button className="nav-button" onClick={handlePrevMonth}>{"<"}</button>
                        <div className="current-month">
                            {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
                        </div>
                        <button className="nav-button" onClick={handleNextMonth}>{">"}</button> 
                    </div>
                    <div className="calendar-grid">
                        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
                            <div key={index} className="calendar-day-header">{day}</div>
                        ))}
                    </div>
                    {calendarRows}
                </div>
        </div>
    );
};

export default CustomCalendar;