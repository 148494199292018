import { Skill, UserProgress } from "../../../types/ai_pivot/skillTypes";
import HorizontalLine from "./HorizontalLine";
import LessonComponent from "./lesson/LessonComponent";
import UnitCard from "./UnitCard";
import { useAuth } from "../../../context/AuthContext";

const SkillComponent: React.FC<{
    skill: Skill, userProgress: UserProgress[],
    updateUserProgressUI: (progress: UserProgress) => void
}> = ({ skill, userProgress, updateUserProgressUI }) => {
    const { user } = useAuth();
    const allowedUserIds = ["fcc30104-bc9f-455b-af04-53d575749184", "ivan"];
    const hiddenSkillIds = [3, 5];
    const userHasAccess = user && allowedUserIds.includes(user.id);
    const shouldHideSkill = hiddenSkillIds.includes(skill.skill_id);

    let sortedLessons = skill.lesson.sort((a, b) => a.lesson_number - b.lesson_number);
    
    if (shouldHideSkill && !userHasAccess) {
        sortedLessons = [];
    }

    const firstIncompleteLesson = sortedLessons.find((lesson) => {
        return !userProgress.find(
            (progress) =>
                progress.skill_id === skill.skill_id &&
                progress.lesson_id === lesson.lesson_id &&
                progress.completion_status
        );
    });
    
    return (
        <div style={{ justifyContent: 'center', marginTop: 100 }}>
            {sortedLessons.length > 0 && (
                <>
                    <div className="skill-header" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', marginBottom: 10, marginTop: skill.level === 1 ? 0 : 30 }}>
                        <HorizontalLine />
                        <h4 style={{ fontSize: '25', whiteSpace: 'nowrap', paddingLeft: 15, paddingRight: 15, color: 'var(--text-color-m)' }}>
                            {skill.skill_name}
                        </h4>
                        <HorizontalLine />
                    </div>
                    
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {sortedLessons.map((lesson, i) => (
                            <LessonComponent
                                key={lesson.lesson_id}
                                lesson={lesson}
                                updateUserProgressUI={updateUserProgressUI}
                                index={i}
                                total={sortedLessons.length}
                                isCompleted={userProgress.find(
                                    (value) =>
                                        value.skill_id === skill.skill_id &&
                                        value.lesson_id === lesson.lesson_id &&
                                        value.completion_status
                                ) ? true : false}
                                isLocked={
                                    !(lesson.lesson_number === 1 || 
                                        userProgress.find((value) => 
                                            value.skill_id === skill.skill_id && 
                                            value.lesson_id === sortedLessons[i - 1]?.lesson_id &&
                                            value.completion_status
                                        ) ? true : false)
                                }
                                firstIncompleteLessonId={firstIncompleteLesson ? firstIncompleteLesson.lesson_id : 0}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default SkillComponent;