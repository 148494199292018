// import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption } from "@ionic/react";
// import React, { useState } from "react";

// const BasicInfoSection: React.FC = () => {
//   const [name, setName] = useState("");
//   const [gender, setGender] = useState("");
//   const [age, setAge] = useState("");

//   return (
//     // <>
//     //   <IonItem>
//     //     <IonLabel position="floating">Name</IonLabel>
//     //     <IonInput value={name} onIonChange={e => setName(e.detail.value!)} clearInput />
//     //   </IonItem>
//     //   <IonItem>
//     //     <IonLabel position="floating">Gender</IonLabel>
//     //     <IonSelect value={gender} placeholder="Select One" onIonChange={e => setGender(e.detail.value)}>
//     //       <IonSelectOption value="female">Female</IonSelectOption>
//     //       <IonSelectOption value="male">Male</IonSelectOption>
//     //       <IonSelectOption value="non-binary">Non-Binary</IonSelectOption>
//     //     </IonSelect>
//     //   </IonItem>
//     //   <IonItem>
//     //     <IonLabel position="floating">Age</IonLabel>
//     //     <IonInput type="number" value={age} onIonChange={e => setAge(e.detail.value!)} clearInput />
//     //   </IonItem>
//     // </>
//     <IonCard>
//       <IonCardHeader>
//         <IonCardTitle>Basic Information</IonCardTitle>
//       </IonCardHeader>
//       <IonCardContent>
//       <IonItem>
//          <IonLabel position="floating">Name</IonLabel>
//          <IonInput value={name} onIonChange={e => setName(e.detail.value!)} clearInput />
//        </IonItem>
//        <IonItem>
//          <IonLabel position="floating">Gender</IonLabel>
//          <IonSelect value={gender} placeholder="Select One" onIonChange={e => setGender(e.detail.value)}>
//            <IonSelectOption value="female">Female</IonSelectOption>
//            <IonSelectOption value="male">Male</IonSelectOption>
//            <IonSelectOption value="non-binary">Non-Binary</IonSelectOption>
//          </IonSelect>
//        </IonItem>
//        <IonItem>
//          <IonLabel position="floating">Age</IonLabel>
//          <IonInput type="number" value={age} onIonChange={e => setAge(e.detail.value!)} clearInput />
//        </IonItem>
//       </IonCardContent>
//     </IonCard>
//   );
// };

// export default BasicInfoSection;


import React, { useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption } from "@ionic/react";

interface BasicInfoSectionProps {
    name: string;
    age: number | string;
    gender: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    setAge: React.Dispatch<React.SetStateAction<number | string>>;
    setGender: React.Dispatch<React.SetStateAction<string>>;
}

const BasicInfoSection: React.FC<BasicInfoSectionProps> = ({ name, age, gender, setName, setAge, setGender }) => {

  const handleGenderChange = (event: CustomEvent) => {
    setGender(event.detail.value);
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Basic Information</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonItem>
          <IonLabel position="floating">Name</IonLabel>
          <IonInput value={name} onIonChange={e => setName(e.detail.value!)} clearInput />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Age</IonLabel>
          <IonInput type="number" value={age} onIonChange={e => setAge(e.detail.value!)} clearInput />
        </IonItem>
        <IonItem>
          <IonLabel>Gender</IonLabel>
          <IonSelect value={gender} placeholder="Select Gender" onIonChange={handleGenderChange}>
            <IonSelectOption value="male">Male</IonSelectOption>
            <IonSelectOption value="female">Female</IonSelectOption>
            <IonSelectOption value="nonBinary">Non-Binary</IonSelectOption>
          </IonSelect>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};

export default BasicInfoSection;

