// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// interface Track {
//   type: string;
//   url: string;
//   mimeType: string;
//   duration: number;
// }

// interface Recording {
//   id: string;
//   room_name: string;
//   start_ts: number;
//   status: string;
//   max_participants: number;
//   duration: number;
//   tracks: Track[] | null;
// }

// const RecordingsList: React.FC = () => { 
//   const [recordings, setRecordings] = useState<Recording[]>([]);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     const getRecordings = async () => {
//       try {
//         const recordingsData = await fetchRecordings();
//         console.log(recordingsData)
//         setRecordings(recordingsData);
//       } catch (error) {
//         setError('Failed to fetch recordings');
//       }
//     };

//     getRecordings();
//   }, []);

//   return (
//     <div>
//       {recordings.map(recording => (
//         <div key={recording.id}>
//           <h3>{recording.room_name} - {recording.status}</h3>
//           <p>Max Participants: {recording.max_participants}</p>
//           {recording.tracks && recording.tracks.map((track, index) => (
//             track.type === 'audio' && (
//               <div key={index}>
//                 <audio controls>
//                   <source src={track.url} type={track.mimeType} />
//                   Your browser does not support the audio element.
//                 </audio>
//               </div>
//             )
//           ))}
//         </div>
//       ))}
//       {error && <p>{error}</p>}
//     </div>
//   );
// };
// export default RecordingsList;

// interface Recording {
//     id: string;
//     room_name: string;
//   }
  
//   const RecordingItem: React.FC<Recording> = ({ id, room_name }) => {
//     const [downloadLink, setDownloadLink] = useState<string | null>(null);
//     const [expires, setExpires] = useState<number | null>(null);
//     const [error, setError] = useState<string | null>(null);
  
//     const handleFetchLink = async () => {
//       try {
//         const data = await fetchAccessLink(id);
//         if (data.download_link) {
//           setDownloadLink(data.download_link);
//           setExpires(data.expires);
//         } else {
//           setError('Failed to obtain download link');
//         }
//       } catch (error) {
//         setError('Error fetching download link');
//       }
//     };
  
//     return (
//       <div>
//         <h3>{room_name}</h3>
//         <button onClick={handleFetchLink}>Get Access Link</button>
//         {downloadLink && <audio controls src={downloadLink}>
//           <source src={downloadLink} type="video/mp4" /> {/* Assuming .mp4 can contain audio */}
//           Your browser does not support the audio element.
//         </audio>}
//         {expires && <p>Link expires on: {new Date(expires * 1000).toLocaleString()}</p>}
//         {error && <p>{error}</p>}
//       </div>
//     );
//   };
  
// export default RecordingItem;

// const fetchRecordings = async () => {
//     const apiUrl = 'https://api.daily.co/v1/recordings';
//   try {
//     const response = await axios.get(apiUrl, {
//       headers: {
//         'Authorization': `Bearer ${"5df5f2d165658757f351217426af48a402f36ad3104455e1de3f35f3cf2a997f"}`
//       }
//     });
//     // Access the nested 'data' array properly
//     return response.data.data || []; // Ensuring we're accessing the correct 'data' level
//   } catch (error) {
//     console.error('Error fetching recordings:', error);
//     throw error; // Rethrow after logging or handle accordingly
//   }
// };

// const fetchAccessLink = async (recordingId: string, validForSecs?: number) => {
//     const apiUrl = `https://api.daily.co/v1/recordings/${recordingId}/access-link`;
//     try {
//       const response = await axios.get(apiUrl, {
//         headers: {
//           'Authorization': `Bearer ${"5df5f2d165658757f351217426af48a402f36ad3104455e1de3f35f3cf2a997f"}`,
//           'Content-Type': 'application/json'
//         },
//         params: {
//           ...(validForSecs ? { valid_for_secs: validForSecs } : {})  // Include valid_for_secs if provided
//         }
//       });
//       return response.data;
//     } catch (error) {
//       console.error('Error fetching access link:', error);
//       throw error;
//     }
//   };


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import RecordingItem from './RecordingItem';  // Adjust the import path as necessary

interface Recording {
    id: string;
    room_name: string;
    start_ts: number;
    status: string;
    max_participants: number;
    duration: number;
    tracks: Track[] | null;
}

interface Track {
  type: string;
  url: string;
  mimeType: string;
  duration: number;
}

const RecordingsList: React.FC = () => {
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRecordings = async () => {
      const apiUrl = 'https://api.daily.co/v1/recordings';
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${"5df5f2d165658757f351217426af48a402f36ad3104455e1de3f35f3cf2a997f"}`
          }
        });
        setRecordings(response.data.data || []); 
      } catch (error) {
        console.error('Error fetching recordings:', error);
        setError('Failed to fetch recordings');
      }
    };

    fetchRecordings();
  }, []);

  return (
    <div>
      {recordings.map(recording => (
        <RecordingItem key={recording.id} recordingId={recording.id}/>
      ))}
      {error && <p>{error}</p>}
    </div>
  );
};

export default RecordingsList;
