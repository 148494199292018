import React, { useState } from 'react';
import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonAlert, useIonToast } from '@ionic/react';
import { deleteAccount } from '../../service/authService';
import { useHistory } from 'react-router-dom';

const DeleteAccount: React.FC = () => {
  const [presentAlert] = useIonAlert();
  const [present] = useIonToast();
  const history = useHistory();

  const handleDeleteAccount = async () => {
    presentAlert({
      header: 'Confirm Deletion',
      message: 'Are you sure you want to delete your account? This action cannot be undone.',
      buttons: [
        'Cancel',
        {
          text: 'Delete',
          handler: async () => {
            const { error } = await deleteAccount();
            if (error) {
              present({
                message: `Failed to delete account: ${error.message}`,
                duration: 3000,
                position: 'bottom',
                color: 'danger'
              });
            } else {
              present({
                message: 'Account successfully deleted',
                duration: 3000,
                position: 'bottom',
                color: 'success'
              });
              history.push('/');
            }
          }
        }
      ]
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Delete Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h2>Delete Your Account</h2>
        <p>Warning: This action is irreversible. All your data will be permanently deleted.</p>
        <IonButton expand="block" color="danger" onClick={handleDeleteAccount}>
          Delete Account
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default DeleteAccount;