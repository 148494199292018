import { IonContent, IonPage } from "@ionic/react";
import RandomScenarioComponent from "../../components/ai_pivot/situation/random.tsx/RandomScenarioComponent";

const RandomScenarioPage: React.FC = () => {
    return (
            <IonPage>
                <IonContent fullscreen>
                    <div className="content-block ion-padding">
                        <RandomScenarioComponent />
                    </div>
                </IonContent>
            </IonPage>
        );
}

export default RandomScenarioPage;