
import {
    IonActionSheet,
    IonButton, IonButtons, IonContent, IonHeader, IonIcon,
    IonItem, IonLabel, IonList, IonModal,
    IonNote, IonPage, IonTextarea, IonTitle, IonText,
    IonToolbar,
    useIonActionSheet
} from '@ionic/react';
import { copyOutline, helpCircleOutline, helpOutline, helpSharp, logoDiscord, logoReddit } from 'ionicons/icons';
import { ellipsisVerticalCircle } from 'ionicons/icons';
import { useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import PlanUpgradeComponent from '../payment/PlanUpgradeComponent';
import { supabase } from '../../../service/authService';
import type { OverlayEventDetail } from '@ionic/core';
import { CONTACT_EMAIL, DISCORD_GROUP, REDDIT_GROUP } from '../../../util/constants';
import { Link } from 'react-router-dom';

const HelpComponent: React.FC = () => {
    const [show, setShow] = useState<boolean>(false);
    const { userProfile } = useAuth();
    const [isCopied, setIsCopied] = useState(false);

    const handleOpenSettings = () => {
        setShow(true);
    }

    const copyEmailToClipboard = async () => {
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(CONTACT_EMAIL);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);

        }
    };

    const iconStyle = {
        fontSize: '20px',
        animation: isCopied ? 'copyAnimation 1s ease' : 'none'
    };

    return (
        <>
            <style>
                {`
                    @keyframes copyAnimation {
                        0% { transform: scale(1); }
                        50% { transform: scale(1.25); }
                        100% { transform: scale(1); }
                    }
                `}
            </style>
            {/* <div className='global-btn'> */}
            <IonButton onClick={handleOpenSettings} size='small' shape='round' fill='clear'>
                <IonIcon icon={helpCircleOutline} />
            </IonButton>
            {/* </div> */}
            <IonModal isOpen={show} canDismiss={true} onWillDismiss={() => setShow(false)}>
                <IonPage>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="end">
                                <IonButton onClick={() => setShow(false)}>Close</IonButton>
                            </IonButtons>
                            <IonTitle>Support</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen={true} color="light">
                        <IonList inset={true}>
                            <IonItem>
                                <IonLabel>The best way to reach us for any issues is on <Link to={{ pathname: DISCORD_GROUP }} target="_blank">Discord</Link></IonLabel>
                                <IonIcon slot="end" icon={logoDiscord}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel>You can also do it on <Link to={{ pathname: REDDIT_GROUP }} target="_blank">Reddit</Link></IonLabel>
                                <IonIcon slot="end" icon={logoReddit}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Or contact us: {CONTACT_EMAIL}</IonLabel>
                                <IonText onClick={copyEmailToClipboard}>
                                    <IonIcon icon={copyOutline} style={iconStyle} />
                                </IonText>
                            </IonItem>
                        </IonList>

                        {/* <IonList inset={true}>
                            <IonItem>
                                <IonTextarea label="Comments" label-placement="floating" rows={5}></IonTextarea>
                            </IonItem>
                        </IonList>

                        <IonNote color="medium" class="ion-margin-horizontal">
                            Your comments will be kept anonymous and will only be used to improve the reliability of our products.
                        </IonNote> */}

                    </IonContent>
                </IonPage>
            </IonModal>
        </>
    );
}

export default HelpComponent;