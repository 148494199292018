import { IonCard, IonButton, IonCardHeader, IonCardTitle, IonCardContent, IonText, IonCardSubtitle } from "@ionic/react";
import Feedback from "../home/Feedback";
import { FEEDBACK_SUMMARY_LOADING } from "../../../util/constants";
import { useState } from "react";
import AudioPlayer from 'react-h5-audio-player';
import './MyAudioCard.css';
import { useHistory } from "react-router-dom";
import '../../../theme/DuoLinguo.css';
import ChatMessage from './conversation_chat/ChatMessage';
import './SitFeedback.css';
import { ContentItem, Message } from "../../../types/ai_pivot/situationTypes";

const SitFeedback: React.FC<{
    feedback: string, 
    title?: string, 
    subtitle?: string, 
    audio_link?: string, 
    situationId?: number, 
    showFeedbackButton?: boolean,
    conversationHistory?: Message[]
}> = ({feedback, title, subtitle, audio_link, situationId, showFeedbackButton, conversationHistory }) => {
    const [expandFeedback, setExpandFeedback] = useState(false);
    const [showConversation, setShowConversation] = useState(false);
    const history = useHistory();
  
    const handleSeeFeedback = () => {
        history.push(`/feedback/${situationId}`);
    };

    const toggleConversation = () => {
        setShowConversation(!showConversation);
    };
      
return (
    <IonCard mode="ios" className="custom-ion-card">
        <IonCardHeader>
            {subtitle && <IonCardSubtitle style={{marginTop:10}}>{subtitle}</IonCardSubtitle>}
            <IonCardTitle>{title ? title : 'Summary'}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
            {feedback === null ? '' : feedback === FEEDBACK_SUMMARY_LOADING
                ? <Feedback feedback={feedback} />
                : <>  
                    <Feedback feedback={expandFeedback ? feedback : feedback.slice(0, 300) + '...'} />
                    <IonText color="primary">
                        <div onClick={() => feedback !== FEEDBACK_SUMMARY_LOADING 
                            && setExpandFeedback(!expandFeedback)}>{expandFeedback ? 'Collapse' : 'Expand'}</div>
                    </IonText>
                  </>
            }
           
            {conversationHistory && conversationHistory.length > 0 && (
                <div className="conversation-section">
                    <IonButton expand="block" onClick={toggleConversation}>
                        {showConversation ? 'Hide Conversation' : 'Show Conversation'}
                    </IonButton>
                    {showConversation && (
                        <div className="chat-container">
                            {conversationHistory.map((message, index) => (
                                <ChatMessage
                                    key={index}
                                    content={message.content}
                                    isUser={message.role === 'user'}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
           
            {showFeedbackButton && (
                <IonButton expand="block" onClick={handleSeeFeedback} style={{marginTop: '15px'}}>
                    Feedback from other users
                </IonButton>
            )}
        </IonCardContent>
    </IonCard>
);
}

export default SitFeedback;