import React, { useEffect, useState } from 'react';
import { IonModal, IonButton, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import CustomCalendar from './CustomCalendar'; 
import {StreakData  } from "../../../types/ai_pivot/skillTypes";
import './StreakModal.css';
import { flame,snow } from 'ionicons/icons';

interface StreakModalProps {
    isOpen: boolean;
    onClose: () => void;
    streakData: StreakData;
}


const StreakModal: React.FC<StreakModalProps> = ({ isOpen, onClose, streakData }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onClose} className="custom-modal" mode="ios">
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={onClose}>
                            <IonIcon icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-paddingg">
                <div className="streak-header">
                    <h2>{streakData.current_streak} Day Streak</h2>
                    <IonIcon icon={flame} className="flame-icon" />
                </div>
                <div className="streak-prompt">
                    <p>Do a lesson today to start a new streak!</p>
                    <IonButton expand="block" color="warning" onClick={onClose}>
                    <div className="prompt-butt">
                        Start a Streak
                        </div>    
                    </IonButton>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <CustomCalendar 
                        currentMonth={currentMonth} 
                        setCurrentMonth={setCurrentMonth}
                        completedDates={streakData.completedDates} 
                        // missedDates={missedDates}
                    />
                </div>
                {/* <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <IonButton onClick={handlePrevMonth}>Previous Month</IonButton>
                    <IonButton onClick={handleNextMonth}>Next Month</IonButton>
                </div> */}
            </IonContent>
        </IonModal>
    );
};

export default StreakModal;