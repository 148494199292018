import React, { useEffect, useState } from 'react';
import axios from 'axios';
import RecordingItem from './RecordingItem';
import { supabase } from "../../../service/authService";

interface Recording {
    id: string;
    room_name: string;
    start_ts: number;
    status: string;
    max_participants: number;
    duration: number;
  }

const RecordingAudio: React.FC<{ roomName: string, InstanceId:number }> = ({ roomName, InstanceId }) => {
    const [recordings, setRecordings] = useState<Recording[]>([]);
    const [error, setError] = useState('');

    const updateRecordingId = async (recordingId: string) => {
      try {
        const { data, error } = await supabase
          .from('situation_instance')
          .update({ recording_id: recordingId })
          .eq('id', InstanceId);
        if (error) {
          throw error;
        }
        console.log('Recording ID updated:', data);
      } catch (error) {
        console.error('Error updating recording ID in Supabase:', error);
      }
    };

    useEffect(() => {
      const fetchRecordings = async () => {
        const apiUrl = 'https://api.daily.co/v1/recordings';
        try {
          const response = await axios.get(apiUrl, {
            headers: {
              'Authorization': `Bearer ${"5df5f2d165658757f351217426af48a402f36ad3104455e1de3f35f3cf2a997f"}`
            }
          });
          const filteredRecordings = response.data.data.filter((rec: Recording) => rec.room_name === roomName);
          setRecordings(filteredRecordings);

          if (filteredRecordings.length > 0) {
            updateRecordingId(filteredRecordings[0].id); 
          }
        } catch (error) {
          console.error('Error fetching recordings:', error);
          setError('Failed to fetch recordings');
        }
      };
  
      fetchRecordings();
    }, [roomName]);
  
    return (
      <div>
        {recordings.map(recording => (
          <RecordingItem key={recording.id} recordingId={recording.id}/>
        ))}
        {error && <p>{error}</p>}
      </div>
    );
  };
  
  export default RecordingAudio;
