// AuthContext.tsx
import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { supabase } from '../service/authService';
import { PostgrestError, Session, User } from '@supabase/supabase-js';
import { getLocalTimeZone } from '../util/timeUtils';
import { PublicUser } from '../types/userTypes';
import { Capacitor } from '@capacitor/core';
import { App as CapApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser'

interface AuthContextType {
  isAuthenticated: boolean | null;
  session: Session | null;
  user: User | null;
  phoneVerified: boolean | null;
  userProfile: PublicUser | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [phoneVerified, setPhoneVerified] = useState<boolean | null>(null);
  const [userProfile, setUserProfile] = useState<PublicUser | null>(null);

  useEffect(() => {
    const createAuthContext = (session: Session | null) => {
      console.log(session);
      setSession(session)
      setIsAuthenticated(session ? true : false)
      setUser(session?.user ?? null);
      setPhoneVerified(session?.user?.phone_confirmed_at ? true : false)
    }

    supabase.auth.getSession().then(({ data: { session } }) => {
      createAuthContext(session);
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      createAuthContext(session);
    })

    if (Capacitor.isNativePlatform()) {
      CapApp.addListener('appUrlOpen', handleAppUrlOpen);
    }

    // return () => subscription.unsubscribe();
    return () => {
      subscription.unsubscribe();
      if (Capacitor.isNativePlatform()) {
        CapApp.removeAllListeners();
      }
    };
  }, []);

  const handleAppUrlOpen = async ({ url }: { url: string }) => {
    if (url.includes('login-callback')) {
      await Browser.close();

      const params = new URLSearchParams(url.split('#')[1]);
      const access_token = params.get('access_token');
      const refresh_token = params.get('refresh_token');

      if (access_token && refresh_token) {
        const { data, error } = await supabase.auth.setSession({
          access_token,
          refresh_token,
        });

        if (error) {
          console.error('Error setting session:', error);
        } else {
          console.log('User signed in:', data.user);
          // The session will be updated automatically through the onAuthStateChange listener
        }
      }
    }
  };
  
  useEffect(() => {
    // get user profile
    if (user && !userProfile) {
      supabase
        .from('users')
        .select('userid, name, age, description, dateprice, usertype, email, user_availability_status, tz, balance, stripe_id, subscription_type, sub_curr_period_start, sub_curr_period_end, reddit_username, username, is_info_collected ')
        .eq('userid', user.id)
        .single()
        .then(({ data, error }: { data: PublicUser | null; error: PostgrestError | null }) => {
          if (error) {
            console.error('AuthContext: Error retrieving user profile: ', JSON.stringify(error));
          } else if (data) {
            setUserProfile(data);

            // check to see if need to update user timezone
            if (data.tz !== getLocalTimeZone()) {
              supabase
                .from('users').update({ tz: getLocalTimeZone() }).eq('userid', user.id).then(({ error }) => {
                  if (error) {
                    console.error(JSON.stringify(error));
                  } else {
                    console.log('updated user timezone.');
                  }
                })
            }

            supabase
              .from('users').update({ last_online: new Date() }).eq('userid', user.id).then(({ error }) => {
                if (error) {
                  console.error(JSON.stringify(error));
                } else {
                  console.log('updated user last login.');
                }
              })
          }
        })

      // setup listener
      const channel = supabase
        .channel('schema-db-changes')
        .on(
          'postgres_changes',
          {
            event: 'UPDATE',
            schema: 'public',
            table: 'users',
            filter: `userid=eq.${user.id}`
          },
          (payload) => {
            setUserProfile(
              {
                userid: payload.new.userid,
                name: payload.new.name,
                age: payload.new.age,
                description: payload.new.description,
                dateprice: payload.new.dateprice,
                usertype: payload.new.usertype,
                email: payload.new.email,
                user_availability_status: payload.new.user_availability_status,
                tz: payload.new.tz,
                balance: payload.new.balance,
                stripe_id: payload.new.stripe_id,
                subscription_type: payload.new.subscription_type,
                sub_curr_period_start: payload.new.sub_curr_period_start,
                sub_curr_period_end: payload.new.sub_curr_period_end,
                reddit_username: payload.new.reddit_username,
                username: payload.new.username,
                is_info_collected: payload.new.is_info_collected
              }
            )
          }
        ).subscribe();

      return () => {
        channel.unsubscribe();
      }
    }
  }, [user])

  return (
    <AuthContext.Provider value={{ isAuthenticated, session, user, phoneVerified, userProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
