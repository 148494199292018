import { IonLabel, IonNav, IonSegment, IonSegmentButton } from "@ionic/react";
import { IonIcon } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';
import { useState } from "react";
import UserSituations from "../situation/UserSituations";
import HistoryComponent from "./HistoryComponent";
import LikedSituations from "../situation/LikedSituations";

import './ProfileComponent.css';
import { useAuth } from "../../../context/AuthContext";


const ProfileComponent: React.FC = () => {
    const [currVal, setCurrVal] = useState("history");
    const { userProfile } = useAuth();

    return (
        <div>
            <br />
            <br />
            <br />
            <div className="general-info-container">
                <IonIcon icon={personCircleOutline} style={{fontSize: '80px'}} />
                <div style={{padding: '5px', marginBottom: 20, fontSize: 20}}>@{userProfile?.username}</div>
                {/* <div>0 followers • 0 following</div> */}
            </div>
            <div style={{maxWidth: '380px', margin: 'auto', width: '80%'}}>
                <IonSegment color="dark" value={currVal} mode="ios" onIonChange={(e) => {
                    if (e.detail.value) {
                        setCurrVal(e.detail.value as string)
                    }
                }}>
                    <IonSegmentButton value="history">
                        <IonLabel>History</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="scenarios">
                        <IonLabel>Scenarios</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="liked">
                        <IonLabel>Liked</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </div>
            {/* <br /> */}
            {
                currVal === "scenarios" && <UserSituations />
            }
            {
                currVal === "liked" && <LikedSituations />
            }
            {
                currVal === "history" && <HistoryComponent />
            }
        </div>
    );
}

export default ProfileComponent;