import { ToastOptions } from "@ionic/react";
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";

export const displayErrorToUser = ({present, error, header, message, position} : {
    present: (options: ToastOptions & HookOverlayOptions) => Promise<void>, 
    error: any,
    header?: string, 
    message?: string,
    position?: 'top' | 'middle' | 'bottom'}) => {

    present({
        header: header ? header : 'Error has occured',
        message: `${message ? message : error.message}`,
        position: position ? position : 'middle',
        duration: 2000
    });
}