import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Loading from "./generic/Loading";

const ProtectedRoute: React.FC<{ path: string; component: React.ComponentType<any>; exact: boolean }> = ({
  component: Component,
  ...rest
}) => {
  const { isAuthenticated } = useAuth();

  return <Route {...rest}
    render={(props) => (
      isAuthenticated ?
        <Component {...props} />
        :
        isAuthenticated === null ?
          <div /> // loading
          :
          <Redirect to={{ pathname: '/signin_', state: { from: props.location } }} />
    )} />;
};

export default ProtectedRoute;

