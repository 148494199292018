import { IonButton } from "@ionic/react";
import { useRef, useEffect } from "react";
import './SituationCategorySelector.css';
import { Situation } from "../../../types/ai_pivot/situationTypes";

const categories: (Situation['category'] | 'For you')[] = ["For you", "Dating", "Therapy", "Small talk", "Philosophy", "Career"];

const SituationCategorySelector: React.FC<{category: Situation["category"] | 'For you', selectCategory: (c: Situation['category'] | 'For you') => void}>
     = ({category, selectCategory}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollToCenter = (element: HTMLButtonElement) => {
        const container = containerRef.current;
        if (container) {
            const containerRect = container.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();

            
            const offset = elementRect.left + elementRect.width / 2 - containerRect.left - containerRect.width / 2;
            container.scrollBy({
                left: offset,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        const selectedButton = containerRef.current?.querySelector('.selected') as HTMLButtonElement;
        if (selectedButton) {
            scrollToCenter(selectedButton);
        }
    }, [category]);

    return (
        <div className="categories-container" ref={containerRef}>
            {categories.map((c) => 
                <IonButton 
                    key={c || undefined}
                    mode="ios" 
                    className={`category-btn ${c === category ? "selected" : "not-selected"}`}
                    onClick={() => selectCategory(c)}
                    fill={c === category ? "solid" : "outline"}
                >{c}</IonButton>)}
        </div>
    );
}

export default SituationCategorySelector;