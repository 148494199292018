class AudioManager {
    private static instance: AudioManager;
    private currentAudio: HTMLAudioElement | null = null; 
    private preloadedAudios: { [key: string]: HTMLAudioElement } = {};

    private constructor() {}

    static getInstance(): AudioManager {
        if (!AudioManager.instance) {
            AudioManager.instance = new AudioManager();
        }
        return AudioManager.instance;
    }

    // preloadAudio(url: string) {
    //     if (!this.preloadedAudios[url]) {
    //         const audio = new Audio(url);
    //         this.preloadedAudios[url] = audio;
    //     }
    // }
    // preloadAudio(url: string, retries = 2): Promise<void> {
    //     return new Promise((resolve, reject) => {
    //         const attemptLoad = (retryCount: number) => {
    //             const audio = new Audio(url);
    //             audio.preload = 'auto';
    //             audio.oncanplaythrough = () => resolve();
    //             audio.onerror = () => {
    //                 if (retryCount > 0) {
    //                     console.log("Retry Audio")
    //                     setTimeout(() => attemptLoad(retryCount - 1), 1000); // Retry after a delay
    //                 } else {
    //                     console.error(`Failed to preload audio: ${url}`);
    //                     reject();
    //                 }
    //             };
    //         };

    //         attemptLoad(retries);
    //     });
    // }
    preloadAudio(url: string, retries = 2): Promise<void> {
        return new Promise((resolve, reject) => {
            const attemptLoad = (retryCount: number) => {
                const audio = new Audio(url);
                audio.preload = 'auto';
                audio.oncanplaythrough = () => {
                    this.preloadedAudios[url] = audio; // Store preloaded audio
                    resolve();
                };
                audio.onerror = () => {
                    if (retryCount > 0) {
                        console.log("Retry Audio");
                        setTimeout(() => attemptLoad(retryCount - 1), 1000); // Retry after a delay
                    } else {
                        console.error(`Failed to preload audio: ${url}`);
                        reject();
                    }
                };
                audio.load(); 
            };

            attemptLoad(retries);
        });
    }

    playAudio(url: string, onEndCallback?: () => void) {
        if (this.currentAudio) {
            this.currentAudio.pause();
            this.currentAudio.currentTime = 0;
        }

        // this.currentAudio = new Audio(url);
        this.currentAudio = this.preloadedAudios[url] || new Audio(url);
        this.currentAudio.play();

        if (onEndCallback) {
            this.currentAudio.onended = onEndCallback;
        }
    }

    stopCurrentAudio() {
        if (this.currentAudio) {
            this.currentAudio.pause();
            this.currentAudio.currentTime = 0;
            this.currentAudio = null;
        }
    }

    pauseCurrentAudio() {
        if (this.currentAudio) {
            this.currentAudio.pause();
        }
    }

    resumeCurrentAudio() {
        if (this.currentAudio) {
            this.currentAudio.play();
        }
    }

    getPreloadedAudio(url: string): HTMLAudioElement | undefined {
        return this.preloadedAudios[url];
    }

    toggleAudioPlayback(url: string, onEndCallback?: () => void) {
        if (this.currentAudio && this.currentAudio.src === url && !this.currentAudio.paused) {
           
            this.pauseCurrentAudio();
        } else {
            if (this.currentAudio && this.currentAudio.src !== url) {
                
                this.stopCurrentAudio();
            }

            if (!this.currentAudio || this.currentAudio.src !== url) {
                
                this.currentAudio = new Audio(url);
                if (onEndCallback) {
                    this.currentAudio.onended = onEndCallback;
                }
            }

            this.currentAudio.play();
        }
    }
}

export default AudioManager.getInstance();