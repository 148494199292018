
import {
    IonActionSheet,
    IonButton, IonButtons, IonContent, IonHeader, IonIcon,
    IonItem, IonLabel, IonList, IonModal, IonPage, IonTitle,
    IonToolbar,
} from '@ionic/react';
import './SettingsComponent.css';
import { settingsOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import PlanUpgradeComponent from '../payment/PlanUpgradeComponent';
import { supabase } from '../../../service/authService';
import type { OverlayEventDetail } from '@ionic/core';

const SettingsComponent: React.FC = () => {
    const [show, setShow] = useState<boolean>(false);
    const [showPurchaseOptions, setShowPurchaseOptions] = useState<boolean>(false);
    const { userProfile } = useAuth();
    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleOpenSettings = () => {
        setShow(true);
    }

    const handlePurchase = () => {
        // setShowPurchaseOptions(true);
    }

    const handleCancel = async () => {
        if (userProfile?.stripe_id) {
            const { data, error } = await supabase.functions.invoke('stripeCancelSub', {
                body: {
                    customerId: userProfile.stripe_id
                }
            });

            if (error) {
                console.error('error canceling subscription, ', JSON.stringify(error));
                alert('Error happened. Please try again later or contact us to process cancellation manually');
            } else if (data) {
                if (!data.success) {
                    alert(data.message);
                }
                console.log(data);
            }
        }
    }

    const processDismissResult = (result: OverlayEventDetail) => {
        setConfirmOpen(false);
        if (result.role === 'destructive') {
            handleCancel();
        } else {
            return;
        }
    }

    const handleUpgrade = () => { }

    const signOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.error(JSON.stringify(error));
        }
    }

    return (
        <>
            <style>
                {`
                    @keyframes copyAnimation {
                        0% { transform: scale(1); }
                        50% { transform: scale(1.25); }
                        100% { transform: scale(1); }
                    }
                `}
            </style>
            <PlanUpgradeComponent show={showPurchaseOptions} close={() => setShowPurchaseOptions(false)} />
            {/* <div className='global-btn'> */}
            <IonButton onClick={handleOpenSettings} size='small' shape='round' fill='clear'>
                <IonIcon icon={settingsOutline} style={{ fontSize: '25px' }} />
            </IonButton>
            {/* </div> */}
            <IonModal isOpen={show} canDismiss={true} onWillDismiss={() => setShow(false)}>
                <IonPage>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="end">
                                <IonButton onClick={() => setShow(false)}>Close</IonButton>
                            </IonButtons>
                            <IonTitle>Settings</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen={true} color="light">
                        {userProfile &&
                            <IonList inset={true}>
                                {/* <IonItem>
                                    <IonLabel>Name: {userProfile?.name} </IonLabel>
                                </IonItem> */}
                                <IonItem>
                                    <IonLabel>Username: {userProfile?.username} </IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Reddit username: {userProfile?.reddit_username} </IonLabel>
                                </IonItem>
                            </IonList>
                        }


                        {/* {userProfile && <IonList inset={true}> */}
                            {/* <IonItem>
                                <IonLabel>Balance: {userProfile?.balance} sessions</IonLabel>
                            </IonItem> */}
                            {/* <IonItem> */}
                                {/* <IonLabel>Subscription Plan: {userProfile?.subscription_type === 'basic_monthly' ? '30 sessions per month, 19.99$ per month' : 'Free'}</IonLabel> */}
                                {/* {
                                    userProfile?.subscription_type === 'basic_monthly' 
                                        ? 
                                    <IonButton id="cancel-button" color="danger" onClick={() => setConfirmOpen(true)}>Cancel</IonButton> 
                                        : 
                                    <IonButton color="primary" onClick={handlePurchase}>Upgrade</IonButton>
                                } */}
                            {/* </IonItem> */}
                            {/* {
                                userProfile?.subscription_type === 'basic_monthly' && 
                                <IonItem>
                                    <IonLabel>Renews: {new Date(userProfile?.sub_curr_period_end).toDateString()}</IonLabel>
                                </IonItem>
                            } */}

                            {/* <IonButton color="danger">Cancel</IonButton>
                            <IonButton color="primary">Upgrade</IonButton>
                            <IonButton color="success" onClick={handlePurchase}>Purchase</IonButton> */}
                        {/* </IonList>
                        } */}

                        {userProfile &&
                            <div className='ion-padding'>
                                {/* <IonList inset={true}> */}
                                    <IonButton expand='block' onClick={signOut}>Sign out</IonButton>
                                {/* </IonList> */}
                                <IonButton expand='block' routerLink="/delete-account" color="danger">Delete Account</IonButton>
                                    
                            </div>
                        }
                        {userProfile &&
                            <div className='ion-padding'>
                                    
                            </div>
                        }
                    </IonContent>
                </IonPage>
            </IonModal>
            <IonActionSheet
                isOpen={confirmOpen}
                onDidDismiss={({ detail }) => processDismissResult(detail)}
                header="Please, confirm cancelation"
                buttons={[
                    {
                        text: 'Confirm',
                        role: 'destructive',
                        data: {
                            action: 'Cancel',
                        },
                    },
                    {
                        text: 'Changed my mind',
                        role: 'cancel',
                        data: {
                            action: 'cancel',
                        },
                    },
                ]}
            ></IonActionSheet>
        </>
    );
}

export default SettingsComponent;