import { IonItem, IonLabel, IonButton, IonIcon, IonToggle, IonRadio, IonCheckbox } from '@ionic/react';
import { Voice } from '../../../../types/voiceTypes';
import { useEffect, useState } from 'react';
import { play, stop } from 'ionicons/icons';

const VoiceComponent: React.FC<{
    voice: Voice;
    isSelected: boolean;
    onSelectVoice: (voice: { voice_id: string; preview_url: string, name: string }) => void;
    playVoice: (v: string | undefined) => void;
    isPlaying: boolean;
}
> = ({ voice, isSelected, onSelectVoice, playVoice, isPlaying }) => {
    const [audio, setAudio] = useState<HTMLAudioElement>(new Audio(voice.preview_url));

    useEffect(() => {
        if (isPlaying) {
            audio.play();
        } else {
            audio.pause();
            audio.currentTime = 0;
        }
    }, [isPlaying]);

    const handleAudioEnd = () => {
        playVoice(undefined);
    };

    useEffect(() => {
        audio.addEventListener('ended', handleAudioEnd);

        return () => {
            audio.removeEventListener('ended', handleAudioEnd);
        };
    }, [audio]);

    return (
        <IonItem key={voice.voice_id} style={{ padding: 5 }}>
            <IonLabel onClick={() => {
                if (isPlaying) {
                    handleAudioEnd();
                } else {
                    playVoice(voice.voice_id);
                }
            }}>
                <IonIcon style={{
                    color: 'var(--text-btn-dark-gray)',
                }}
                    icon={isPlaying ? stop : play}></IonIcon>
                <IonLabel>
                    <h4 style={{ wordWrap: "break-word", whiteSpace: 'normal' }}>{voice.name}</h4>
                    <p style={{ wordWrap: "break-word", whiteSpace: 'normal' }}>{voice.description}</p>
                </IonLabel>
            </IonLabel>
            {
                !isSelected && 
                    <IonButton onClick={() => onSelectVoice(voice)}>
                        Select
                    </IonButton> 
            }
        </IonItem>
    );
}


export default VoiceComponent;