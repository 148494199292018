import { IonText } from '@ionic/react';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import './Feedback.css';

interface FeedbackSection {
    title: string;
    content: string;
}


const Feedback: React.FC<{ feedback: string }> = ({ feedback }) => {
    return (
        <IonText color="dark" className='feedback-content'>
            <ReactMarkdown>{feedback}</ReactMarkdown>
        </IonText>
    );
};

export default Feedback;