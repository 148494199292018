import {
    IonButton, IonButtons, IonContent, IonHeader,
    IonItem, IonLabel, IonList, IonModal,
    IonPage, IonText, IonTitle, IonToolbar
} from "@ionic/react";
import { useAuth } from "../../../context/AuthContext";
import { supabase } from "../../../service/authService";

const PlanUpgradeComponent: React.FC<{ show: boolean, close: () => void }> = (
    { show, close }
) => {
    const { userProfile } = useAuth();

    const handlePurchase = async () => {
        if (userProfile) {
            let stripe_id = userProfile.stripe_id;
            if (!stripe_id) {
                console.log('here');
                const { data, error } = await supabase.rpc('create_or_get_stripe_customer_id', {});
                console.log(data);
                if (error) {
                    console.error('error when calling create_or_get_stripe_customer_id', error);
                    alert('Error initiating purchase. Developers are notified of the issue');
                    return;
                } else if (data) {
                    console.log(data);
                    stripe_id = data;
                }
            }

            if (stripe_id) {
                console.log(stripe_id);
                const payload = {
                    customerid: stripe_id,
                    successUrl: window.location.href,
                    cancelUrl: window.location.href
                }
                const { data, error } = await supabase.functions.invoke('createCheckoutSessionStripe', {
                    body: payload
                });

                if (error) {
                    console.error('Error invoking createCheckoutSessionStripe', error);
                    alert('Error creating checkout. developers are notified. Please, contact support');
                } else if (data) {
                    window.location.replace(data.sessionLinkUrl);
                }
            } else {
                console.error('issue with stripe id');
            }
        }
    }

    return (
        <IonModal isOpen={show} canDismiss={true} onWillDismiss={() => close()}>
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton onClick={() => close()}>Close</IonButton>
                        </IonButtons>
                        <IonTitle>Plan selection</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen={true} color="light">

                    <IonList inset={true}>
                        {
                            userProfile &&
                            <IonItem 
                                // onClick={handlePurchase} 
                                // disabled={userProfile.subscription_type === 'basic_monthly'}
                                >
                                <IonLabel>
                                    <IonText color="primary">
                                        <h1>Basic plan</h1>
                                    </IonText>
                                    <h4>19.99$ per month = 30 sessions per month</h4>
                                </IonLabel>
                            </IonItem>
                        }
                    </IonList>

                </IonContent>
            </IonPage>
        </IonModal>
    );
}

export default PlanUpgradeComponent;