import React, { useState, useEffect } from 'react';
import {
    IonPage, IonHeader, IonToolbar,
    IonTitle, IonContent, IonButton,
    IonButtons, IonList, IonRadioGroup
} from '@ionic/react';
import { fetchVoices } from '../../../service/voiceService';
import VoiceComponent from '../situation/edit/VoiceComponent';
import { Voice } from '../../../types/voiceTypes';

interface VoicesListProps {
    onSelectVoice: (voice: { voice_id: string; preview_url: string, name: string }) => void;
    currentlySelected: string;
    dismiss: () => void;
    voices: Voice[];
}


const VoicesList: React.FC<VoicesListProps> = ({ onSelectVoice, currentlySelected, dismiss, voices }) => {
    const [voicePlaying, setVoicePlaying] = useState<string>();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => dismiss()}>
                            Cancel
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Available Voices</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className='ion-padding' color="light">
                <IonList inset={true}>
                    {voices.sort((voice) => {
                        return voice.voice_id === currentlySelected ? -1 : 1;
                    }).map(voice => (
                        <VoiceComponent
                            key={voice.voice_id}
                            voice={voice}
                            isSelected={voice.voice_id === currentlySelected}
                            onSelectVoice={onSelectVoice}
                            isPlaying={voicePlaying === voice.voice_id}
                            playVoice={(v: string | undefined) => {
                                setVoicePlaying(v);
                            }}
                        />
                    ))}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default VoicesList;
