import { PostgrestError } from "@supabase/supabase-js";
import { supabase } from "./authService";
import { Situation, SituationInstance } from "../types/ai_pivot/situationTypes";
import { LAST_X_SESSION_SUMMARY_CONTEXT, SUMMARY_HISTORY_PAGE_SIZE } from "../util/constants";
// TODO: do not forget when locking down db
export const createSituationInstance = async (situation_id: number) => {
    const { data, error }: 
        { data: SituationInstance | null; error: PostgrestError | null} = await supabase
        .rpc('create_si', {
            _situation_id: situation_id
        }).single();
    
    return { data, error };
}

export const stopSituationInstance = async (situation_id: number) => {
    const { error } = await supabase
        .from('situation_instance')
        .update({
            end_time: new Date()
        })
        .eq('id', situation_id);
    
    return { error };
}

export const saveMessage = async (situation_instance_id: number, _message: string, _msg_from: 'ai' | 'human') => {
    const { error } = await supabase
        .rpc('append_message', {
            _si_id: situation_instance_id, 
            _message, 
            _msg_from, 
            _timestamp: new Date()
        });

    return { error };
}

export const addFeedback = async (situation_instance_id: number, feedback: string) => {
    const { error } = await supabase
        .from('situation_instance_feedback')
        .insert({si_id: situation_instance_id, feedback_as_text: feedback});

    return { error };
}

export const getSituations = async (category: Situation['category'] | 'For you') => {

    if (category === 'For you') {
        const { data, error }: { data: Situation[] | null, error: PostgrestError | null} = await supabase
        .from('situation')
        .select('*')
        .eq('public', true)
        .order('created_at', { ascending: false });

        return { data, error };
    } else {
        const { data, error }: { data: Situation[] | null, error: PostgrestError | null} = await supabase
        .from('situation')
        .select('*')
        .eq('public', true)
        .eq('category', category)
        .order('created_at', { ascending: false });

        return { data, error };
    }
}

export const getUserSituations = async (userId:string) => {
    const { data, error }: { data: Situation[] | null, error: PostgrestError | null} = await supabase
        .from('situation')
        .select('*')
        .eq('author_id', userId).order('updated_at', {ascending: false});

    return { data, error };
}

export const getLikedSituations = async (userId:string) => {
    const { data, error }: { data: Situation[] | null, error: PostgrestError | null} = await supabase
        .rpc('get_liked_situations')

    return { data, error };
}

export const getSituation = async (id: string) => {
    const { data, error }: { data: Situation | null, error: PostgrestError | null} = await supabase
        .from('situation')
        .select('*')
        .eq('id', id)
        .single();

    return { data, error };
}

export const getSituationInstances = async (userId: string, currPage: number) => {
    const rangeStart = (currPage - 1) * SUMMARY_HISTORY_PAGE_SIZE;
    const rangeEnd = rangeStart + SUMMARY_HISTORY_PAGE_SIZE - 1;

    let totalPages = null;

    const { data, error, count }: 
    { data: SituationInstance[] | null, error: PostgrestError | null, count: number | null} = await supabase
        .from('situation_instance')
        .select('*, situation!public_situation_instance_situation_id_fkey ( title )', 
            { count: 'exact' })
        .eq('user_id', userId)
        .order('start_time', {ascending: false})
        .range(rangeStart, rangeEnd);
        
    if (count) {
        totalPages = Math.ceil(count / SUMMARY_HISTORY_PAGE_SIZE);
    }

    return { data, error, totalPages };
}


// export const getPublicSituationInstances = async (currPage: number) => {
//     const rangeStart = (currPage - 1) * SUMMARY_HISTORY_PAGE_SIZE;
//     const rangeEnd = rangeStart + SUMMARY_HISTORY_PAGE_SIZE - 1;

//     let totalPages = null;

//     const { data, error, count }: 
//     { data: SituationInstance[] | null, error: PostgrestError | null, count: number | null} = await supabase
//         .from('situation_instance')
//         .select(`
//             *,
//             situation:public_situation_instance_situation_id_fkey (
//                 id,
//                 prompt,
//                 created_at,
//                 updated_at,
//                 feedback_prompt,
//                 description,
//                 title,
//                 image_url,
//                 audio_url,
//                 audio_volume,
//                 voice_id,
//                 category,
//                 type,
//                 version,
//                 author_id,
//                 author_username,
//                 duration,
//                 sessions,
//                 public
//             ),
//             user:users ( username ),
//             feedback_count: review_feedback ( count )
//         `, 
//         { count: 'exact' })
//         .eq('public', true)
//         .order('feedback_count', {ascending: true})
//         .range(rangeStart, rangeEnd);
        
//     if (count) {
//         totalPages = Math.ceil(count / SUMMARY_HISTORY_PAGE_SIZE);
//     }

//     return { data, error, totalPages };
// }

// export const getPublicSituationInstances = async (userId: string, currPage: number) => {
//     const rangeStart = (currPage - 1) * SUMMARY_HISTORY_PAGE_SIZE;
//     const rangeEnd = rangeStart + SUMMARY_HISTORY_PAGE_SIZE - 1;

//     let totalPages = null;

//     const { data, error, count }:
//         { data: (SituationInstance & { user: { username: string }, feedback_count: number })[] | null, error: PostgrestError | null, count: number | null } = await supabase
//             .rpc('get_situation_instances_with_feedback_count', { p_user_id: userId })
//             .order('feedback_count', { ascending: true })
//             .range(rangeStart, rangeEnd);

//     if (count) {
//         totalPages = Math.ceil(count / SUMMARY_HISTORY_PAGE_SIZE);
//     }

//     return { data, error, totalPages };
// };

export const getPublicSituationInstances = async (userId: string, currPage: number) => {
    const rangeStart = (currPage - 1) * SUMMARY_HISTORY_PAGE_SIZE;
    const rangeEnd = rangeStart + SUMMARY_HISTORY_PAGE_SIZE - 1;

    let totalPages = null;

    const { data, error, count }: 
    { data: SituationInstance[] | null, error: PostgrestError | null, count: number | null } = await supabase
        .rpc('get_situation_instances_with_feedback_count', { p_user_id: userId })
        .range(rangeStart, rangeEnd);

    if (count) {
        totalPages = Math.ceil(count / SUMMARY_HISTORY_PAGE_SIZE);
    }

    return { data, error, totalPages };
};


export const submitFeedback = async (feedback: {
    user_id: string,
    situation_instance_id: number,
    went_well: string,
    to_improve: string,
    came_across_as: string
}) => {
    const { data, error } = await supabase
        .from('review_feedback')
        .insert([feedback]);

    if (error) throw error;
    return data;
};

export const checkFeedback = async (userId: string, situationInstanceId: number) => {
    const { data, error } = await supabase
        .from('review_feedback')
        .select('*')
        .eq('user_id', userId)
        .eq('situation_instance_id', situationInstanceId)
        .limit(1); 

    if (error) throw error;

    return data?.length > 0 ? data[0] : null;
};

export const getFeedbackForSituationInstance = async (situationInstanceId: number) => {
    const { data, error } = await supabase
        .from('review_feedback')
        .select('*, users(username)')
        .eq('situation_instance_id', situationInstanceId)
        .order('created_at', { ascending: false });

    if (error) {
        console.error('Error fetching feedback:', error);
        return { data: null, error };
    }

    const mappedData = data?.map((fb: any) => ({
        ...fb,
        username: fb.users.username
    }));

    return { data: mappedData, error: null };
};

export const getLatestXSituationSummaries = async (situation: Situation, userId: string) => {
    if (situation.type === "therapy") {
        const { data, error }: {data: {feedback_as_text: string}[] | null; error: PostgrestError | null} = await supabase
            .from('situation_instance')
            .select('feedback_as_text')
            .eq('situation_id', situation.id)
            .eq('user_id', userId)
            .neq('feedback_as_text', null)
            .limit(LAST_X_SESSION_SUMMARY_CONTEXT)
            .order('start_time', {ascending: false});
        
        return { data, error };
    }

    return {data: null, error: null};
}


export const getSituationInstanceById = async (id: number) => {
    const { data, error } = await supabase
      .from('situation_instance')
      .select(`
        *,
        situation: situation_id (
          title,
          description,
          image_url,
          author_username,
          version
        ),
        user: user_id (
          username
        )
      `)
      .eq('id', id)
      .single();
    
    if (error) {
      throw error;
    }
  
    return data;
  };