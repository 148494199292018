import { IonButton } from "@ionic/react";

const ContinueButton: React.FC<{
    onContinue: () => void,
    canContinue: boolean,
    color?: 'success' | 'danger'
}> = ({
    onContinue, 
    canContinue,
    color
}) => {

        return <>
            <IonButton
                onClick={onContinue}
                disabled={!canContinue}
                expand="block"
                color={color}
            >
                Continue
            </IonButton>
        </>
    }

export default ContinueButton;