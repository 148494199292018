import { IonCard, IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonButton, IonBackButton, IonButtons } from "@ionic/react";
import PersonalityTraits from "./PersonalityTraits"; 
import InterestsSelector from "./InterestsSelector"; 
import VoiceAndSpeech from "./VoiceAndSpeech"; 
import BasicInfoSection from "./BasicInfo";
import React, { useState, useEffect } from 'react';
import { supabase } from '../../service/authService';
import { useAuth } from '../../context/AuthContext';

const CharacterCustomizationPage: React.FC = () => {
    const [gender, setGender] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [age, setAge] = useState<number | string>('');
    const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
    const [personalityTraits, setPersonalityTraits] = useState<{ [key: string]: number }>({});
    const [voiceId, setVoiceId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [hasProfile, setHasProfile] = useState(false);
    const { session } = useAuth();
    const currentUser = session?.user;

    const saveToDatabase = async () => {
        const profile = {
            user_id: currentUser?.id,
            name,
            age: Number(age), // Ensure age is saved as a number
            gender,
            interests: selectedInterests,
            personality_traits: personalityTraits,
            voice_id: voiceId,
        };

        console.log(profile)
        try {
            let { data, error } = await supabase
                .from('ai_profiles')
                .upsert(profile, { onConflict: "user_id" });

            if (error) throw error;
            console.log(data);
            // Handle success - maybe navigate to a different page or show a message
        } catch (error) {
            console.error('Error saving profile:', error);
            // Handle error - show an error message to the user
        }
    };

    useEffect(() => {
        const fetchAIData = async () => {

            if (currentUser) {
                const { data, error } = await supabase
                    .from('ai_profiles')
                    .select('*')
                    .eq('user_id', currentUser.id)
                    .single(); 

                if (error) {
                    console.log('Error fetching AI profile:', error);
                } else if (data) {
                    console.log('fetched AI profile:', data);
                    setName(data.name || '');
                    setAge(data.age || '');
                    setGender(data.gender || '');
                    setSelectedInterests(data.interests || []);
                    setPersonalityTraits(data.personality_traits || {});
                    setVoiceId(data.voice_id || '');
                    // ... any other fields you need to set
                    setHasProfile(true);
                    
                }
                setIsLoading(false);
            }

        };

        fetchAIData();
    }, [currentUser]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/secret" />
          </IonButtons>
          <IonTitle>Customize Your AI Friend</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <>
                        <BasicInfoSection
                            name={name}
                            age={age}
                            gender={gender}
                            setName={setName}
                            setAge={setAge}
                            setGender={setGender}
                        />
                        <PersonalityTraits
                            personalityTraits={personalityTraits}
                            setPersonalityTraits={setPersonalityTraits}
                        />
                        <InterestsSelector
                            selectedInterests={selectedInterests}
                            setSelectedInterests={setSelectedInterests}
                        />
                        <VoiceAndSpeech gender={gender} initialVoiceId={voiceId} setVoiceId={setVoiceId} />
                        {/* <IonButton expand="block" routerLink="/summary">Review and Confirm</IonButton> */}
                        <IonButton expand="block" onClick={saveToDatabase}>Review and Confirm</IonButton>
                        </>
                )}
      </IonContent>
    </IonPage>
  );
};

export default CharacterCustomizationPage;


