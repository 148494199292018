import React, { useState, useRef, useEffect } from 'react';
import { IonIcon,IonButton } from '@ionic/react';
import { sync } from 'ionicons/icons';
import AudioManager from '../../AudioManager';
import './PassageContent.css';
import { playSkipForwardOutline } from 'ionicons/icons';

interface PassageContentProps {
    passage: { text: string, sender: string, audio_url: string }[];
    onComplete?: () => void;
}

interface MessageState {
    isTyping: boolean;
    isPlaying: boolean;
    isDisplayed: boolean;
    text: string;
}

const PassageContent: React.FC<PassageContentProps> = ({ passage, onComplete }) => {
    const initialMessagesState = passage.map(() => ({
        isTyping: false,
        isPlaying: false,
        isDisplayed: false,
        text: ''
    }));

    const [messagesState, setMessagesState] = useState<MessageState[]>(initialMessagesState);
    const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(0);
    const [showReplayButton, setShowReplayButton] = useState<boolean>(false);
    const [isSkipped, setIsSkipped] = useState<boolean>(false);
    const isFirstRun = useRef<boolean>(true);
    const typingTimeouts = useRef<NodeJS.Timeout[]>([]);

    const senderAlignments = useRef<{ [sender: string]: string }>({});
    let lastAlignment = 'right';

    useEffect(() => {
        console.log("Hello")
        resetState();
        startMessage(0);
        return () => {
            console.log("Hello 2")
            clearTimeouts();
            AudioManager.stopCurrentAudio();
        };
    }, []);

    const resetState = () => {
        setMessagesState(passage.map(() => ({
            isTyping: false,
            isPlaying: false,
            isDisplayed: false,
            text: ''
        })));
        setCurrentMessageIndex(0);
        setShowReplayButton(false);
        setIsSkipped(false);
        isFirstRun.current = true;
    };

    const clearTimeouts = () => {
        typingTimeouts.current.forEach(clearTimeout);
        typingTimeouts.current = [];
    };
    
    const updateMessageState = (index: number, newState: Partial<MessageState>) => {
        setMessagesState((prevState) => {
            const updatedMessages = [...prevState];
            updatedMessages[index] = { ...updatedMessages[index], ...newState };
            return updatedMessages;
        });
    };


    const handleReplayAllMessages = () => {
        clearTimeouts();
        AudioManager.stopCurrentAudio();

        setMessagesState(initialMessagesState);
        isFirstRun.current = true;
        setShowReplayButton(false);
        setIsSkipped(false);
        startMessage(0);
    };

    const handleSkip = () => {
        clearTimeouts();
        AudioManager.stopCurrentAudio();
        setMessagesState(passage.map((message, index) => ({
            isTyping: false,
            isPlaying: false,
            isDisplayed: true,
            text: message.text
        })));
        setIsSkipped(true);
        setShowReplayButton(true);
        if (typeof onComplete === 'function') {
            onComplete();  
        }
    };

    const startMessage = (index: number, continueSequence: boolean = true) => {
        if (index < passage.length) {
            setCurrentMessageIndex(index);
            updateMessageState(index, { isTyping: true, isPlaying: true, isDisplayed: true });
            console.log("Rec1");
            let typingCompleted = false;
            let audioCompleted = false;
        
            const onTypingComplete = () => {
                console.log("Rec2");
                typingCompleted = true;
                if (continueSequence && audioCompleted) {
                    startMessage(index + 1);
                }
            };
        
            startTypingEffect(index, onTypingComplete);
        
            AudioManager.playAudio(passage[index].audio_url, () => {
                audioCompleted = true;
                updateMessageState(index, { isPlaying: false });
                if (typingCompleted && continueSequence) {
                    startMessage(index + 1);
                }
                if (index === passage.length - 1) {
                    isFirstRun.current = false;
                    setShowReplayButton(true);
                    if (typeof onComplete === 'function') {
                        console.log("Passage completed")
                        onComplete();  
                    }
                }
            });
        }
    };
    
    const startTypingEffect = (index: number, onTypingComplete: () => void) => {
        const text = passage[index].text;
        let displayedText = '';
    
        clearTimeouts();
    
        for (let i = 0; i < text.length; i++) {
            const timeout = setTimeout(() => {
                displayedText += text[i];
                updateMessageState(index, { text: displayedText });
    
                if (i === text.length - 1) {
                    updateMessageState(index, { isTyping: false, isDisplayed: true });
                    onTypingComplete();  
                }
            }, i * 35);
    
            typingTimeouts.current.push(timeout);
        }
    };
    
    const handleReplayMessage = (index: number) => {
        clearTimeouts();
        AudioManager.stopCurrentAudio();
    
        if (messagesState[currentMessageIndex]?.isTyping) {
            updateMessageState(currentMessageIndex, {
                isTyping: false,
                isPlaying: false,
                isDisplayed: true,
                text: passage[currentMessageIndex].text
            });
        }
    
        updateMessageState(index, {
            isTyping: true,
            isPlaying: true,
            isDisplayed: false,
            text: ''
        });
    
        
        const continueSequence = isFirstRun.current;
        console.log(continueSequence)
        startMessage(index, continueSequence);
    };
    
   
    return (
        <div className="passage-content" style={{ marginBottom: 30, marginTop: 20 }}>
            {!isSkipped && (
                <IonIcon icon={playSkipForwardOutline} onClick={handleSkip} className="replay-icon" />
            )}
            {showReplayButton && (
                <IonIcon icon={sync} onClick={handleReplayAllMessages} className="replay-icon" />
            )}
            {passage.map((message, index) => {
                
                let alignmentClass = senderAlignments.current[message.sender];

                // if (!alignmentClass) {
                //     alignmentClass = lastAlignment === 'left' ? 'right' : 'left';
                //     senderAlignments.current[message.sender] = alignmentClass;
                //     lastAlignment = alignmentClass;
                // }
                if (!alignmentClass) {
                    if (message.sender.toLowerCase() === 'author') {
                        alignmentClass = 'center';
                    } else {
                        alignmentClass = lastAlignment === 'left' ? 'right' : 'left';
                        lastAlignment = alignmentClass;
                    }
                    senderAlignments.current[message.sender] = alignmentClass;
                }

                return (
                    <div
                        key={index}
                        className={`message-wrapper ${alignmentClass}`}
                        onClick={() => handleReplayMessage(index)}
                    >
                        {messagesState[index]?.isDisplayed && message.sender.toLowerCase() !== 'author' && (
                            <div className={`sender-name ${alignmentClass}`}>
                                {message.sender}
                            </div>
                        )}
                        <div
                            className={`message ${alignmentClass} ${index === currentMessageIndex && messagesState[index]?.isPlaying ? 'playing' : ''}`}
                        >
                            {messagesState[index]?.text || ''}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default PassageContent;