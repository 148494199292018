import React, { useState, useEffect } from 'react';
import { IonModal, IonToggle, IonCheckbox, IonPage, IonButton, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonLabel, IonInput, IonRange, IonButtons, IonIcon } from '@ionic/react';
import { close } from "ionicons/icons";

interface SurveyModalProps {
  isOpen: boolean;
  onClose: (data?: { [key: string]: string }) => void;
  title: string;
  questions: { id: string, text: string, type: 'text' | 'range' | 'boolean' | 'toggle'}[];
  justCloseModal?: () => void;
}

const SurveyModal: React.FC<SurveyModalProps> = ({ isOpen, onClose, title, questions, justCloseModal }) => {
  const [responses, setResponses] = useState<{ [key: string]: string }>({});

  const handleInputChange = (id: string, value: string) => {
    setResponses({ ...responses, [id]: value });
  };

  const handleSubmit = () => {
    onClose(responses);
  };

  useEffect(() => {
    if (isOpen) {
      setResponses({});
    }
  }, [isOpen]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={justCloseModal ? () => justCloseModal() : () => handleSubmit()} mode="ios">
      <IonHeader>
        <IonToolbar>
          {justCloseModal && <IonButtons slot="start">
            <IonButton onClick={() => justCloseModal()}><IonIcon icon={close}></IonIcon></IonButton>
          </IonButtons> }
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" className="ion-padding">
        <IonList inset={true}>

          {questions.map(question => (
            <IonItem key={question.id} style={{ '--padding-end': '0px', '--inner-padding-end': '8px' }}>
              {
                question.type === "text" ? (
                  <IonInput
                    label-placement="floating"
                    label={question.text}
                    counter={true}
                    maxlength={1000}
                    // onIonInput={(e) => setPreviousAttempts((e.target as unknown as HTMLTextAreaElement).value)}
                    style={{ '--border-width': 0, '--padding-top': '5px', '--padding-end': '8px' }}

                      value={responses[question.id] || ''}
                      onIonInput={e => handleInputChange(question.id, e.detail.value!)}
                    />
                   ) : question.type === "range" ? (
                      <IonRange
                        mode='ios'
                        labelPlacement="stacked"
                        label={question.text}
                        snaps={true} min={0} max={10}
                        onIonInput={e => handleInputChange(question.id, String(e.detail.value!))}
                      ></IonRange>

                   ) : question.type === "boolean" ? (
                        <IonCheckbox
                          checked={responses[question.id] === 'true'}
                          onIonChange={e => handleInputChange(question.id, e.detail.checked ? 'true' : 'false')}
                        >
                          <IonLabel>{question.text}</IonLabel>
                        </IonCheckbox>
                   ) : question.type === "toggle" ? (
                        <>
                        <IonLabel>{question.text}</IonLabel>
                        <IonToggle
                          checked={responses[question.id] === 'true'}
                          onIonChange={e => handleInputChange(question.id, e.detail.checked ? 'true' : 'false')}
                          style={{ marginLeft: 'auto' }}
                        />
                      </>
                   ) : null} 
                      
            </IonItem>
          ))}
        </IonList>
        <IonList inset={true} style={{ backgroundColor: 'transparent' }}>
          <IonButton expand="block" onClick={handleSubmit}>Submit</IonButton>
        </IonList>
        {/* <IonButton expand="block" onClick={handleSubmit}>Submit</IonButton> */}
      </IonContent>

    </IonModal>
  );
};

export default SurveyModal;
