import React, { useState, useEffect, useRef } from 'react';
import { IonProgressBar, IonPage, IonIcon, IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonInput, IonButton, IonList, IonItem, IonText } from '@ionic/react';
import axios from 'axios';
import { sendOutline, micOutline, closeOutline } from 'ionicons/icons';
import './TextChatComponent.css';

interface TextChatComponentProps {
    postData: {
        instance_id: number;
        prompt: string;
        feedback_prompt: string;
    };
    situationData: {
        title: string;
        description: string;
        author_username: string;
        version: number;
        image_url: string;
    };
    isOpen: boolean;
    onClose: () => void;
}



const TextChatComponent: React.FC<TextChatComponentProps> = ({ postData, situationData, isOpen, onClose }) => {
    const [messages, setMessages] = useState<{ from: string, msg: string, time: string, id: number, className?: string }[]>([]);
    const [newMessage, setNewMessage] = useState<string>('');
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const messageCounter = useRef<number>(0);
    const [lastUserMessageId, setLastUserMessageId] = useState<number | null>(null);
    const [lastAssistantMessageId, setLastAssistantMessageId] = useState<number | null>(null);
    const [timeLeft, setTimeLeft] = useState<number>(30); 
    const [isTiming, setIsTiming] = useState<boolean>(false);
    const [timeoutTriggered, setTimeoutTriggered] = useState<boolean>(false);
    const [timedOutMessageIds, setTimedOutMessageIds] = useState<number[]>([]);
    const [followUpMessageId, setFollowUpMessageId] = useState<number | null>(null);

    useEffect(() => {
        const startChat = async () => {
            try {
                // console.log('Sending POST request to /start_chat with postData:', postData);
                //const response = await axios.post('http://localhost:3000/start_chat', postData);
                const response = await axios.post('https://finalbackend-nuob.onrender.com/start_chat', postData);
                console.log('Chat started:', response.data);
            } catch (error) {
                console.error('Error starting the chat:', error);
                alert('Error encountered while starting the chat. Please reload the page and retry.');
                onClose();
            }
        };

        startChat();
    }, [postData, onClose]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    // const handleSendMessage = async () => {
    //     const userMessage = {
    //         situation_instance_id: postData.instance_id,
    //         from: 'user',
    //         msg: newMessage,
    //         time: new Date().toISOString()
    //     };

    //     const response = await axios.post('http://localhost:3000/chat', {
    //         instance_id: postData.instance_id,
    //         user_message: newMessage
    //     });

    //     if (response.data && response.data.response) {
    //         const assistantMessage = {
    //             situation_instance_id: postData.instance_id,
    //             from: 'assistant',
    //             msg: response.data.response,
    //             time: new Date().toISOString(),
    //             id: ++messageCounter.current
    //         };

    //         setMessages([...messages, userMessage, assistantMessage]);
    //         setNewMessage('');
    //     }
    // };

    useEffect(() => {
        if (isTiming && timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        } else if (timeLeft === 0 && !timeoutTriggered) {
            // Trigger timeout only once
            setTimeoutTriggered(true);
            handleTimeout();
        }
    }, [isTiming, timeLeft, timeoutTriggered]);

    const handleTimeout = async () => {
        setIsTiming(false);
        
        // Send the system message internally but do not display it in the chat
        const systemMessage = "The user is taking too long to respond. Please ask why they are delayed in a casual manner if you are texting not speaking directly in person.";

        try {
            const response = await axios.post('https://finalbackend-nuob.onrender.com/chat', {
            // const response = await axios.post('http://localhost:3000/chat', {
                instance_id: postData.instance_id,
                user_message: systemMessage
            });

            // Display the assistant's response based on the system message
            if (response.data && response.data.response) {
                const assistantMessage = {
                    situation_instance_id: postData.instance_id,
                    from: 'assistant',
                    msg: response.data.response,
                    time: new Date().toISOString(),
                    id: ++messageCounter.current,
                    className: 'pop-in'
                };

                setMessages(prevMessages => [...prevMessages, assistantMessage]);
                setLastAssistantMessageId(assistantMessage.id);
                setTimedOutMessageIds([lastUserMessageId!, lastAssistantMessageId!, assistantMessage.id]);
                setFollowUpMessageId(assistantMessage.id);
            }
        } catch (error) {
            console.error('Error handling timeout message:', error);
        }
    };


    // const handleSendMessage = async () => {
    //     // Reset the timing and clear state related to timeouts
    //     setIsTiming(false);
    //     setTimeoutTriggered(false);
    //     setTimeLeft(30);
    
    //     // Create and add the new user message
    //     const userMessage = {
    //         situation_instance_id: postData.instance_id,
    //         from: 'user',
    //         msg: newMessage,
    //         time: new Date().toISOString(),
    //         id: ++messageCounter.current,
    //         className: 'pop-in'
    //     };
    
    //     // Remove the previous user and assistant messages if they exist (this handles non-timeout cases)
    //     if (lastUserMessageId !== null && lastAssistantMessageId !== null) {
    //         setMessages(prevMessages =>
    //             prevMessages.map(message =>
    //                 [lastUserMessageId, lastAssistantMessageId].includes(message.id)
    //                     ? { ...message, className: 'fade-out' }
    //                     : message
    //             )
    //         );
    
    //         setTimeout(() => {
    //             setMessages(prevMessages =>
    //                 prevMessages.filter(
    //                     message => ![lastUserMessageId, lastAssistantMessageId].includes(message.id)
    //                 )
    //             );
    //         }, 500); // Duration of fade-out animation
    //     }
    
    //     // Remove all messages related to timeout (user's previous message, assistant's reply, and follow-up)
    //     if (timedOutMessageIds.length > 0) {
    //         setMessages(prevMessages =>
    //             prevMessages.map(message => {
    //                 if (timedOutMessageIds.includes(message.id)) {
    //                     return { ...message, className: 'fade-out' };
    //                 }
    //                 return message;
    //             })
    //         );
    
    //         setTimeout(() => {
    //             setMessages(prevMessages =>
    //                 prevMessages.filter(message => !timedOutMessageIds.includes(message.id))
    //             );
    //         }, 500); // Duration of fade-out animation
    //     }
    
    //     // Clear the `timedOutMessageIds` array
    //     setTimedOutMessageIds([]);
    
    //     // Add the new user message
    //     setMessages(prevMessages => {
    //         const updatedMessages = prevMessages.map(message => ({
    //             ...message,
    //             className: message.className === 'pop-in' ? '' : message.className,
    //         }));
    //         return [...updatedMessages, userMessage];
    //     });
    
    //     setNewMessage('');
    //     setLastUserMessageId(userMessage.id);
    
    //     // Send the new message to the server
    //     try {
    //         const response = await axios.post('http://localhost:3000/chat', {
    //             instance_id: postData.instance_id,
    //             user_message: newMessage
    //         });
    
    //         if (response.data && response.data.response) {
    //             const assistantMessage = {
    //                 situation_instance_id: postData.instance_id,
    //                 from: 'assistant',
    //                 msg: response.data.response,
    //                 time: new Date().toISOString(),
    //                 id: ++messageCounter.current,
    //                 className: 'pop-in'
    //             };
    
    //             setMessages(prevMessages => {
    //                 const updatedMessages = prevMessages.map(message => ({
    //                     ...message,
    //                     className: message.className === 'pop-in' ? '' : message.className,
    //                 }));
    //                 return [...updatedMessages, assistantMessage];
    //             });
    //             setLastAssistantMessageId(assistantMessage.id);
    //             setIsTiming(true);
    //         }
    //     } catch (error) {
    //         console.error('Error sending the message:', error);
    //     }
    // };
    const handleSendMessage = async () => {
        // Reset the timing and clear state related to timeouts
        setIsTiming(false);
        setTimeoutTriggered(false);
        setTimeLeft(30);
    
        // Create and add the new user message
        const userMessage = {
            situation_instance_id: postData.instance_id,
            from: 'user',
            msg: newMessage,
            time: new Date().toISOString(),
            id: ++messageCounter.current,
            className: 'pop-in'
        };
    
        // Fade out and remove previous messages (for non-timeout cases)
        if (lastUserMessageId !== null && lastAssistantMessageId !== null) {
            fadeOutMessages([lastUserMessageId, lastAssistantMessageId]);
        }
    
        // Handle removing messages for timeout cases
        if (timedOutMessageIds.length > 0) {
            fadeOutMessages(timedOutMessageIds);
            setTimedOutMessageIds([]);
        }
    
        // Add the new user message
        setMessages(prevMessages => {
            const updatedMessages = prevMessages.map(message => ({
                ...message,
                className: message.className === 'pop-in' ? '' : message.className,
            }));
            return [...updatedMessages, userMessage];
        });
    
        setNewMessage('');
        setLastUserMessageId(userMessage.id);
    
        // Send the new message to the server
        try {
            const response = await axios.post('https://finalbackend-nuob.onrender.com/chat', {
            // const response = await axios.post('http://localhost:3000/chat', {
                instance_id: postData.instance_id,
                user_message: newMessage
            });
    
            if (response.data && response.data.response) {
                const assistantMessage = {
                    situation_instance_id: postData.instance_id,
                    from: 'assistant',
                    msg: response.data.response,
                    time: new Date().toISOString(),
                    id: ++messageCounter.current,
                    className: 'pop-in'
                };
    
                setMessages(prevMessages => {
                    const updatedMessages = prevMessages.map(message => ({
                        ...message,
                        className: message.className === 'pop-in' ? '' : message.className,
                    }));
                    return [...updatedMessages, assistantMessage];
                });
                setLastAssistantMessageId(assistantMessage.id);
                setIsTiming(true);
            }
        } catch (error) {
            console.error('Error sending the message:', error);
        }
    };

    const fadeOutMessages = (messageIds: number[]) => {
        // Apply the fade-out class to messages
        setMessages(prevMessages =>
            prevMessages.map(message =>
                messageIds.includes(message.id)
                    ? { ...message, className: 'fade-out' }
                    : message
            )
        );
    
        // After the fade-out animation is complete, remove the messages from the list
        setTimeout(() => {
            setMessages(prevMessages =>
                prevMessages.filter(message => !messageIds.includes(message.id))
            );
        }, 500); // Match the duration of the fade-out animation
    };

    const handleEndChat = async () => {
        try {
            const chatHistory = messages.map(msg => ({
                situation_instance_id: postData.instance_id,
                from: msg.from,
                msg: msg.msg,
                time: msg.time
            }));

            const feedbackContent = postData.feedback_prompt + " Conversation History: " + chatHistory.map(msg => `${msg.from}: ${msg.msg}`).join('\n');

            onClose();
            await axios.post('https://finalbackend-nuob.onrender.com/end_chat_with_feedback', {
             //await axios.post('http://localhost:3000/end_chat_with_feedback', {    
                messages: chatHistory,
                postData,
                feedback_content: feedbackContent
            });
            console.log("end")
            
        } catch (error) {
            console.error('Error ending chat:', error);
        }
    };

    return (
        <IonModal isOpen={isOpen} onDidDismiss={handleEndChat}>
            <IonHeader className="modal-header">
                <div className="header-left">
                    <img src={situationData.image_url} alt="situation" className="header-image" />
                    <div className="header-title">
                        <h1>{situationData.title}</h1>
                        <span>By @{situationData.author_username} • v{situationData.version}</span>
                    </div>
                </div>
                <div className="header-right">
                    <button className="header-close-button" onClick={handleEndChat}>
                        <IonIcon icon={closeOutline} />
                    </button>
                </div>
            </IonHeader>
            <IonContent>
                {/* <IonList className="message-list">
                    {messages.map((message, index) => (
                        <IonItem key={index} className={`message-item ${message.from === 'user' ? 'user-message' : 'assistant-message'}`}>
                            <IonText className="message-text">
                                {message.msg}
                            </IonText>
                        </IonItem>
                    ))}
                    <div ref={messagesEndRef} />
                </IonList> */}
                <IonList className="message-list">
                    {messages.map((message, index) => (
                        <IonItem key={index} className={`message-item ${message.from === 'user' ? 'user-message' : 'assistant-message'} ${message.className ? message.className : ''}`}>
                            <IonText className="message-text">
                                {message.msg}
                            </IonText>
                        </IonItem>
                    ))}
                    <div ref={messagesEndRef} />
                </IonList>
            </IonContent>
            {isTiming && (
                <IonProgressBar value={timeLeft / 30} color="danger" />
            )}
            <IonFooter className="footer-input">
                <div className="input-container">
                    <IonInput
                        value={newMessage}
                        placeholder="Type a message..."
                        onIonInput={e => setNewMessage(e.detail.value!)}
                    />
                    
                </div>
                <IonButton fill="clear" className="send-button" onClick={handleSendMessage}>
                    <IonIcon  icon={sendOutline} />
                </IonButton>
            </IonFooter>
        </IonModal>
    );
};

export default TextChatComponent;
