import { useEffect, useState } from "react";
import { SituationInstance } from "../../../types/ai_pivot/situationTypes";
import { getSituationInstances, getFeedbackForSituationInstance  } from "../../../service/situationService";
import { useAuth } from "../../../context/AuthContext";
import {  IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel, IonList, IonSpinner, IonText } from "@ionic/react";
import PaginationActions from "../../generic/PaginationActions";
import ReactMarkdown from 'react-markdown';
import { IonIcon } from '@ionic/react';
import { chevronDownOutline, chevronForwardOutline } from 'ionicons/icons';
import SitFeedback from "../situation/SitFeedback";
import RecordingItem from "../situation/RecordingItem";
import {Message, ContentItem } from "../../../types/ai_pivot/situationTypes";

const HistoryComponent: React.FC = () => {
    const [situationInstances, setSituationInstances] = useState<SituationInstance[]>();
    const [currPage, setCurrPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const { user } = useAuth();
    const [expandedId, setExpandedId] = useState<string | null>(null);

    useEffect(() => {
        const fetchSituationInstances = async () => {
            if (user) {
                const { data, error, totalPages } = await getSituationInstances(user.id, currPage);
                console.log(data);
                if (error) {
                    console.error('error fetching history: ', JSON.stringify(error));
                } else if (data) {
                    const filteredData = data.filter(si => 
                        si.messages && si.messages.length > 0 && 
                        si.messages.some(message => {
                            if (typeof message.content === 'string') {
                                return message.content.trim() !== '';
                            } else if (Array.isArray(message.content)) {
                                return message.content.some(item => item.text.trim() !== '');
                            }
                            return false;
                        })
                    );

                    console.log("F", filteredData);
                    setSituationInstances(filteredData);
                }
    
                if (totalPages) {
                    setTotalPages(totalPages);
                }
            }
        }
    
        fetchSituationInstances();
    }, [currPage, user]);

    const toggleExpansion = (id: number) => {
        const idStr = id.toString();
        setExpandedId(expandedId === idStr ? null : idStr);
    }

    const handlePrev = () => {
        setCurrPage(currPage - 1);
    }

    const handleNext = () => {
        setCurrPage(currPage + 1);
    }

    if (!situationInstances) {
        return (
            <div style={{ width: '100%', maxWidth: '550px', margin: 'auto', textAlign: 'center' }}>
                <IonSpinner style={{width: 100, height: 100, marginTop: 100}} name="crescent"></IonSpinner>
            </div>
        );
    }

    return (
        <div style={{width: '100%', maxWidth: '550px', margin: 'auto'}}>
            {
                situationInstances 
                    &&
                situationInstances.map((si) => (
                    <div key={si.id}>
                    <SitFeedback 
                        key={si.id}
                        feedback={si.feedback_as_text} 
                        title={si.situation.title} 
                        subtitle={new Date(si.start_time).toLocaleString()}
                        situationId={si.id}
                        showFeedbackButton={true}
                        conversationHistory={si.messages}
                        />
                    {si.recording_id && <RecordingItem recordingId={si.recording_id} />}
                    
                    </div>
                    ))
            }
            <PaginationActions
                currPage={currPage}
                totalPages={totalPages}
                handlePrev={handlePrev}
                handleNext={handleNext}
            />
        </div>
    );
}

export default HistoryComponent;