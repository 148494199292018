import { IonCard, IonIcon, IonText } from '@ionic/react';
import { list } from 'ionicons/icons'; // Import the icon you want to use
import './UnitCard.css';

const UnitCard: React.FC<{ section: string; unit: string; title: string }> = ({ section, unit, title }) => {
    return (
        <IonCard className="unit-card">
        <div className="unit-card-text">
            <IonText className="unit-card-section">
                <span>{section}, {unit}</span>
            </IonText>
            <IonText className="unit-card-title">
                {title}
            </IonText>
        </div>
        <IonIcon icon={list} className="unit-card-icon" />
    </IonCard>
    );
}

export default UnitCard;

