import { IonButton, IonIcon } from "@ionic/react";
import { volumeMedium, volumeMediumOutline, pause } from "ionicons/icons";
import { useEffect, useState } from "react";
import AudioManager from '../../AudioManager';

const ModuleAudioContent: React.FC<{ audio_url: string }> = ({ audio_url }) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    useEffect(() => {
        const handleAudioEnd = () => {
            setIsPlaying(false);
        };
        AudioManager.playAudio(audio_url,handleAudioEnd);
        setIsPlaying(true);
        
        return () => {
            AudioManager.stopCurrentAudio();
            setIsPlaying(false);
        };
    }, [audio_url]);

    const toggleAudio = () => {
        if (isPlaying) {
            AudioManager.pauseCurrentAudio();
        } else {
            AudioManager.resumeCurrentAudio();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '50px', marginBottom: '30px' }}>
            <IonButton
                style={{ height: '120px', width: '120px', '--border-radius': '22%' }}
                onClick={toggleAudio}
            >
                <IonIcon 
                    src={isPlaying ? pause : volumeMedium} 
                    style={{ height: '70px', width: '70px' }} 
                    size="large"
                />
            </IonButton>
        </div>
    );
}

export default ModuleAudioContent;