
import { createClient, SupabaseClient, User } from '@supabase/supabase-js';
import { MAIN_SCREEN } from '../util/constants';
import { PostgrestError, AuthError } from '@supabase/supabase-js';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

const supabaseUrl: string = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey: string = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase: SupabaseClient = createClient(supabaseUrl, supabaseAnonKey);

interface GenericError {
  message: string;
  // ... any other properties you expect in the error
}

export const signIn = async (email: string, password: string): Promise<{ user: User | null, error: AuthError | null }> => {
  const { data, error } = await supabase.auth.signInWithPassword({ email, password });
  const user = data.user;
  return { user, error };
};

export const signUp = async (email: string, password: string): Promise<{ user: User | null, error: AuthError | null }> => {
  const { data, error } = await supabase.auth.signUp(
    {  
      email,
      password, 
      options: {
        emailRedirectTo: `${window.location.origin}/${MAIN_SCREEN}`
      } 
    });

  const user = data.user;
  return { user, error };
};

export const signOut = async (): Promise<{ error: AuthError | null }> => {
  const { error } = await supabase.auth.signOut();
  return { error };
};

// export const signInWithGoogle = async (redirectToPath?: string) => {
//     const { data, error } = await supabase.auth.signInWithOAuth({
//       provider: 'google',
//       options: {
//         redirectTo: `${window.location.origin}${redirectToPath || `/${MAIN_SCREEN}`}`
//       }
//     });
//     return { data, error };
//   };

export const signInWithGoogle = async (redirectToPath?: string) => {
  const isNative = Capacitor.isNativePlatform();
  const redirectUrl = isNative
    ? 'extraverse://login-callback'
    : `${window.location.origin}${redirectToPath || `/${MAIN_SCREEN}`}`;

  if (isNative) {
    const authUrl = `${supabaseUrl}/auth/v1/authorize?provider=google&redirect_to=${encodeURIComponent(redirectUrl)}`;
    await Browser.open({ url: authUrl });
    return { data: null, error: null };
  } else {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: redirectUrl,
      },
    });
    return { data, error };
  }
};

export const signInWithFacebook = async (redirectToPath?: string) => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'facebook',
      options: {
        redirectTo: `${window.location.origin}${redirectToPath || `/${MAIN_SCREEN}`}`
      }
    });
    return { data, error };
  };

export const signInWithApple = async (redirectToPath?: string) => {
  const isNative = Capacitor.isNativePlatform();
  const redirectUrl = isNative
    ? 'extraverse://login-callback'
    : `${window.location.origin}${redirectToPath || `/${MAIN_SCREEN}`}`;
  
  if (isNative) {
    const authUrl = `${supabaseUrl}/auth/v1/authorize?provider=apple&redirect_to=${encodeURIComponent(redirectUrl)}`;
    await Browser.open({ url: authUrl });
    return { data: null, error: null };
  } else {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'apple',
      options: {
        redirectTo: redirectUrl,
      },
    });
    return { data, error };
  }
};

export const resetPassword = async (email: string) => {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/update-password`,
    });
    return { data, error };
  };  

export const updatePassword = async (newPassword: string) => {
  const { data, error } = await supabase.auth.updateUser({
    password: newPassword
  });

  return {data, error};
}

export const deleteAccount = async (): Promise<{ error: Error | null }> => {
  try {
    const { error } = await supabase.rpc('delete_user');
    if (error) throw error;
    
    await signOut();
    
    return { error: null };
  } catch (error) {
    console.error('Error deleting account:', error);
    return { error: error as Error };
  }
};
