
// import React, { useState } from "react";
// import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonRange, IonText } from "@ionic/react";
// import './friend.css'; 

// interface TraitSliderProps {
//   traitName: string;
//   leftLabel: string;
//   rightLabel: string;
// }

// const TraitSlider: React.FC<TraitSliderProps> = ({ traitName, leftLabel, rightLabel }) => {
//   const [value, setValue] = useState<number>(40); // Default mid-range

//   return (
//     <div className="trait-slider-container">
//       <IonText className="trait-name">{traitName}</IonText>
//       <div className="range-container">
//         <IonText className="end-label">{leftLabel}</IonText>
//         <IonRange
//           min={0}
//           max={100}
//           step={20}
//           snaps
//           ticks
//           value={value}
//           onIonChange={e => setValue(e.detail.value as number)}
//         />
//         <IonText className="end-label">{rightLabel}</IonText>
//       </div>
//     </div>
//   );
// };

// const PersonalityTraits: React.FC = () => {
//   return (
//     <IonCard>
//       <IonCardHeader>
//         <IonCardTitle>Personality Traits</IonCardTitle>
//       </IonCardHeader>
//       <IonCardContent>
//         <TraitSlider traitName="Extraversion" leftLabel="Introverted" rightLabel="Extroverted" />
//         <TraitSlider traitName="Agreeableness" leftLabel="Critical" rightLabel="Empathetic" />
//         <TraitSlider traitName="Conscientiousness" leftLabel="Spontaneous" rightLabel="Organized" />
//         <TraitSlider traitName="Emotional Stability" leftLabel="Sensitive" rightLabel="Resilient" />
//         <TraitSlider traitName="Openness" leftLabel="Consistent" rightLabel="Curious" />
//       </IonCardContent>
//     </IonCard>
//   );
// };

// export default PersonalityTraits;


import React, { useState } from "react";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonRange, IonText } from "@ionic/react";
import './friend.css';

interface TraitSliderProps {
  traitName: string;
  leftLabel: string;
  rightLabel: string;
  value: number;
  onValueChange: (traitName: string, newValue: number) => void; // New function prop for updating the trait
}

const TraitSlider: React.FC<TraitSliderProps> = ({ traitName, leftLabel, rightLabel, value, onValueChange }) => {
  return (
    <div className="trait-slider-container">
      <IonText className="trait-name">{traitName}</IonText>
      <div className="range-container">
        <IonText className="end-label">{leftLabel}</IonText>
        <IonRange
          min={0}
          max={100}
          step={20}
          snaps
          ticks
          value={value}
          onIonChange={e => onValueChange(traitName, e.detail.value as number)}
        />
        <IonText className="end-label">{rightLabel}</IonText>
      </div>
    </div>
  );
};

// Define a new interface for PersonalityTraitsProps
interface PersonalityTraitsProps {
  personalityTraits: { [key: string]: number; };
  setPersonalityTraits: React.Dispatch<React.SetStateAction<{ [key: string]: number; }>>;
}

const PersonalityTraits: React.FC<PersonalityTraitsProps> = ({personalityTraits, setPersonalityTraits }) => {
  // Define a state for each personality trait
  const [traits, setTraits] = useState<{ [key: string]: number }>(personalityTraits);
  

  // Update the state when a slider's value changes
  const handleValueChange = (traitName: string, newValue: number) => {
    const updatedTraits = { ...traits, [traitName]: newValue };
    setTraits(updatedTraits); // Update local state
    setPersonalityTraits(updatedTraits); // Update parent state
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Personality Traits</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {Object.keys(traits).map((trait) => (
          <TraitSlider
            key={trait}
            traitName={trait}
            leftLabel="Low"
            rightLabel="High"
            value={traits[trait]}
            onValueChange={handleValueChange}
          />
        ))}
      </IonCardContent>
    </IonCard>
  );
};

export default PersonalityTraits;
