import React, { useState } from 'react';
import axios from 'axios';
import { IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonButton, IonInput, IonSelect, IonSelectOption, IonLabel, IonItem } from '@ionic/react';

const VoiceGenerator: React.FC = () => {
    const [gender, setGender] = useState<string>('');
    const [accent, setAccent] = useState<string>('');
    const [age, setAge] = useState<string>('');
    const [accentStrength, setAccentStrength] = useState<number>(0.3);
    const [text, setText] = useState<string>('');

    const generateVoice = async () => {
        const payload = {
            gender,
            accent,
            age,
            accent_strength:accentStrength,
            text
        };
    
        console.log('Sending payload:', payload);
        try {
            const response = await axios.post('https://api.elevenlabs.io/v1/voice-generation/generate-voice', payload, {
                headers: {
                    'Content-Type': 'application/json', // Ensure the content type is set to application/json
                    'xi-api-key': '0e53a64fbff1b23e8537f45831b6561c'  
                },
                responseType: 'blob'  
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'generated_voice.mp3');
            document.body.appendChild(link);
            link.click();
         } catch (error) {
            console.error('Error making API request:', error);
            if (axios.isAxiosError(error) && error.response) {
                error.response.data.text().then((textData: string) => {
                    console.error('API Error Response:', textData);
                    alert(`Error: ${JSON.parse(textData).message}`);
                });
            } else {
                alert('An unexpected error occurred.');
            }
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Generate Random Voice</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem>
                    <IonLabel position="floating">Gender</IonLabel>
                    <IonSelect value={gender} onIonChange={e => setGender(e.detail.value)}>
                        <IonSelectOption value="female">Female</IonSelectOption>
                        <IonSelectOption value="male">Male</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Accent</IonLabel>
                    <IonInput value={accent} onIonChange={e => setAccent(e.detail.value as string)} />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Age</IonLabel>
                    <IonSelect value={age} onIonChange={e => setAge(e.detail.value)}>
                        <IonSelectOption value="young">Young</IonSelectOption>
                        <IonSelectOption value="middle_aged">Middle Aged</IonSelectOption>
                        <IonSelectOption value="old">Old</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                <IonLabel position="floating">Accent Strength</IonLabel>
                <IonInput type="number" value={accentStrength} onIonChange={e => setAccentStrength(parseFloat(e.detail.value ?? '0.3'))} />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Text</IonLabel>
                    <IonInput value={text} onIonChange={e => setText(e.detail.value as string)} />
                </IonItem>
                <IonButton expand="block" onClick={generateVoice}>Generate Voice</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default VoiceGenerator;
