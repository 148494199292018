import { IonPage, IonContent } from "@ionic/react";
import SituationComponent from "../../components/ai_pivot/home/SituationComponent";

import '../../theme/layout.css';

const Situation: React.FC = () => {
    return (
        <IonPage>
            {/* <IonHeader>
                <IonToolbar>
                    <IonTitle>Home</IonTitle>
                </IonToolbar>
            </IonHeader> */}
            <IonContent fullscreen={true}>
                <div className="content-block ion-padding">
                    <SituationComponent />
                </div>
            </IonContent>
        </IonPage>
    );
}

export default Situation;