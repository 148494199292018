import { IonText } from "@ionic/react";
import ReactMarkdown from "react-markdown";

import './Content.css';
import './shared/ModuleBottomBar.css';
import { Content } from "../../../../../types/ai_pivot/contentTypes";
import { useState } from "react";
import ContinueButton from "./shared/ContinueButton";
import { ModuleProps } from "./shared/ModuleProps";

interface InfoContentProps extends ModuleProps {
    content: Content;
}

export const InfoContentModule: React.FC<InfoContentProps> = ({
    content, moveToNextModule, updateProgressBar
}) => {
    const chunks = content.text.split('___');
    const totalChunks = content.text.split('___').length;
    const [currChunk, setCurrChunk] = useState(0);

    const isLastChunk = (chunk: number) => {
        return chunk + 1 === totalChunks;
    }

    return (
        <div>
            <div
                style={{ maxWidth: '550px', margin: 'auto', marginBottom: '12vh', padding: '20px' }}
                className="ion-padding">
                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    <IonText color="dark">
                        <ReactMarkdown className="content">{chunks[currChunk]}</ReactMarkdown>
                    </IonText>
                </div>

            </div>
            <div className="button-bar">
                <div className="center-content">
                    <div className="bottom-btn-wrapper">
                        <ContinueButton
                            onContinue={() => {
                                if (currChunk + 1 === totalChunks) {
                                    moveToNextModule();
                                } else {
                                    let nextChunk = currChunk + 1;
                                    let newProgress = nextChunk / totalChunks;
                                    updateProgressBar(isLastChunk(nextChunk) ? 1 : newProgress);
                                    setCurrChunk(nextChunk);
                                }
                            }
                            }
                            canContinue={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoContentModule;