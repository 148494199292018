import React, { useState } from 'react';
import { 
   IonPage, IonContent, IonInput, IonButton,
   IonHeader, IonToolbar, IonTitle, IonIcon, 
   IonRow, IonCol, useIonToast 
  } from '@ionic/react';
import './SignUp.css';
import { signUp } from '../../service/authService'; 
import { useHistory } from 'react-router-dom';
import { signInWithGoogle, signInWithFacebook } from '../../service/authService';
import { logoGoogle } from 'ionicons/icons';
import '../../theme/layout.css';
import { displayErrorToUser } from '../../util/errorUtils';

 
const SignUp: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [present] = useIonToast();
  const history = useHistory();

  const handleSignUp = async () => {
    const { user, error } = await signUp(email, password);
    
    // slight workaround - https://github.com/orgs/supabase/discussions/1282
    if (user && user!.identities?.length === 0) {
      present({
        header: 'Email is already in use',
        message: 'This email address is already in use. Sign-in instead',
        duration: 3000,
        position: 'top',
        buttons: ['OK']
      });
    } else if (error) {
      if (error.name === "AuthWeakPasswordError") {
        present({
          header: 'Weak password',
          message: error.message,
          position: 'top',
          buttons: ['OK']
        });
      } else {
        console.error('Sign-up error: ', JSON.stringify(error));
        displayErrorToUser({present, error, header: 'Sign-up error', position: 'top'});
      }
    } else {
      present({
        header: 'Confirm your email',
        message: 'Please check your email for a confirmation link to complete the process.',
        duration: 3000,
        position: 'top',
        buttons: ['OK']
      });
    }
  };

  const handleGoogleSignIn = async () => {
    const { data, error } = await signInWithGoogle();
    if (error) {
      console.error('Google sign in error:', error.message);
    } else {
      console.log('Google sign in successful:', data);
    }
  };

  const handleFacebookSignIn = async () => {
    const { data, error } = await signInWithFacebook();
    if (error) {
      console.error('Facebook sign in error:', error.message);
    } else {
      console.log('Facebook sign in successful:', data);
    }
  };

  const goToSignIn = () => {
    history.push('/signin_'); // Adjust the path as needed for your app's route
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sign Up</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="content-block ion-padding">
          <div className="form-container">
            <IonInput
              placeholder="Email"
              type="email"
              onIonInput={(e) => setEmail(e.detail.value!)}
              clearInput
            />
            <IonInput
              placeholder="Password"
              type="password"
              onIonInput={(e) => setPassword(e.detail.value!)}
              clearInput
            />
            <IonButton expand="block" onClick={handleSignUp}>Sign Up</IonButton>
            <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <IonButton fill="clear" onClick={handleGoogleSignIn}>
                Sign up with Google <IonIcon icon={logoGoogle} />
              </IonButton>
            </IonCol>
            <IonCol size="auto">
              {/* <IonButton fill="clear" onClick={handleFacebookSignIn}>
                <IonIcon icon={logoFacebook} />
              </IonButton> */}
            </IonCol>
          </IonRow>
            <IonButton expand="block" fill="clear" onClick={goToSignIn}>Already a user? Sign-in</IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignUp;