import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as Sentry from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/integrations";

Sentry.init({
  dsn: "https://63c61cd6b299e89c371d3f71751cb8fa@o4506877321936896.ingest.us.sentry.io/4506877343236096",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    captureConsoleIntegration({levels: ['error']})
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== 'development',
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  //  <React.StrictMode>
    <App />
  //  </React.StrictMode>
);