import { useState } from "react";
import { Quiz } from "../../../../../../types/ai_pivot/contentTypes";
import { ModuleProps } from "../shared/ModuleProps";
import { IonButton } from "@ionic/react";
import AnswerCheckPopup from "./AnswerCheckPopup";
import QuizQuestion from "./QuizQuestion";
import TextButton from "../shared/TextButton";
import PassageContent from "../shared/PassageContent";
import '../shared/ModuleBottomBar.css';
import ModuleAudioContent from "../shared/AudioContent";
import ModuleTextContent from "../shared/TextContent";
import AudioButton from "../shared/AudioButton";
import ImageContent from "../shared/ImageContent";
import ImageButton from "../shared/ImageButton";

interface QuizProps extends ModuleProps {
    quiz: Quiz;
    preloadedImages?: { [key: string]: HTMLImageElement };
}

const QuizModule: React.FC<QuizProps> = ({ quiz, preloadedImages, moveToNextModule, updateProgressBar, hideButton }) => {
    const [optionSelected, setOptionSelected] = useState<number>();
    const [isOpen, setIsOpen] = useState(false);
    const [isCorrect, setIsCorrect] = useState<boolean>(false);
    const [passageCompleted, setPassageCompleted] = useState<boolean>(quiz.content_type !== 'passage');

    const checkSelectedOption = () => {
        if (optionSelected === quiz.answer) {
            setIsCorrect(true);
        } else {
            setIsCorrect(false);
        }

        setIsOpen(true);
    }

    const handleSelection = (option: number) => {
        setOptionSelected(option);
    }

    const handlePassageCompletion = () => {
        setPassageCompleted(true);
    }

    return (
        <div>
            <div
                style={{ maxWidth: '550px', margin: 'auto', marginBottom: '12vh', padding: '20px' }}
                className="ion-padding">

                { quiz.content_type !== 'passage' && (
                    <QuizQuestion question={quiz.question_text} />
                )}

                {
                    {
                        'audio': <ModuleAudioContent audio_url={quiz.audio_content}/>,
                        'text': <ModuleTextContent text_content={quiz.text_content} />,
                        'image': <ImageContent image_url={quiz.image_content} preloadedImage={preloadedImages?.[quiz.image_content]}/>,
                        'passage': <PassageContent passage={quiz.passage_content} onComplete={handlePassageCompletion}/>
                    }[quiz.content_type]
                }

                {passageCompleted && quiz.content_type === 'passage' && (
                    <QuizQuestion question={quiz.question_text} />
                )}

                {passageCompleted && (
                    
                <div style={{
                    width: '100%',
                    textAlign: 'center',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                    gridTemplateRows: 'repeat(2, minmax(0, 1fr))',
                    rowGap: '8px',
                    columnGap: '3px'
                }}>
                    {
                        (quiz.option_1 || quiz.audio_option_1 || quiz.image_option_1) &&
                        <div
                            style={{ gridColumn: 1, gridRow: 1, minHeight: '100px' }}
                        >
                            {
                                {
                                    'text': <TextButton
                                        canClick={!isOpen}
                                        option={quiz.option_1 ? quiz.option_1 : ''}
                                        selectOption={() => handleSelection(1)}
                                        isSelected={optionSelected === 1}
                                    />,
                                    'audio': <AudioButton
                                        optionNum={1}
                                        url={quiz.audio_option_1 ? quiz.audio_option_1 : ''}
                                        selectOption={() => handleSelection(1)}
                                        isSelected={optionSelected === 1}
                                        canClick={!isOpen}
                                    />,
                                    'image': <ImageButton
                                        index={1}
                                        canClick={!isOpen}
                                        imageUrl={quiz.image_option_1 ? quiz.image_option_1 : ''}
                                        preloadedImage={preloadedImages?.[quiz.image_option_1!]}
                                        selectOption={() => handleSelection(1)}
                                        isSelected={optionSelected === 1}
                                    />,
                                    'passage': <div></div>
                                }[quiz.ans_type]
                            }
                        </div>
                    }
                    {
                        (quiz.option_2 || quiz.audio_option_2 || quiz.image_option_2) &&
                        <div
                            style={{ gridColumn: 2, gridRow: 1, minHeight: '100px' }}
                        >
                            {
                                {
                                    'text': <TextButton
                                        canClick={!isOpen}
                                        option={quiz.option_2 ? quiz.option_2 : ''}
                                        selectOption={() => handleSelection(2)}
                                        isSelected={optionSelected === 2}
                                    />,
                                    'audio': <AudioButton
                                        optionNum={2}
                                        url={quiz.audio_option_2 ? quiz.audio_option_2 : ''}
                                        selectOption={() => handleSelection(2)}
                                        isSelected={optionSelected === 2}
                                        canClick={!isOpen}
                                    />,
                                    'image': <ImageButton
                                        index={2}
                                        canClick={!isOpen}
                                        imageUrl={quiz.image_option_2 ? quiz.image_option_2 : ''}
                                        selectOption={() => handleSelection(2)}
                                        isSelected={optionSelected === 2}
                                    />,
                                    'passage': <div></div>
                                }[quiz.ans_type]
                            }
                        </div>
                    }
                    {(quiz.option_3 || quiz.audio_option_3 || quiz.image_option_3) &&
                        <div
                            style={{ gridColumn: 1, gridRow: 2, minHeight: '100px' }}
                        >
                            {
                                {
                                    'text': <TextButton
                                        canClick={!isOpen}
                                        option={quiz.option_3 ? quiz.option_3 : ''}
                                        selectOption={() => handleSelection(3)}
                                        isSelected={optionSelected === 3}
                                    />,
                                    'audio': <AudioButton
                                        optionNum={3}
                                        url={quiz.audio_option_3 ? quiz.audio_option_3 : ''}
                                        selectOption={() => handleSelection(3)}
                                        isSelected={optionSelected === 3}
                                        canClick={!isOpen}
                                    />,
                                    'image': <ImageButton
                                        index={3}
                                        canClick={!isOpen}
                                        imageUrl={quiz.image_option_3 ? quiz.image_option_3 : ''}
                                        selectOption={() => handleSelection(3)}
                                        isSelected={optionSelected === 3}
                                    />,
                                    'passage': <div></div>
                                }[quiz.ans_type]
                            }
                        </div>
                    }
                    {(quiz.option_4 || quiz.audio_option_4 || quiz.image_option_4) &&
                        <div
                            style={{ gridColumn: 2, gridRow: 2, minHeight: '100px' }}
                        >
                            {
                                {
                                    'text': <TextButton
                                        canClick={!isOpen}
                                        option={quiz.option_4 ? quiz.option_4 : ''}
                                        selectOption={() => handleSelection(4)}
                                        isSelected={optionSelected === 4}
                                    />,
                                    'audio': <AudioButton
                                        optionNum={4}
                                        url={quiz.audio_option_4 ? quiz.audio_option_4 : ''}
                                        selectOption={() => handleSelection(4)}
                                        isSelected={optionSelected === 4}
                                        canClick={!isOpen}
                                    />,
                                    'image': <ImageButton
                                        index={4}
                                        canClick={!isOpen}
                                        imageUrl={quiz.image_option_4 ? quiz.image_option_4 : ''}
                                        selectOption={() => handleSelection(4)}
                                        isSelected={optionSelected === 4}
                                    />,
                                    'passage': <div></div>
                                }[quiz.ans_type]
                            }
                        </div>
                    }
                </div>
                )}
            </div>
            {!hideButton && passageCompleted &&(
            <div className="button-bar">
                <div className="center-content">
                    <div className="bottom-btn-wrapper">
                        <IonButton
                            onClick={checkSelectedOption}
                            disabled={!optionSelected}
                            expand="block"
                        >
                            Check
                        </IonButton>
                    </div>
                </div>
            </div>
            )}
            <AnswerCheckPopup
                correct={isCorrect}
                correctAns={
                    {
                        'text': quiz[`option_${quiz.answer}`] || `${quiz.answer}`,
                        'audio': `${quiz.answer}`,
                        'image': `${quiz.answer}`,
                    }[quiz.ans_type]
                }
                moveToNextModule={() => {
                    console.log('moving to next module');
                    setIsOpen(false);
                    setOptionSelected(undefined);
                    setIsCorrect(false);
                    moveToNextModule();
                }}
                canContinue={true}
                isOpen={isOpen}
                closeSelf={() => setIsOpen(false)}
            />
        </div>
    )
}

export default QuizModule;