import React, { useState, useEffect, useRef } from 'react';
import { IonButton } from '@ionic/react';
import AnswerCheckPopup from '../quiz/AnswerCheckPopup';
import { ModuleProps } from '../shared/ModuleProps';
import { PairMatching } from "../../../../../../types/ai_pivot/contentTypes";
import '../shared/ModuleBottomBar.css';
import './PairMatchingModule.css';

interface PairMatchingModuleProps extends ModuleProps {
    pairMatching: PairMatching;
}

const PairMatchingModule: React.FC<PairMatchingModuleProps> = ({ pairMatching, moveToNextModule, updateProgressBar, hideButton }) => {
    const [leftItems, setLeftItems] = useState<string[]>([]);
    const [rightItems, setRightItems] = useState<string[]>([]);
    const [selectedLeft, setSelectedLeft] = useState<string | null>(null);
    const [selectedRight, setSelectedRight] = useState<string | null>(null);
    const [matches, setMatches] = useState<{ left: string, right: string }[]>([]);
    const [incorrectMatches, setIncorrectMatches] = useState<{ left: string, right: string }[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isCorrect, setIsCorrect] = useState<boolean>(false);

    const leftColumnRefs = useRef<HTMLDivElement[]>([]);
    const rightColumnRefs = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
        const pairs = Array.isArray(pairMatching.pairs) ? pairMatching.pairs : [];
        const shuffledLeftItems = shuffleArray(pairs.map(pair => pair.left));
        const shuffledRightItems = shuffleArray(pairs.map(pair => pair.right));

        setLeftItems(shuffledLeftItems);
        setRightItems(shuffledRightItems);
    }, [pairMatching]);

    useEffect(() => {
        syncHeights();
        window.addEventListener('resize', syncHeights);

        return () => {
            window.removeEventListener('resize', syncHeights);
        };
    }, [leftItems, rightItems]);

    const syncHeights = () => {
        leftColumnRefs.current.forEach((leftItem, index) => {
            const rightItem = rightColumnRefs.current[index];
            if (leftItem && rightItem) {
                const leftHeight = leftItem.offsetHeight;
                const rightHeight = rightItem.offsetHeight;
                const maxHeight = Math.max(leftHeight, rightHeight);
                leftItem.style.height = `${maxHeight}px`;
                rightItem.style.height = `${maxHeight}px`;
            }
        });
    };

    const shuffleArray = (array: string[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };
    
    const handleSelectLeft = (item: string) => {
        setSelectedLeft(item);
    };

    const handleSelectRight = (item: string) => {
        setSelectedRight(item);
    };

    useEffect(() => {
        if (selectedLeft && selectedRight) {
            const pair = { left: selectedLeft, right: selectedRight };
            if (pairMatching.pairs.some(p => p.left === pair.left && p.right === pair.right)) {
                setMatches([...matches, pair]);
            } else {
                setIncorrectMatches([...incorrectMatches, pair]);
                setTimeout(() => {
                    setIncorrectMatches((prev) => prev.filter(p => p.left !== pair.left && p.right !== pair.right));
                }, 2000);
            }
            setSelectedLeft(null);
            setSelectedRight(null);
        }
    }, [selectedLeft, selectedRight]);

    const checkAnswers = () => {
        const pairs = Array.isArray(pairMatching.pairs) ? pairMatching.pairs : [];
        setIsCorrect(pairs.length === matches.length);
        setIsOpen(true);
    };

    return (
        <div>
            <div style={{ maxWidth: "550px", margin: "auto", marginBottom: "12vh", padding: "20px" }} className="ion-padding">
                <div className="instructions">
                    {pairMatching.question || "Please match the pairs below."}
                </div>
                <div className="pair-matching-container ion-padding">
                    <div className="left-column">
                        {leftItems.map((item, index) => (
                            <div
                                key={item}
                                ref={el => leftColumnRefs.current[index] = el!}
                                className={`itemm ${selectedLeft === item ? 'selected' : ''} ${matches.some(m => m.left === item) ? 'matched' : ''} ${incorrectMatches.some(m => m.left === item) ? 'incorrect' : ''}`}
                                onClick={() => !matches.some(m => m.left === item) && handleSelectLeft(item)}
                            >
                                <div>{item}</div>
                            </div>
                        ))}
                    </div>
                    <div className="right-column">
                        {rightItems.map((item, index) => (
                            <div
                                key={item}
                                ref={el => rightColumnRefs.current[index] = el!}
                                className={`itemm ${selectedRight === item ? 'selected' : ''} ${matches.some(m => m.right === item) ? 'matched' : ''} ${incorrectMatches.some(m => m.right === item) ? 'incorrect' : ''}`}
                                onClick={() => !matches.some(m => m.right === item) && handleSelectRight(item)}
                            >
                                <div>{item}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {!hideButton && (
            <div className="button-bar">
                <div className="center-content">
                    <div className="bottom-btn-wrapper">
                        <IonButton onClick={checkAnswers} expand="block">
                            Check
                        </IonButton>
                    </div>
                </div>
            </div>
            )}
            <AnswerCheckPopup
                correct={isCorrect}
                feedback={isCorrect ? "Great job! All pairs are correctly matched." : "Some pairs are incorrectly matched. Please try again."}
                moveToNextModule={() => {
                    setIsOpen(false);
                    moveToNextModule();
                }}
                canContinue={true}
                isOpen={isOpen}
                closeSelf={() => setIsOpen(false)}
            />
        </div>
    );
};

export default PairMatchingModule;

// import React, { useState, useEffect } from 'react';
// import { IonButton } from '@ionic/react';
// import AnswerCheckPopup from '../quiz/AnswerCheckPopup';
// import { ModuleProps } from '../shared/ModuleProps';
// import { PairMatching } from "../../../../../../types/ai_pivot/contentTypes";
// import '../shared/ModuleBottomBar.css';
// import './PairMatchingModule.css';

// interface PairMatchingModuleProps extends ModuleProps {
//     pairMatching: PairMatching;
// }

// const PairMatchingModule: React.FC<PairMatchingModuleProps> = ({ pairMatching, moveToNextModule, updateProgressBar, hideButton }) => {
//     const [leftItems, setLeftItems] = useState<string[]>([]);
//     const [rightItems, setRightItems] = useState<string[]>([]);
//     const [selectedLeft, setSelectedLeft] = useState<string | null>(null);
//     const [selectedRight, setSelectedRight] = useState<string | null>(null);
//     const [matches, setMatches] = useState<{ left: string, right: string }[]>([]);
//     const [incorrectMatches, setIncorrectMatches] = useState<{ left: string, right: string }[]>([]);
//     const [isOpen, setIsOpen] = useState(false);
//     const [isCorrect, setIsCorrect] = useState<boolean>(false);

//     useEffect(() => {
//         const pairs = Array.isArray(pairMatching.pairs) ? pairMatching.pairs : [];
//         const shuffledLeftItems = shuffleArray(pairs.map(pair => pair.left));
//         const shuffledRightItems = shuffleArray(pairs.map(pair => pair.right));

//         setLeftItems(shuffledLeftItems);
//         setRightItems(shuffledRightItems);
//         // setLeftItems(pairs.map(pair => pair.left));
//         // setRightItems(pairs.map(pair => pair.right));
//     }, [pairMatching]);

//     const shuffleArray = (array: string[]) => {
//         for (let i = array.length - 1; i > 0; i--) {
//             const j = Math.floor(Math.random() * (i + 1));
//             [array[i], array[j]] = [array[j], array[i]];
//         }
//         return array;
//     };
    
//     const handleSelectLeft = (item: string) => {
//         setSelectedLeft(item);
//     };

//     const handleSelectRight = (item: string) => {
//         setSelectedRight(item);
//     };

//     useEffect(() => {
//         if (selectedLeft && selectedRight) {
//             const pair = { left: selectedLeft, right: selectedRight };
//             if (pairMatching.pairs.some(p => p.left === pair.left && p.right === pair.right)) {
//                 setMatches([...matches, pair]);
//             } else {
//                 setIncorrectMatches([...incorrectMatches, pair]);
//                 setTimeout(() => {
//                     setIncorrectMatches((prev) => prev.filter(p => p.left !== pair.left && p.right !== pair.right));
//                 }, 2000);
//             }
//             setSelectedLeft(null);
//             setSelectedRight(null);
//         }
//     }, [selectedLeft, selectedRight]);

//     const checkAnswers = () => {
//         const pairs = Array.isArray(pairMatching.pairs) ? pairMatching.pairs : [];
//         setIsCorrect(pairs.length === matches.length);
//         setIsOpen(true);
//     };

//     return (
//         <div>
//             <div style={{ maxWidth: "550px", margin: "auto", marginBottom: "12vh", padding: "20px" }} className="ion-padding">
//                 <div className="instructions">
//                     {pairMatching.question}
//                 </div>
//                 <div className="pair-matching-container ion-padding">
//                     <div className="left-column">
//                         {leftItems.map((item) => (
//                             <div
//                                 key={item}
//                                 // className={`item ${selectedLeft === item ? 'selected' : ''} ${matches.some(m => m.left === item) ? 'matched' : ''}`}
//                                 className={`itemm ${selectedLeft === item ? 'selected' : ''} ${matches.some(m => m.left === item) ? 'matched' : ''} ${incorrectMatches.some(m => m.left === item) ? 'incorrect' : ''}`}
//                                 onClick={() => !matches.some(m => m.left === item) && handleSelectLeft(item)}
//                             >
//                                 <div>{item}</div>
//                             </div>
//                         ))}
//                     </div>
//                     <div className="right-column">
//                         {rightItems.map((item) => (
//                             <div
//                                 key={item}
//                                 className={`itemm ${selectedRight === item ? 'selected' : ''} ${matches.some(m => m.right === item) ? 'matched' : ''} ${incorrectMatches.some(m => m.right === item) ? 'incorrect' : ''}`}
//                                 onClick={() => !matches.some(m => m.right === item) && handleSelectRight(item)}
//                             >
//                                 <div>{item}</div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </div>
//             {!hideButton && (
//             <div className="button-bar">
//                 <div className="center-content">
//                     <div className="bottom-btn-wrapper">
//                         <IonButton onClick={checkAnswers} expand="block">
//                             Check
//                         </IonButton>
//                     </div>
//                 </div>
//             </div>
//             )}
//             <AnswerCheckPopup
//                 correct={isCorrect}
//                 feedback={isCorrect ? "Great job! All pairs are correctly matched." : "Some pairs are incorrectly matched. Please try again."}
//                 moveToNextModule={() => {
//                     setIsOpen(false);
//                     moveToNextModule();
//                 }}
//                 canContinue={true}
//                 isOpen={isOpen}
//                 closeSelf={() => setIsOpen(false)}
//             />
//         </div>
//     );
// };

// export default PairMatchingModule;