import { IonNav, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { useAuth } from "../context/AuthContext";
import Routes from "./Routes";
import AppTabs from "./AppTabs";
import GatherInitialUserDataPopUp from "../components/ai_pivot/get_initial_user_data/GatherInitialUserData";


const AppRoutesHelper: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <IonReactRouter>
      <GatherInitialUserDataPopUp />
        {
        isAuthenticated ?
        <AppTabs />
          :
          <IonRouterOutlet>
            <Routes />
          </IonRouterOutlet>
        }
    </IonReactRouter>
  );
}

export default AppRoutesHelper;