import { useEffect, useState, useRef  } from "react";
import SkillComponent from "../../skill/SkillComponent";
import UnitCard from "../../skill/UnitCard";
import { Skill, UserProgress, StreakData  } from "../../../../types/ai_pivot/skillTypes";
import { loadAllSkills, loadUserProgress, fetchUserStreak  } from "../../../../service/skillService";
import { useAuth } from "../../../../context/AuthContext";
import { IonContent, IonButton, IonIcon, IonHeader, IonToolbar, IonTitle } from "@ionic/react";
import { flameOutline } from "ionicons/icons";
import StreakModal from "../../skill/StreakModal"; 

const HomeComponent: React.FC = () => {
    const [data, setData] = useState<Skill[]>([]);
    const [userProgress, setUserProgress] = useState<UserProgress[]>([]);
    const { user } = useAuth();
    const [currentSkill, setCurrentSkill] = useState<Skill | null>(null);
    const skillRefs = useRef<(HTMLDivElement | null)[]>([]);
    const previousIndex = useRef<number | null>(null);
    const [streakData, setStreakData] = useState<StreakData | null>(null);
    const [isStreakModalOpen, setIsStreakModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            loadAllSkills().then(({ data, error }) => {
                if (error) {
                    console.error(JSON.stringify(error));
                } else if (data) {
                    setData(data);
                }
            })

            loadUserProgress(user.id).then(({ data, error }) => {
                if (error) {
                    console.error(JSON.stringify(error));
                } else if (data) {
                    setUserProgress(data);
                }
            })

            // fetchUserStreak(user.id).then((data) => {
            //     console.log("St",data)
            //     setStreakData(data);
            // });
        }

    }, [user?.id])

    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         (entries) => {
    //             entries.forEach((entry) => {
    //                 const index = skillRefs.current.indexOf(entry.target as HTMLDivElement);
    //                 if (index !== -1) {
    //                     if (entry.isIntersecting) {
    //                         setCurrentSkill(data[index]);
    //                         previousIndex.current = index;
    //                     } else if (previousIndex.current !== null && entry.boundingClientRect.top > 0) {
    //                         const newIndex = index - 1 >= 0 ? index - 1 : 0;
    //                         setCurrentSkill(data[newIndex]);
    //                         previousIndex.current = newIndex;
    //                     }
    //                 }
    //             });
    //         },
    //         {
    //             threshold: 0.5,
    //         }
    //     );

    //     skillRefs.current.forEach((ref) => {
    //         if (ref) {
    //             observer.observe(ref);
    //         }
    //     });

    //     return () => {
    //         skillRefs.current.forEach((ref) => {
    //             if (ref) {
    //                 observer.unobserve(ref);
    //             }
    //         });
    //     };
    // }, [data]);

    useEffect(() => {
        const offset = 150; 

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const index = skillRefs.current.indexOf(entry.target as HTMLDivElement);
                    if (index !== -1) {
                        const rect = entry.boundingClientRect;
                        const entryPoint = window.innerHeight / 2 - offset;

                        if (entry.isIntersecting) {
                            if (rect.top < entryPoint) {
                                setCurrentSkill(data[index]);
                                previousIndex.current = index;
                            }
                        } else {
                            if (rect.top > entryPoint && previousIndex.current !== null) {
                                const newIndex = index - 1 >= 0 ? index - 1 : 0;
                                setCurrentSkill(data[newIndex]);
                                previousIndex.current = newIndex;
                            } else if (rect.bottom < entryPoint && index + 1 < data.length) {
                                setCurrentSkill(data[index + 1]);
                                previousIndex.current = index + 1;
                            }
                        }
                    }
                });
            },
            {
                root: null,
                rootMargin: `-${offset}px 0px -${window.innerHeight - offset}px 0px`,
                threshold: 0,
            }
        );

        skillRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            skillRefs.current.forEach((ref) => {
                if (ref) {
                    observer.unobserve(ref);
                }
            });
        };
    }, [data]);
    

    const updateUserProgressUI = (progress: UserProgress) => {
        let updatedUserProgress = userProgress.filter((value) => !(value.skill_id === progress.skill_id
            && value.user_id === progress.user_id
            && value.lesson_id === progress.lesson_id))

        updatedUserProgress.push(progress);
        setUserProgress(updatedUserProgress);
    }
    
    useEffect(() => {
        if (data.length > 0) {
            setCurrentSkill(data[0]);
        }
    }, [data]);

    // const handleOpenStreakModal = () => {
    //     setIsStreakModalOpen(true);
    // };

    // const handleCloseStreakModal = () => {
    //     setIsStreakModalOpen(false);
    // };

    return (
        <div  style={{ width: '100%', maxWidth: '550px', margin: 'auto' }}>
            {/* <IonHeader>
                <IonToolbar>
                    <IonButton slot="end" onClick={handleOpenStreakModal}>
                        <IonIcon icon={flameOutline} />
                    </IonButton>
                </IonToolbar>
            </IonHeader> */}

            {currentSkill && (
                <UnitCard
                    section={`SECTION ${currentSkill.level}`}
                    unit={`UNIT ${currentSkill.skill_id}`}
                    title={currentSkill.skill_name}
                />
            )}
            
            {data.map((skill, index) => (
                <div key={skill.skill_id} ref={(ref) => (skillRefs.current[index] = ref)}>
                    <SkillComponent
                        skill={skill}
                        userProgress={userProgress}
                        updateUserProgressUI={updateUserProgressUI}
                    />
                </div>
            ))}

            {/* {streakData && (
                <StreakModal
                    isOpen={isStreakModalOpen}
                    onClose={handleCloseStreakModal}
                    streakData={streakData}
                />
            )} */}
        </div>
    );
}

export default HomeComponent;