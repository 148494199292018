import { IonButton, IonInput, IonItem, IonLabel, IonList } from "@ionic/react";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import { MAIN_SCREEN } from "../../../util/constants";
import { useAuth } from "../../../context/AuthContext";
import { supabase } from "../../../service/authService";



const GatherUserInitialInfoComponent: React.FC<{ 
    nameCurr: string; 
    usernameCurr: string; 
    redditUsernameCurr: string; 
    closeModal: () => void; 
    // goToNextPage: () => void;  
}> = ({
    nameCurr, 
    usernameCurr, 
    redditUsernameCurr, 
    closeModal, 
    // goToNextPage
}) => {
    const [name, setName] = useState<string>(nameCurr || '');
    const [username, setUsername] = useState<string>(usernameCurr || '');
    // const [redditUsername, setRedditUsername] = useState<string>(redditUsernameCurr || '');
    const ionInputUsername = useRef<HTMLIonInputElement>(null);
    const { user } = useAuth();
    const history = useHistory();

    const validate = () => {
        try {
            // if (!name.trim()) {
            //     alert('Name is required.');
            //     return false;
            // }

            if (!username.trim()) {
                alert('Username is required.');
                return false;
            }

            // if (!redditUsername.trim()) {
            //     alert('Reddit username is required.');
            //     return false;
            // }

            return true;
        } catch (e) {
            console.error(JSON.stringify(e));
            alert('Make sure to provide both valid name and username')
            return false;
        }
    }

    const onUserNameInput = (ev: Event) => {
        let value = (ev.target as HTMLIonInputElement).value as string;
        value = value.toLowerCase();
        const filteredValue = value.replace(/[^a-z0-9_]+/g, '');
        setUsername(filteredValue);

        const inputCmp = ionInputUsername.current;
        if (inputCmp !== null) {
            inputCmp.value = filteredValue;
        }
    };

    const handleProfileUpdate = async (name: string, username: string, reddit_username?: string) => {
        if (user) {
            const { error } = await supabase
                .from('users')
                .update({ name, username, reddit_username })
                .eq('userid', user.id);

            if (error) {
                if (error.code = "23505") {
                    alert("The username is already taken")
                } else {
                    alert(error.message);
                    console.error('error updating profile: ', JSON.stringify(error));
                }
            } else {
                closeModal();
                // goToNextPage();
            }
        }
    };

    const handleSubmit = async () => {
        if (validate()) {
            await handleProfileUpdate(
                name,
                username,
                // redditUsername
            );
        }
    }

    return (
        <>
            <IonList inset={true}>
                {/* <IonItem style={{ '--padding-end': '0px', '--inner-padding-end': '8px' }}>
                    <IonInput
                        label-placement="floating"
                        label="Please, enter your first name"
                        value={name}
                        counter={true}
                        maxlength={30}
                        onIonInput={(e) => setName((e.target as unknown as HTMLInputElement).value)}
                        style={{ '--border-width': 0, '--padding-top': '5px', '--padding-end': '8px' }}
                    />
                </IonItem> */}
                <IonItem style={{ '--padding-end': '0px', '--inner-padding-end': '8px' }}>
                    <IonInput
                        label-placement="floating"
                        label="Please, enter your username"
                        value={username}
                        onIonInput={onUserNameInput}
                        style={{ '--border-width': 0, '--padding-top': '5px', '--padding-end': '8px' }}
                        maxlength={30}
                        counter={true}
                    />
                </IonItem>
            </IonList>
            {/* <IonList inset={true}>
                <IonItem style={{ '--padding-end': '0px', '--inner-padding-end': '8px' }}>
                    <IonInput
                        label-placement="floating"
                        label="Please, enter your Reddit username"
                        value={redditUsername}
                        counter={true}
                        maxlength={100}
                        onIonInput={(e) => setRedditUsername((e.target as unknown as HTMLInputElement).value)}
                        style={{ '--border-width': 0, '--padding-top': '5px', '--padding-end': '8px' }}
                    />
                </IonItem>
            </IonList> */}
            <IonList inset={true} style={{ backgroundColor: 'transparent' }}>
                <IonButton expand="block" onClick={handleSubmit}>Submit</IonButton>
            </IonList>
        </>
    );
}

export default GatherUserInitialInfoComponent;