import { IonText } from "@ionic/react";

const ModuleTextContent: React.FC<{ text_content: string }> = ({ text_content }) => {
    return (
        <div
            style={{ width: '100%', display: 'flex', marginTop: '30px', marginBottom: '30px', fontSize: 20 }}
        >
            <IonText>{text_content}</IonText>
        </div>
    );
}

export default ModuleTextContent;