import { IonPage, IonHeader, IonLabel, IonToolbar, IonButtons, IonContent,IonIcon } from "@ionic/react";
import HelpComponent from "../../components/ai_pivot/help/HelpComponent";
import HomeComponent from "../../components/ai_pivot/home/actual_home/HomeComponent";
import StreakComponent from "../../components/ai_pivot/skill/StreakComponent"; 
import { heartOutline, flameOutline, waterOutline, flag } from "ionicons/icons";
const HomePage: React.FC = () => {
    // return (
    //     <IonPage>
    //         <IonHeader mode="ios" collapse="fade">
    //             <IonToolbar>
    //                 <IonButtons slot="end">
    //                     <HelpComponent />
    //                 </IonButtons>
    //                 <IonButtons slot="end" >
    //                     <StreakComponent />
    //                 </IonButtons>
    //             </IonToolbar>
    //         </IonHeader>
    //         <IonContent fullscreen={true}>
    //             <div className="content-block ion-padding">
    //                 <HomeComponent />
    //             </div>
    //         </IonContent>
    //     </IonPage>
    // );
    return (
        <IonPage>
            <IonHeader className="ion-no-border ion-safe-area-top" mode="ios" collapse="fade">
                <IonToolbar style={{ height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '50px 20px',  '--padding-top': 'calc(env(safe-area-inset-top) - 20px)' }}>
                    <IonButtons slot="start">
                        <StreakComponent />
                    </IonButtons>
                    <IonButtons slot="end">
                        <HelpComponent />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}
            // style={{
            //     '--padding-top': 'calc(env(safe-area-inset-top) + 20px)',
            // }}
            >
                <div className="content-block ion-padding">
                    <HomeComponent />
                </div>
            </IonContent>
        </IonPage>
    );
}

export default HomePage;