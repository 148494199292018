import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { useState } from "react";
import HelpComponent from "../../components/ai_pivot/help/HelpComponent";
import CreateEditScenarioForm from "../../components/ai_pivot/home/CreateEditScenarioForm";

const CreateScenarioPage: React.FC = () => {
    const [showCreate, setShowCreate] = useState(false);

    return (
        <IonPage>
            <IonHeader mode="ios" collapse="fade">
                <IonToolbar>
                    <IonButtons slot="end">
                        <HelpComponent />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <CreateEditScenarioForm
                    show={showCreate}
                    dismiss={() => {
                        setShowCreate(false)
                    }} />
                <div className="content-block ion-padding">
                    <IonButton onClick={() => setShowCreate(true)}>CREATE</IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default CreateScenarioPage;