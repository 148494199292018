import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Loading from "./generic/Loading";
import { MAIN_SCREEN } from "../util/constants";

const UnauthRoute: React.FC<{ path: string; component: React.ComponentType<any>; exact: boolean }> = ({
    component: Component,
    ...rest
  }) => {
    const { isAuthenticated } = useAuth();

    return <Route {...rest} 
        render={(props) => (
          isAuthenticated ? 
            <Redirect to={{ pathname: `/${MAIN_SCREEN}`, state: { from: props.location } }} />
              : 
              isAuthenticated === null ? 
                <div/>
                  : 
                <Component {...props} />
        )} />;
  };

export default UnauthRoute;