import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonLoading, IonText } from '@ionic/react';
import { supabase } from "../../../service/authService";
import '../../../theme/DuoLinguo.css';

interface Recording {
    id: string;
    room_name: string;
    start_ts: number;
    status: string;
    max_participants: number;
    duration: number;
    // tracks: Track[] | null;
}

// interface Track {
//   type: string;
//   url: string;
//   mimeType: string;
//   duration: number;
// }

// interface RecordingItemProps {
//   recording: Recording;
//   InstanceId: number;
// }
interface RecordingItemProps {
    recordingId: string;
    wrapped?: boolean;
  }

const RecordingItem: React.FC<RecordingItemProps> = ({ recordingId, wrapped = true  }) => {
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [expires, setExpires] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchAccessLink = async (recordingId: string) => {
    const apiUrl = `https://api.daily.co/v1/recordings/${recordingId}/access-link`;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          'Authorization': `Bearer ${"5df5f2d165658757f351217426af48a402f36ad3104455e1de3f35f3cf2a997f"}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching access link:', error);
      throw error;
    }
  };

//   const updateSituationInstance = async (link: string) => {
//     try {
//       const { data, error } = await supabase
//         .from('situation_instance')
//         .update({ audio_link: link })
//         .eq('id', InstanceId);
//       if (error) {
//         throw error;
//       }
//       console.log('Audio link updated:', data);
//     } catch (error) {
//       console.error('Error updating audio link in Supabase:', error);
//     }
//   };

  useEffect(() => {
    setLoading(true);
    fetchAccessLink(recordingId)
      .then(data => {
        if (data.download_link) {
          setDownloadLink(data.download_link);
          setExpires(data.expires);
        //   updateSituationInstance(data.download_link);
        } else {
          setError('Failed to obtain download link');
        }
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching download link');
        setLoading(false);
      });
  }, [recordingId]);

//   return (
//     <IonCard>
//       <IonCardHeader>
//         <IonCardTitle>Audio Recording</IonCardTitle>
//       </IonCardHeader>
//       <IonCardContent>
//         {/* {loading ? (
//           <IonLoading isOpen={loading} message={'Loading...'} />
//         ) : ( */}
//           <>
//             {downloadLink && (
//               <audio controls>
//                 <source src={downloadLink} type="audio/mp4" />
//                 Your browser does not support the audio element.
//               </audio>
//             )}
//             {/* {expires && <IonText>Link expires on: {new Date(expires * 1000).toLocaleString()}</IonText>} */}
//             {error && <IonText color="danger">{error}</IonText>}
//           </>
//         {/* )} */}
//       </IonCardContent>
//     </IonCard>

//     // <div>
//     //   <h3>{recording.room_name}</h3>
//     //   {loading ? (
//     //     <p>Loading...</p>
//     //   ) : (
//     //     <>
//     //       {downloadLink && (
//     //         <audio controls>
//     //           <source src={downloadLink} type="video/mp4" />
//     //           Your browser does not support the audio element.
//     //         </audio>
//     //       )}
//     //       {expires && <p>Link expires on: {new Date(expires * 1000).toLocaleString()}</p>}
//     //       {error && <p>{error}</p>}
//     //     </>
//     //   )}
//     // </div>
//   );
// };
const content = (
  // <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <div>
      {downloadLink && (
          <audio controls>
              <source src={downloadLink} type="audio/mp4" />
              Your browser does not support the audio element.
          </audio>
      )}
      {error && <IonText color="danger">{error}</IonText>}
  </div>
);

if (wrapped) {
  return (
      <IonCard mode="ios" className="custom-ion-card">
          <IonCardHeader>
              <IonCardTitle>Audio Recording</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
              {/* {loading ? (
                  <IonLoading isOpen={loading} message={'Loading...'} />
              ) : ( */}
             {content}
              {/* )} */}
          </IonCardContent>
      </IonCard>
  );
}

return content;
};

export default RecordingItem;
