// import React, { useState } from 'react';
// import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonLabel, IonToggle } from '@ionic/react';

// // Define a type for your interests if they have a specific structure
// type Interest = string; // Use 'string' if your interests are just strings

// const interests: Interest[] = ['Music', 'Reading', 'Sports', 'Technology', 'Art'];

// const InterestsSelector: React.FC = () => {
//   const [selectedInterests, setSelectedInterests] = useState<Interest[]>([]);

//   // Now specify the type for the 'interest' parameter
//   const handleToggleInterest = (interest: Interest) => {
//     setSelectedInterests(selectedInterests.includes(interest)
//       ? selectedInterests.filter(i => i !== interest)
//       : [...selectedInterests, interest]
//     );
//   };

//   return (
    
//     <IonCard>
//     <IonCardHeader>
//       <IonCardTitle>Interests</IonCardTitle>
//     </IonCardHeader>
//     <IonCardContent>
//     <IonList>
//         {interests.map((interest) => (
//           <IonItem key={interest}>
//             <IonLabel>{interest}</IonLabel>
//             <IonToggle checked={selectedInterests.includes(interest)} onIonChange={() => handleToggleInterest(interest)} />
//           </IonItem>
//         ))}
//       </IonList>
//     </IonCardContent>
//   </IonCard>

//   );
// };

// export default InterestsSelector;


// import React, { useState } from 'react';
// import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonChip, IonLabel, IonIcon, IonInput, IonItem, IonButton } from '@ionic/react';
// import { addCircleOutline } from 'ionicons/icons';


// const InterestsSelector: React.FC = () => {
//   const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
//   const [customInterest, setCustomInterest] = useState('');

//   const toggleInterest = (interest: string) => {
//     setSelectedInterests(selectedInterests.includes(interest)
//       ? selectedInterests.filter(i => i !== interest)
//       : [...selectedInterests, interest]);
//   };

//   const addCustomInterest = () => {
//     if (customInterest && !selectedInterests.includes(customInterest)) {
//       setSelectedInterests([...selectedInterests, customInterest]);
//       setCustomInterest('');
//     }
//   };

//   const interests = ['Music', 'Reading', 'Sports', 'Technology', 'Art']; // Default interests


// return (
//     <IonCard>
//       <IonCardHeader>
//         <IonCardTitle>Interests</IonCardTitle>
//       </IonCardHeader>
//       <IonCardContent>
//         {interests.map((interest) => (
//           <IonChip
//             key={interest}
//             outline={!selectedInterests.includes(interest)}
//             color="primary"
//             onClick={() => toggleInterest(interest)}
//           >
//             <IonLabel>{interest}</IonLabel>
//           </IonChip>
//         ))}
//         {selectedInterests.filter(interest => !interests.includes(interest)).map(interest => (
//           <IonChip
//             key={interest}
//             color="primary"
//             onClick={() => toggleInterest(interest)}
//           >
//             <IonLabel>{interest}</IonLabel>
//             <IonIcon icon="close-circle" /> {/* Implement removal logic */}
//           </IonChip>
//         ))}
//         <IonItem lines="none">
//           <IonInput
//             value={customInterest}
//             placeholder="Add Custom Interest"
//             onIonChange={e => setCustomInterest(e.detail.value!)}
//             onKeyPress={e => e.key === 'Enter' && addCustomInterest()}
//           />
//           <IonButton fill="clear" onClick={addCustomInterest}>
//             <IonIcon slot="icon-only" icon={addCircleOutline} />
//           </IonButton>
//         </IonItem>
//       </IonCardContent>
//     </IonCard>
//   );
// };
// export default InterestsSelector;


import React, { useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonChip, IonLabel, IonIcon, IonInput, IonItem, IonButton } from '@ionic/react';
import { addCircleOutline, closeCircleOutline } from 'ionicons/icons';

interface InterestsSelectorProps {
  selectedInterests: string[]; // Add this to maintain the selected interests state from the parent.
  setSelectedInterests: React.Dispatch<React.SetStateAction<string[]>>;
}

const InterestsSelector: React.FC<InterestsSelectorProps> = ({ selectedInterests, setSelectedInterests }) => {
  const [customInterest, setCustomInterest] = useState('');

  const toggleInterest = (interest: string) => {
    setSelectedInterests(selectedInterests.includes(interest)
      ? selectedInterests.filter(i => i !== interest)
      : [...selectedInterests, interest]);
  };

  const addCustomInterest = () => {
    if (customInterest.trim() && !selectedInterests.includes(customInterest.trim())) {
      setSelectedInterests([...selectedInterests, customInterest.trim()]);
      setCustomInterest('');
    }
  };

  const interests = ['Music', 'Reading', 'Sports', 'Technology', 'Art']; // Default interests

  // Removal logic for custom interest
  const removeCustomInterest = (interest: string) => {
    setSelectedInterests(selectedInterests.filter(i => i !== interest));
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Interests</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {interests.map((interest) => (
          <IonChip
            key={interest}
            outline={!selectedInterests.includes(interest)}
            color="primary"
            onClick={() => toggleInterest(interest)}
          >
            <IonLabel>{interest}</IonLabel>
          </IonChip>
        ))}
        {selectedInterests.filter(interest => !interests.includes(interest)).map(interest => (
          <IonChip
            key={interest}
            color="primary"
            onClick={() => removeCustomInterest(interest)}
          >
            <IonLabel>{interest}</IonLabel>
            <IonIcon icon={closeCircleOutline} />
          </IonChip>
        ))}
        <IonItem lines="none">
          <IonInput
            value={customInterest}
            placeholder="Add Custom Interest"
            onIonChange={e => setCustomInterest(e.detail.value!)}
            onKeyPress={e => e.key === 'Enter' && addCustomInterest()}
          />
          <IonButton fill="clear" onClick={addCustomInterest}>
            <IonIcon slot="icon-only" icon={addCircleOutline} />
          </IonButton>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};

export default InterestsSelector;

