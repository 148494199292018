import { IonContent, IonList, IonItem, IonLabel, IonAvatar, IonPage } from "@ionic/react";
import React from "react";

const presets = [
  { name: "The Scholar", description: "Wise and knowledgeable.", image: "scholar.png" },
  { name: "The Adventurer", description: "Bold and brave.", image: "adventurer.png" },
  // ... other presets
];

const QuickStartOptions: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <IonList>
          {presets.map((preset, index) => (
            <IonItem key={index} routerLink={`/customize/${preset.name}`}>
              <IonAvatar slot="start">
                <img src={`assets/${preset.image}`} alt={preset.name} />
              </IonAvatar>
              <IonLabel>
                <h2>{preset.name}</h2>
                <p>{preset.description}</p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default QuickStartOptions;
